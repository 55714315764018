import ConfigNodesEdit from './ConfigNodesEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Tooltip } from 'antd';

//组件 
const WebComponent = (props: any) => {

    /** api服务id */
    const apiId = 'configNodes';

    //表头
    const columnsHead = [
        {
            title: '配置名称',
            dataIndex: 'configName',
            sorter: true,
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any) => (
                text ? text.brandName : ''
            )
        },
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => (
                text ? text.userName : ''
            )
        },
        {
            title: '节点内容',
            dataIndex: 'nodes',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '配置列表',
        apiId,
        columns,
        showStates: true,
        EditComponent: ConfigNodesEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponent;
