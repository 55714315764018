import { useEffect } from 'react';
import WebCountryOrAreaEdit from './WebCountryOrAreaEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';

const WebUniversalList = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '国家或地区',
            dataIndex: 'countryOrAreaName',
        },
        {
            title: 'usName',
            dataIndex: 'usName',
        },
        {
            title: '区号',
            dataIndex: 'areaNumber',
        },
        {
            title: '区域类型',
            dataIndex: 'areaType_Name',
        },
    ];

    const columns = getColumnsheadId({ title: '代码', dataIndex: 'code' })
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '国家或地区',
        apiId: 'webCountryOrArea',
        columns,
        showStates: true,
        primaryKeyName: 'code',
        EditComponent: WebCountryOrAreaEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebUniversalList;