import { Form, Input, Row, Col, Switch, Select, DatePicker } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from "../../universal/getList";
import moment from 'moment';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebProductOrderEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    // const [wxUserData, setWxUserData] = useState<any>();
    const [shopData, setShopData] = useState<any>();
    const [orderStatusData, setOrderStatusData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    const shopId = res.data.shopId;
                    // const orderStatusId = res.data.orderStatusId;
                    // const unionId = res.data.wxUser.id;
                    // console.log('res.data');
                    // console.log(res.data);

                    fetchApi.GET(defaultService,
                        {
                            apiId: 'shop',
                            apiVariable: shopId
                        }).then(res => {
                            if (res.success) {
                                setShopData([res.data]);
                            }
                        })
                    fetchApi.GET(defaultService,
                        {
                            apiId: 'generalData',
                            apiVariable: 'orderStatus',
                            apiExtend: 'showList',
                            // apiData: {
                            //     id: orderStatusId
                            // }
                        }).then(res => {
                            if (res.success) {
                                setOrderStatusData(res.data);
                            }
                        })
                    // fetchApi.GET(defaultService,
                    //     {
                    //         apiId: 'webWxUser',
                    //         apiVariable: unionId,
                    //     }).then(res => {
                    //         if (res.success) {
                    //             setWxUserData([res.data]);
                    //         }
                    //     })
                }
            })
        }
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webProductOrder',
        dateColumnName: ['orderDate', 'lastReceiptDate'],
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信"
                                name="unionId">
                                <Select
                                    showSearch
                                    placeholder="微信unionId"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="店铺"
                                name="shopId">
                                <Select
                                    showSearch
                                    placeholder="店铺"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(shopData, 'shopName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="订单状态"
                                name="orderStatusId">
                                <Select
                                    showSearch
                                    placeholder="订单状态"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(orderStatusData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="下单时间"
                                name="orderDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="最后收货确认"
                                name="lastReceiptDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="支付单号"
                                name="orderPayId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="允许修改地址"
                                name="allowChangeAddress"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="允许修改备注"
                                name="allowChangeMemo"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebProductOrderEdit;