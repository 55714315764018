import { useEffect, useRef, useState } from 'react';
import WxSubscribeMessageTypeEdit from './WxSubscribeMessageTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const Web = (props: any) => {
    const apiId = 'wxSubscribeMessageType';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    /** 刷新数据 */
    let initData: any;
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'typeName',
        },
        {
            title: '模板id',
            dataIndex: 'templetId',
        },
        {
            title: '一次性订阅',
            dataIndex: 'isOnceSubscription',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isOnceSubscription', e, apiId)}
                />
            )
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信订阅信息类型',
        apiId,
        columns,
        showStates: true,
        EditComponent: WxSubscribeMessageTypeEdit,
        serviceId: 'message',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Web;