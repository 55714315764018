import { useState, useEffect } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import { Col, Form, Select } from 'antd';
import systematic from '../../config/systematic';
import getList from '../universal/getList';

/** 地址代码类型 */
export declare type addressCodeType = 'countryOrArea' | 'province' | 'city' | 'county' | 'town';

/** 后端数据defaultService */
const defaultService = systematic.bmService;

/**
 * 显示国家或地区列表
 * @param setCountryOrAreaRows 保存的数据
 */
const showCountryOrArea = async (setCountryOrAreaRows: Function) => {
    fetchApi.GET(defaultService,
        {
            apiId: 'webAddressCode',
            apiExtend: 'showList',
            apiData: {
                codeType: 'countryOrArea',
                showModel: 3
            }
        }).then(res => {
            if (res.success === true) {
                setCountryOrAreaRows(res.data.rows);
            }
        })
};

/**
 * # 地址编辑
 * 传入对象
 * @var isRequired 是否需要required
 * @var row 初始数据
 * @var form 表单
 * @var visibleList 可视内容: [addressCodeType]Visible，[addressCodeType]CodeName，
 * [addressCodeType]Span，[addressCodeType]Disabled
 * @returns 
 */
const AddressEdit = (props: any) => {
    //是否需要，原始数据，表单，显示列表
    let { isRequired, row, form, visibleList } = props;
    if (typeof visibleList === 'undefined') {
        visibleList = {
            countryOrAreaVisible: true,
            provinceVisible: true,
            cityVisible: true,
            countyVisible: true,
            townVisible: true
        };
    }
    const [rulesRequired, setRulesRequired] = useState(false);
    const [countryOrAreaRows, setCountryOrAreaRows] = useState<any>();
    const [provinceRows, setProvinceRows] = useState<any>();
    const [cityRows, setCityRows] = useState<any>();
    const [countyRows, setCountyRows] = useState<any>();
    const [townRows, setTownRows] = useState<any>();
    let { countryOrAreaVisible,
        countryOrAreaCodeName,
        countryOrAreaSpan,
        countryOrAreaDisabled,
        provinceVisible,
        provinceCodeName,
        provinceCodeSpan,
        provinceDisabled,
        cityVisible,
        cityCodeName,
        cityCodeSpan,
        cityDisabled,
        countyVisible,
        countyCodeName,
        countyCodeSpan,
        countyDisabled,
        townVisible,
        townCodeName,
        townCodeSpan,
        townDisabled
    } = visibleList;
    if (typeof countryOrAreaCodeName === 'undefined') { countryOrAreaCodeName = 'countryOrAreaCode'; }
    if (typeof countryOrAreaSpan === 'undefined') { countryOrAreaSpan = 8; }
    if (typeof countryOrAreaDisabled === 'undefined') { countryOrAreaDisabled = false; }
    if (typeof provinceCodeName === 'undefined') { provinceCodeName = 'provinceCode'; }
    if (typeof provinceCodeSpan === 'undefined') { provinceCodeSpan = 8; }
    if (typeof provinceDisabled === 'undefined') { provinceDisabled = false; }
    if (typeof cityCodeName === 'undefined') { cityCodeName = 'cityCode'; }
    if (typeof cityCodeSpan === 'undefined') { cityCodeSpan = 8; }
    if (typeof cityDisabled === 'undefined') { cityDisabled = false; }
    if (typeof countyCodeName === 'undefined') { countyCodeName = 'countyCode'; }
    if (typeof countyCodeSpan === 'undefined') { countyCodeSpan = 12; }
    if (typeof countyDisabled === 'undefined') { countyDisabled = false; }
    if (typeof townCodeName === 'undefined') { townCodeName = 'townCode'; }
    if (typeof townCodeSpan === 'undefined') { townCodeSpan = 12; }
    if (typeof townDisabled === 'undefined') { townDisabled = false; }

    useEffect(() => {
        if (typeof isRequired !== 'undefined') { setRulesRequired(isRequired) }
        showCountryOrArea(setCountryOrAreaRows);
        if (typeof row !== 'undefined') {
            // console.log(row);

            /** 国家或地区 */
            let countryOrAreaCode = row.countryOrAreaCode;
            /** 省 */
            let provinceCode = row.provinceCode;
            /** 城市 */
            let cityCode = row.cityCode;
            /** 区.县 */
            let countyCode = row.countyCode;
            /** 街道.镇.乡 */
            // let townCode = row.townCode;
            /** 获取数据 */
            const fetchData = async () => {
                //获取省数据
                await changeAddress('province', countryOrAreaCode);
                //获取城市数据
                await changeAddress('city', provinceCode);
                //获取区.县数据
                await changeAddress('county', cityCode);
                //获取街道.镇.乡数据
                await changeAddress('town', countyCode);
            }
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row]);

    /**
     * 改变数据
     * @param codeType 类型
     * @param upCode 上级代码
     * @param setNull 是否设置为null
     */
    const changeAddress = async (codeType: addressCodeType, upCode: any, setNull?: boolean) => {
        fetchApi.GET(defaultService,
            {
                apiId: 'webAddressCode',
                apiExtend: 'showList',
                apiData: {
                    codeType,
                    upCode
                }
            }).then(res => {
                if (res.success === true) {
                    switch (codeType) {
                        case 'province':
                            if (setNull) {
                                form.setFieldsValue({
                                    provinceCode: null,
                                    cityCode: null,
                                    countyCode: null,
                                    townCode: null
                                })
                                setCityRows(undefined);
                                setCountyRows(undefined);
                                setTownRows(undefined);
                            }
                            setProvinceRows(res.data.rows);
                            break;
                        case 'city':
                            if (setNull) {
                                form.setFieldsValue({
                                    cityCode: null,
                                    countyCode: null,
                                    townCode: null
                                })
                                setCountyRows(undefined);
                                setTownRows(undefined);
                            }
                            setCityRows(res.data.rows);
                            break;
                        case 'county':
                            if (setNull) {
                                form.setFieldsValue({
                                    countyCode: null,
                                    townCode: null
                                })
                                setTownRows(undefined);
                            }
                            setCountyRows(res.data.rows);
                            break;
                        case 'town':
                            if (setNull) {
                                form.setFieldsValue({
                                    townCode: null
                                })
                            }
                            setTownRows(res.data.rows);
                            break;
                    }
                }
            })
    };

    return (
        <>
            {countryOrAreaVisible ?
                <Col span={countryOrAreaSpan}>
                    <Form.Item
                        label="国家或地区"
                        name={countryOrAreaCodeName}
                        rules={[{ required: rulesRequired, message: '国家或地区!' }]}>
                        <Select
                            showSearch
                            placeholder="国家或地区"
                            optionFilterProp="children"
                            disabled={countryOrAreaDisabled}
                            onChange={(e) => changeAddress('province', e, true)}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(countryOrAreaRows, 'countryOrAreaName', 'code')}
                        </Select>
                    </Form.Item>
                </Col>
                : undefined}

            {provinceVisible ?
                <Col span={provinceCodeSpan}>
                    <Form.Item
                        label="省"
                        name={provinceCodeName}
                        rules={[{ required: rulesRequired, message: '省!' }]}>
                        <Select
                            showSearch
                            placeholder="省"
                            optionFilterProp="children"
                            disabled={provinceDisabled}
                            onChange={(e) => changeAddress('city', e, true)}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(provinceRows, 'provinceName', 'code')}
                        </Select>
                    </Form.Item>
                </Col>
                : undefined}

            {cityVisible ?
                <Col span={cityCodeSpan}>
                    <Form.Item
                        label="城市"
                        name={cityCodeName}
                        rules={[{ required: rulesRequired, message: '城市!' }]}>
                        <Select
                            showSearch
                            placeholder="城市"
                            optionFilterProp="children"
                            disabled={cityDisabled}
                            onChange={(e) => changeAddress('county', e, true)}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(cityRows, 'cityName', 'code')}
                        </Select>
                    </Form.Item>
                </Col> : undefined}

            {countyVisible ?
                <Col span={countyCodeSpan}>
                    <Form.Item
                        label="区.县"
                        name={countyCodeName}
                        rules={[{ required: rulesRequired, message: '区.县!' }]}>
                        <Select
                            showSearch
                            placeholder="区.县"
                            optionFilterProp="children"
                            disabled={countyDisabled}
                            onChange={(e) => changeAddress('town', e, true)}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(countyRows, 'countyName', 'code')}
                        </Select>
                    </Form.Item>
                </Col> : undefined}

            {townVisible ?
                <Col span={townCodeSpan}>
                    <Form.Item
                        label="街道.镇.乡"
                        name={townCodeName}
                    >
                        <Select
                            showSearch
                            placeholder="街道.镇.乡"
                            optionFilterProp="children"
                            disabled={townDisabled}
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(townRows, 'townName', 'code')}
                        </Select>
                    </Form.Item>
                </Col>
                : undefined}
        </>
    )
}

export default AddressEdit;





