import { useEffect, useRef, useState } from 'react';
import WebChargebackEdit from './WebChargebackEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';
import { Button, Space, Switch, Tooltip } from 'antd';
import WebOrderPayEdit from "./WebOrderPayEdit";
import WebRefundOrderEdit from './WebRefundOrderEdit';
import WebReturnOrderEdit from './WebReturnOrderEdit';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification'

/**
 * 组件 WebChargeback 
 * @param props 
 * @returns 
 */
const WebChargeback = (props: any) => {

    const [modalVisibleWebOrderPayEdit,
        setModalVisibleWebOrderPayEdit] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalVisibleWebRefundOrderEdit,
        setModalVisibleWebRefundOrderEdit] = useState(false);
    const [modalVisibleWebReturnOrderEdit,
        setModalVisibleWebReturnOrderEdit] = useState(false);
    const apiId = 'webChargeback';
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    /** 更改一项数据 */
    let handleChangeOne: any;
    /** 刷新数据 */
    let initData: any;
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        /** ref.current 为空，因为只有在函数返回并呈现内容之后才设置ref。
        每当传递给useffect钩子的数组的内容值发生更改时，
        它都会触发useffect钩子。
        通过在数组中传递 handleChangeOne 并将记录 handleChangeOne 的回调传递到控制台，
        可以利用useffect钩子来完成任务。*/
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    let sendData = {
        type: '',
        id: '',
        // sendPageData: {},
    }

    //子组件回调函数，关闭modal
    const onCloseModalWebOrderPayEdit = (state: boolean) => {
        setModalVisibleWebOrderPayEdit(false)
    };

    /**
     * 打开[退款订单]
     * @param type 
     * @param id 
     */
    const openModalWebRefundOrderEdit = (type: string, id: string) => {
        sendData.type = type;
        sendData.id = id;
        setModalData(sendData);
        setModalVisibleWebRefundOrderEdit(true);
    };

    /**
     * 打开[退货订单]
     * @param type 
     * @param id 
     */
    const openModalWebReturnOrderEdit = (type: string, id: string) => {
        sendData.type = type;
        sendData.id = id;
        setModalData(sendData);
        setModalVisibleWebReturnOrderEdit(true);
    };

    /**
     * 子组件回调函数，关闭[退款订单]
     * @param state 
     */
    const onCloseModalWebRefundOrderEdit = (state: boolean) => {
        setModalVisibleWebRefundOrderEdit(false)
    };

    /**
     * 子组件回调函数，关闭[退货订单]
     * @param state 
     */
    const onCloseModalWebReturnOrderEdit = (state: boolean) => {
        setModalVisibleWebReturnOrderEdit(false)
    }

    /**
     * 退单流程：1.同意或拒绝退单
     * @param id 退单id
     * @param agreeChageback 是否同意或拒绝退单
     */
    const agreeOrRejectChargeback = async (id: string, agreeChageback: boolean) => {
        fetchApi.PUT(defaultService,
            {
                apiId,
                apiExtend: 'agreeOrRejectChargeback',
                apiData: {
                    id,
                    agreeChageback
                }
            }).then(res => {
                if (res.success === true) {
                    antMessage('success', '执行成功', 'success');
                    if (initData) {
                        initData();
                    }
                }
                else {
                    antMessage('error', '错误，执行失败', 'error');
                }
            })
    };

    /**
     * 退单流程：确认收到退货
     * @param id 退单id
     */
    const confirmReceiving = async (id: string) => {
        fetchApi.PUT(defaultService,
            {
                apiId,
                apiExtend: 'confirmReceiving',
                apiData: {
                    id
                }
            }).then(res => {
                if (res.success === true) {
                    antMessage('success', '执行成功，已确认收到退货', 'success');
                    if (initData) {
                        initData();
                    }
                }
            }).catch(err => {
                antMessage('error', '错误，执行失败', err.message);
            })
    };

    /**
     * 生成退款订单
     * @param chargebackId 退单id
     */
    const refundOrder = async (chargebackId: string) => {
        fetchApi.POST(defaultService,
            {
                apiId: 'webWxPay',
                apiExtend: 'refundOrder',
                apiData: {
                    chargebackId
                }
            }).then(res => {
                if (res.success === true) {
                    antMessage('success', '退款成功', 'success');
                    if (initData) {
                        initData();
                    }
                }
            }).catch(err => {
                antMessage('error', '退款失败', err.message);
            })
    };

    /**
     * 显示退单流程(按钮)
     * @param record 
     */
    const showChargebackProcess = (record: any) => {
        if (record.isUse === true) {
            /** 退单流程 */
            const chargebackProcessId = record.chargebackProcessId;
            /** 流程是否完成 */
            const isFinish = record.isFinish;
            /** 同意退单按钮 */
            const agreeChargebackBtn = (<Tooltip placement="topLeft" title={'同意退单'}>
                <Button size={"small"} type="primary"
                    onClick={() => agreeOrRejectChargeback(record.id, true)} >
                    {'同意'}
                </Button>
            </Tooltip>);
            /** 拒绝退单按钮 */
            const rejectChargebackBtn = (<Tooltip placement="topLeft" title={'拒绝退单'}>
                <Button size={"small"} type="default"
                    onClick={() => agreeOrRejectChargeback(record.id, false)} >
                    {'拒绝'}
                </Button>
            </Tooltip>);
            /** 退款按钮 */
            const agreeRefundBtn = (<Tooltip placement="topLeft" title={'执行退款，并完成流程'}>
                <Button size={"small"} type="primary"
                    onClick={() => refundOrder(record.id)} >
                    {'退款'}
                </Button>
            </Tooltip>);
            /** 退货订单按钮 */
            const returnOrderBtn = (<Tooltip placement="topLeft" title={'查看退货订单'}>
                <Button size={"small"} type="link"
                    onClick={() => openModalWebReturnOrderEdit('', record.id)}>
                    {'退货订单'}
                </Button>
            </Tooltip>);
            /** 确认收到退货按钮 */
            const confirmReceivingBtn = (<Tooltip placement="topLeft" title={'确认收到退货'}>
                <Button size={"small"} type="primary"
                    onClick={() => confirmReceiving(record.id)}>
                    {'确认收到退货'}
                </Button>
            </Tooltip>);
            /** 换货订单按钮 */
            const exchangeOrderBtn = (<Tooltip placement="topLeft" title={'新增换货物流订单，并完成流程'}>
                <Button size={"small"} type="primary"
                    onClick={() => openModalWebReturnOrderEdit('', record.id)}>
                    {'【换货】'}
                </Button>
            </Tooltip>);




            if (isFinish === false) {
                //退单流程
                switch (chargebackProcessId) {
                    case 1:
                        return (<Space>
                            {agreeChargebackBtn}
                            {rejectChargebackBtn}
                        </Space>);
                    case 3:
                        return (<Space>
                            {agreeRefundBtn}
                        </Space>);
                    case 5:
                        return (<Space>
                            {returnOrderBtn}
                            {confirmReceivingBtn}
                        </Space>);
                    case 6:
                        return (<Space>
                            {exchangeOrderBtn}
                        </Space>);
                    case 7:
                        return (<Space>
                            {agreeRefundBtn}
                        </Space>);

                }
            }
        } else {
            return (<>
                {'cancel'}
            </>)
        }
    };

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '店铺',
            dataIndex: 'productOrderDetail',
            render: (text: any) => (
                text
                    ? text.productOrder.shop.shopName
                    : ''
            )
        },
        {
            title: '微信',
            dataIndex: 'productOrderDetail',
            render: (text: any) => (
                text
                    ? text.productOrder.wxUser.nickName +
                    '(' + text.productOrder.wxUser.mobile + ')'
                    : ''
            )
        },
        {
            title: '类型',
            dataIndex: 'chargebackType_Name',
        },
        {
            title: '退款金额',
            dataIndex: 'refundAmount',
        },
        {
            title: '原因',
            dataIndex: 'chargebackReason_Name',
        },
        {
            title: '流程',
            dataIndex: 'chargebackProcess_Name',
        },
        {
            title: '',
            dataIndex: 'id',
            render: (text: any, record: any) => showChargebackProcess(record)
        },
        {
            title: '',
            dataIndex: 'id',
            render: (text: any, record: any) => (
                record.chargebackTypeId !== 3 ?
                    <Button size={"small"} type="link"
                        onClick={() => openModalWebRefundOrderEdit('', record.id)} >
                        {'退款详情'}
                    </Button>
                    : ''
            )
        },
        {
            title: '完成',
            dataIndex: 'isFinish',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isFinish', e, apiId)}
                />
            )
        }
    ];

    /**
     * 表头
     */
    const columns = getColumnsheadId({ title: '退单id' })
        .concat(columnsHead as []);

    /**
     * 子表头内容
     */
    const expandableColumnsHead = [
        {
            title: '产品名称',
            dataIndex: 'productName',
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={record.id}>{
                text
            }</Tooltip>
        },
        {
            title: '规格',
            dataIndex: 'productPriceSpec',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '价格',
            dataIndex: 'price',
        },
        {
            title: '成交价格',
            dataIndex: 'purchasePrice',
        },
        {
            title: '成交数量',
            dataIndex: 'amount',
        },
        {
            title: '单位',
            dataIndex: 'productUnit',
        },
        {
            title: '金额',
            dataIndex: 'totalPrice',
        },
        {
            title: '产品状态',
            dataIndex: 'productStatus_Name',
        },
        {
            title: '',
            dataIndex: 'chargebackStatus_Name',
        },
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? (
                    <Tooltip placement="topLeft" title={text.id}>{text.salesName}</Tooltip>
                ) : ''
            )
        },
    ];

    /**
    * 子表头
    */
    const expandableColumns = expandableColumnsHead;

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品',
        apiId,
        columns,
        showStates: true,
        showAdd: false,
        EditComponent: WebChargebackEdit,
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'productOrderDetail',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalVisibleWebOrderPayEdit ? (<WebOrderPayEdit closeModal={onCloseModalWebOrderPayEdit} data={modalData} />) : null}
            {modalVisibleWebRefundOrderEdit ? (<WebRefundOrderEdit closeModal={onCloseModalWebRefundOrderEdit} data={modalData} />) : null}
            {modalVisibleWebReturnOrderEdit ? (<WebReturnOrderEdit closeModal={onCloseModalWebReturnOrderEdit} data={modalData} />) : null}
        </>
    )
}

export default WebChargeback;