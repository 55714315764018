import React from 'react';
import CompanyFileEdit from './CompanyFileEdit';
import UploadFileListUniversal from '../../filesSystem/UploadFileListUniversal'
import { Tooltip } from 'antd';

//组件 CompanyUploadFile
const CompanyUploadFile = (props: any) => {

    //表头
    const columns = [
        {
            title: '编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '公司',
            dataIndex: 'company_Name',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                typeof text !== "undefined" ?
                    (text.length >= 5 ? text.substring(0, 5) + '......' : text)
                    : ''
            }</Tooltip>,
        },
        {
            title: '文件编号',
            dataIndex: 'fileId',
        },
        {
            title: '文件名称',
            dataIndex: 'fileName',
        },
    ];
    let extendProps: any = {
        ...props,
        titleName: '公司文件',
        apiName: 'company',
        //+ File
        //上传 + UploadFile
        //删除 + DeleteFile
        columns,
        makeDirectory: true,
        showImage: true,
        showDownload: true,
        showStates: true,
        EditComponent: CompanyFileEdit,
    };

    return (
        < UploadFileListUniversal props={extendProps} />
    )
}

export default CompanyUploadFile;
