import WebCommissionsOrderEdit from './WebCommissionsOrderEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

const webProductType = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? text.salesName : ''
            )
        },
        {
            title: '产品',
            dataIndex: 'productName',
            sorter: true,
        },
        {
            title: '规格',
            dataIndex: 'productPriceSpec',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
        {
            title: '佣金金额',
            dataIndex: 'commissionsAmount',
        },
        {
            title: '审核',
            dataIndex: 'isCheck',
            render: (text: any, record: any) => (
                <Switch checkedChildren="已审核" unCheckedChildren="未审核"
                    checked={text} key={record.id}

                />
            )
        }
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '佣金订单',
        apiId: 'webCommissionsOrder',
        columns,
        showAdd: false,
        showStates: true,
        EditComponent: WebCommissionsOrderEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default webProductType;
