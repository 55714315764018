import WebUserCouponEdit from './WebUserCouponEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';

const webProductType = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '微信',
            dataIndex: 'nickName',
            render: (text: any, record: any) =>
            (record.wxUser.nickName +
                (record.wxUser.mobile ? '(' + record.wxUser.mobile + ')' : ''))
        },
        {
            title: '优惠券',
            dataIndex: 'coupon',
            render: (text: any) => (text.couponName)
        },
        {
            title: '领取日期',
            dataIndex: 'collectDate',
            render: (val: moment.MomentInput) => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: '使用日期',
            dataIndex: 'useDate',
            render: (val: moment.MomentInput) => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: '最后使用',
            dataIndex: 'coupon',
            render: (text: any) => <span>{moment(text.useEndDate).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: '优惠券状态',
            dataIndex: 'userCouponState_Name',
        }
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户优惠券',
        apiId: 'webUserCoupon',
        columns,
        showStates: true,
        EditComponent: WebUserCouponEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default webProductType;
