import { Checkbox, Card, Pagination, Layout, Menu, Image, Drawer, Button, Space, Input, Tooltip, Upload } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import systematic from "../../config/systematic";
import systemExpand from '../../config/systemExpand';
import { UploadOutlined } from '@ant-design/icons';
import FilesManage from './FilesManage';
import './css/Scrollbar.css';

const { Content, Footer, Sider } = Layout;
const { Search } = Input;
//const { SubMenu } = Menu;        //使用子菜单时使用

//组件 PhotoGallery 
const PhotoGallery = (props: any) => {

    /** 后端数据defaultService */
    let defaultService: any = systematic.bmService;
    //配置参数
    const { filesGroupData, defaultMenu, closeModal, getCheckPhoto, editorData, uploadFileParam } = props; //从父组件传来
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState<any>();
    const [pageSize, setPageSize] = useState(32);
    //选定的文件组
    const [menuValueData, setMenuValueData] = useState(defaultMenu);
    //组的文件内容
    const [filesData, setFilesData] = useState<any>([]);
    const [state, setState] = useState(false);
    //多选的内容
    const [checkedValuesData, setCheckedValuesData] = useState<any>([]);
    //查询内容
    const [queryInput, setQueryInput] = useState("");
    //上传文件
    const [upload, setUpload] = useState<any>();
    /** 文件数据filesService */
    const [filesService, setFilesService] = useState<any>();
    /** 服务id */
    const [serviceId, setServiceId] = useState<any>();
    //文件服务器地址
    const [filesServerUrl, setFilesServerUrl] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            let url: any;
            let apiServiceUrl: any;
            let serviceId: any;
            //如果存在上传文件参数
            if (uploadFileParam) {
                url = uploadFileParam.url;
                apiServiceUrl = uploadFileParam.apiServiceUrl;
                serviceId = uploadFileParam.serviceId;
                console.log('存在 uploadFileParam');
                console.log(uploadFileParam);

            } else {
                //读取默认设置
                const filesServer = await systemExpand.filesServer();
                if (filesServer) {
                    url = filesServer.url;
                    apiServiceUrl = filesServer.apiServiceUrl;
                    serviceId = filesServer.serviceId;
                }
                console.log('不存在 uploadFileParam!!!!!!!');
                console.log(filesServer);
            }
            setFilesService(apiServiceUrl);
            setServiceId(serviceId);
            setFilesServerUrl(url);
            //设置上传文件的资料
            setUpload(await FilesManage.upload(
                apiServiceUrl,
                serviceId,
                menuValueData,
                { successCallback }
            ));

            fetchApi.GET(defaultService, {
                apiId: 'files',
                apiExtend: 'groupShowPage',
                current: 1,
                pageSize: 32,
                filesGroupId: defaultMenu
            }).then(res => {
                if (res.success === true) {
                    setFilesData(res.data.rows);
                    setTotal(res.data.total);
                }
            })

        };

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** 刷新文件 */
    const refreshFile = async (filesGroupId: any) => {
        const res = await fetchApi.GET(defaultService, {
            apiId: 'files',
            apiExtend: 'groupShowPage',
            current: 1,
            pageSize: 32,
            filesGroupId: filesGroupId
        })
        if (res.success === true) {
            setFilesData(res.data.rows);
        }
    };

    /**
     * 文件上传成功回调的方法
     * @param data 文件上传成功后的数据
     */
    const successCallback = (data: any) => {
        //console.log(data);        
        refreshFile(data.filesGroupId);
    };

    /**
     * 点击图片组后，获取该图片组的图片数据
     */
    const getPhotoList = async (datas: any, page: any) => {
        const res = await fetchApi.GET(defaultService, {
            apiId: 'files',
            apiExtend: 'groupShowPage',
            current: page,
            pageSize: pageSize,
            filesGroupId: datas,      //string类型，文件组id
            queryString: queryInput      //string类型，需要查询的内容
        })
        if (res.success) {
            setPage(page);         //设置当前页
            setPageSize(32);         //设置当前页显示数据的总数
            setTotal(res.data.total);         //设置当前页显示数据的总数
            setFilesData(res.data.rows);
        }
    }

    const handleOk = async () => {
        //console.log(checkedValuesData);
        if (getCheckPhoto !== undefined) {
            let NewcheckedValues: any[] = [];
            for (var i = 0, len = checkedValuesData.length; i < len; i++) {
                //console.log(checkedValuesData[i])
                const newCheckedValuesData = {
                    id: checkedValuesData[i],
                    src: await getPhotoSrc(checkedValuesData[i])
                }
                NewcheckedValues.push(newCheckedValuesData);
            }
            closeModal(false);
            getCheckPhoto(NewcheckedValues, editorData);
        }
        else {
            closeModal(false);
        }
    };

    const handleCancel = () => {
        closeModal(false);
    };

    /**
     * 同步获取图片地址（由于获取这个地址用异步方法不可行，所以采用同步的方法进行获取）
     */
    const getPhotoSrc = async (datas: any) => {
        if (datas !== undefined) {
            const res = await fetchApi.GET(defaultService, {
                apiId: 'files',
                apiVariable: datas
            });
            if (res.success) {
                return res.data.serverUrl + "/" +
                    res.data.destination + "/" + res.data.fileName
            }
        }
    }

    /**
     * 点击页码时触发
     */
    async function onChangePage(page: any, pageSize: any) {
        setPage(page);         //设置当前页
        setPageSize(pageSize);         //设置当前页显示数据的总数
        //console.log(menuValueData);
        const res = await fetchApi.GET(defaultService, {
            apiId: 'files',
            apiExtend: 'groupShowPage',
            current: page,
            pageSize: pageSize,
            filesGroupId: menuValueData,      //string类型，文件组id
            queryString: queryInput      //string类型，需要查询的内容
        })
        if (res.success) {
            setFilesData(res.data.rows);
        }
    }

    /**
     * 选择图片时触发（多选）
     */
    function onChangeCheckbox(checked: any) {
        setState(!state);         //无实质意义，主要用来刷新数据
        if (checkedValuesData.length === 0) {         //判断数组是否为空
            if (checkedValuesData.indexOf(checked.target.value) > -1) {              //判断该数据是否已在数组里，-1为无重复，其余数字则表示该数据所在数组的位置
                //console.log("这是重复数据");
                checkedValuesData.splice(checkedValuesData.indexOf(checked.target.value), 1)
                //console.log(checkedValuesData);
            }
            else {
                checkedValuesData.push(checked.target.value);
                //console.log("第一个数据");
                //console.log(checkedValuesData);
            }
        }
        else {
            if (checkedValuesData.indexOf(checked.target.value) > -1) {
                //console.log("这是重复数据");
                checkedValuesData.splice(checkedValuesData.indexOf(checked.target.value), 1)
                //console.log(checkedValuesData);
            }
            else {
                //console.log("不是重复数据");
                //console.log("不是第一个数据");
                checkedValuesData.push(checked.target.value);
                //console.log(checkedValuesData);
            }
            setCheckedValuesData(checkedValuesData);
        }
    };

    /**
     * 点击选择图片组时触发
     */
    async function onChangeMenu(item: any) {
        setMenuValueData(item.key);
        getPhotoList(item.key, 1);
        //设置上传文件的资料
        setUpload(await FilesManage.upload(
            filesService,
            serviceId,
            item.key,
            { successCallback }
        ));
    };

    /**
     * 获取文件
     * @param record
     * @returns
     */
    const getFile = (record: any) => {
        const { destination, fileName, smallImage, serverUrl } = record;
        console.log(record);

        return serverUrl + "/" + destination + "/" + (smallImage ? smallImage : fileName);
    };

    /**
     * 查询
     */
    const onSearch = async (value: any) => {
        //console.log(value);
        setQueryInput(value);        //将当前查询的内容set一下

        //点击查询后，将第一个组为默认
        fetchApi.GET(defaultService,
            {
                apiId: 'filesGroup',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    res.data.map(async (item: any, index: any) => {
                        if (index === 0) {
                            //读取数据库，首先将分组先set好
                            fetchApi.GET(defaultService,
                                {
                                    apiId: 'filesGroup',
                                    apiExtend: 'showList'
                                }).then(res => {
                                    if (res.success) {
                                        //setFilesGroupData(res.data);
                                    }
                                })
                            //将默认分组也先set好
                            //console.log(item.id);
                            setMenuValueData(item.id);

                            //根据该分组读取数据库，将数据都set好
                            const res = await fetchApi.GET(defaultService, {
                                apiId: 'files',
                                apiExtend: 'groupShowPage',
                                current: 1,
                                pageSize: 32,
                                filesGroupId: item.id,      //string类型，文件组id
                                queryString: value      //string类型，需要查询的内容
                            })
                            if (res.success) {
                                //console.log(res.data);
                                setPage(page);         //设置当前页
                                setPageSize(32);         //设置当前页显示数据的总数
                                setTotal(res.data.total);         //设置当前页显示数据的总数
                                setFilesData(res.data.rows);
                            }
                        }
                    })
                }
            })
    };

    /** 上传文件参数 */
    const uploadData = {
        userId: 'system'
    };

    return (
        <>
            <Drawer
                title='图库'
                placement="right"
                width='100%'
                open={true}
                maskClosable={false}
                closable={false}
                keyboard={false}
                onClose={handleCancel}
                extra={
                    <Space>
                        <Search
                            placeholder="请输入查询内容"
                            defaultValue={queryInput}
                            allowClear
                            enterButton="查询"
                            onSearch={onSearch}
                        />
                        <Upload
                            {...upload}
                            data={() => uploadData}
                            showUploadList={false}>
                            <Button icon={<UploadOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />}>文件上传</Button>
                        </Upload>
                        <Button onClick={handleCancel}>取消</Button>
                        <Button onClick={handleOk} type="primary">确认</Button>
                    </Space>
                }
            >
                <Layout style={{ background: "#1f1f1f" }}>
                    <Sider
                        className="ScrollbarStyle ScrollbarStyle-1"   //侧边栏的导航条的样式
                        style={{
                            overflow: 'auto',
                            height: '85%',
                            position: 'fixed',
                            left: 0
                        }}
                    >
                        <Menu
                            mode="inline"
                            theme="dark"
                            style={{ height: '100%' }}
                            selectedKeys={menuValueData}
                            onClick={onChangeMenu}
                        >
                            {
                                filesGroupData.map((item: any) => {
                                    return (
                                        <Menu.Item key={item.id}>
                                            {item.groupName}
                                        </Menu.Item>
                                    )
                                })
                            }
                        </Menu>
                    </Sider>
                    <Layout style={{ background: "#1f1f1f", marginLeft: 190 }}>
                        <Content style={{ background: "#1f1f1f", margin: '24px 0 0', overflow: 'initial' }} >
                            <div style={{ padding: 0, textAlign: 'center' }}>
                                <Card title="" bordered={false}>
                                    {
                                        filesData.map((item: any) => {
                                            if (getCheckPhoto !== undefined) {
                                                return (
                                                    <Card.Grid style={{ width: '12.5%', textAlign: 'center' }}>
                                                        <Checkbox
                                                            style={{ alignItems: 'flex-start' }}
                                                            value={item.id}
                                                            checked={(checkedValuesData.indexOf(item.id) > -1) ? true : false}
                                                            onChange={onChangeCheckbox}
                                                        >
                                                            <Image
                                                                width={90}
                                                                height={90}
                                                                preview={false}
                                                                src={getFile(item)}
                                                            />
                                                            <br />
                                                            {
                                                                item.originalFileName.length > 10 ?
                                                                    <Tooltip placement="bottom" color='#108ee9' title={item.originalFileName}>
                                                                        <span>
                                                                            {
                                                                                item.originalFileName.length > 10 ?
                                                                                    '......' + item.originalFileName.substring(item.originalFileName.length - 8, item.originalFileName.length)
                                                                                    : item.originalFileName
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                    : item.originalFileName
                                                            }
                                                        </Checkbox>
                                                    </Card.Grid>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Card.Grid style={{ width: '12.5%', textAlign: 'center' }}>
                                                        <Image
                                                            width={90}
                                                            height={90}
                                                            preview={true}
                                                            src={getFile(item)}
                                                        />
                                                        <br />
                                                        {
                                                            item.originalFileName.length > 10 ?
                                                                <Tooltip placement="bottom" color='#108ee9' title={item.originalFileName}>
                                                                    <span>
                                                                        {
                                                                            item.originalFileName.length > 10 ?
                                                                                '......' + item.originalFileName.substring(item.originalFileName.length - 8, item.originalFileName.length)
                                                                                : item.originalFileName
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                                : item.originalFileName
                                                        }
                                                    </Card.Grid>
                                                )
                                            }
                                        })
                                    }
                                </Card>
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'right', background: "#1f1f1f" }}>
                            <Pagination
                                size="small"
                                total={total}
                                current={page}
                                pageSize={pageSize}
                                showTotal={(total: any) => `共 ${total} 条`}
                                showSizeChanger={true}
                                hideOnSinglePage={true}
                                showQuickJumper
                                onChange={onChangePage}
                            />
                        </Footer>
                    </Layout>
                </Layout>
            </Drawer>
        </>
    );
}
export default PhotoGallery;