import { useEffect, useRef, useState } from 'react';
import PayOrderEdit from './PayOrderEdit';
import WxPayOrderEdit from './wxPay/WxPayOrderEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Button, Switch, Tooltip } from 'antd';
import WxPayRefundOrder from './wxPay/WxPayRefundOrder';
import fetchApi from '../../utils/api/fetchApi';
import AntdNotification from '../../utils/extend/AntdNotification';

const WebComponent = (props: any) => {
    const apiId = 'payOrder';
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    const [modalVisibleWxPayOrderEdit, setModalVisibleWxPayOrderEdit] = useState(false);
    const [modalVisibleWxPayRefundOrder, setModalVisibleWxPayRefundOrder] = useState(false);
    const [modalData, setModalData] = useState({});
    /** 默认后台service */
    const [defaultService, setDefaultService] = useState<any>();
    /** 刷新 */
    const [initData, setInitData] = useState<any>();
    //子组件回调函数
    const onVisibleModalWxPayOrderEdit = (state: boolean) => {
        setModalVisibleWxPayOrderEdit(state);
    };
    //子组件回调函数
    const onVisibleModalWxPayRefundOrder = (state: boolean) => {
        setModalVisibleWxPayRefundOrder(state);
    }

    useEffect(() => {
        if (ref.current) {
            setDefaultService(ref.current.defaultService);
            setInitData(() => ref.current.initData);
        }
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []); //初始化数据

    /**
     * 订单查询
     * @param id 
     */
    const payOrderQuery = async (id: string) => {
        fetchApi.GET(defaultService, {
            apiId: 'payOrder',
            apiExtend: 'orderQuery',
            apiVariable: id
        }).then(res => {
            if (res.success === true) {
                initData();
                AntdNotification('success', '查询', JSON.stringify(res.data));
            }
        })
    }

    /**
     * 关闭付款
     * @param id 
     */
    const closePayment = async (id: string) => {
        fetchApi.PUT(defaultService, {
            apiId: 'payOrder',
            apiExtend: 'closePayment',
            apiVariable: id
        }).then(res => {
            if (res.success === true) {
                initData();
                AntdNotification('success', '关闭付款成功');
            } else {
                AntdNotification('error', '关闭付款失败', '支付单已支付或已关闭');
            }
        })
    }

    /** 表头内容 */
    const columnsHead = [
        {
            title: '方式',
            dataIndex: 'payMode_Name',
            sorter: true,
            render: (text: any, record: any) => (
                <Button type="primary" onClick={
                    () => {
                        setModalData({
                            type: 'EDIT',
                            id: record.id
                        });
                        setModalVisibleWxPayOrderEdit(true);
                    }
                }>{text}</Button>
            )
        },
        {
            title: '查询',
            dataIndex: 'id',
            sorter: true,
            render: (text: any) => (
                <Button type='dashed' onClick={() => payOrderQuery(text)}>{'查询'}</Button>
            )
        },
        {
            title: '金额',
            dataIndex: 'payAmount',
        },
        {
            title: '进程',
            dataIndex: 'payProcess_Name',
            render: (text: any) => (
                <Button size={"small"} type="text" danger={text === '支付取消' ? true : false} >{text}</Button>
            )
        },
        {
            title: '成功',
            dataIndex: 'isPaySuccess',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record['isPaySuccess']}
                />
            )
        },
        {
            title: '退款',
            dataIndex: 'refundProcess_Name',
            sorter: true,
            render: (text: any, record: any) => (
                <Button type='primary' danger={text === '未退款' ? false : true} onClick={() => {
                    let { payModeId } = record;
                    payModeId = payModeId.substring(0, 5);
                    switch (payModeId) {
                        case 'wxPay':
                            setModalData({
                                payOrderId: record.id
                            });
                            onVisibleModalWxPayRefundOrder(true);
                            break;
                    }
                }}>{text}</Button>
            )
        },
        {
            title: '单号',
            dataIndex: 'merchantOrderId',
            render: (text: any, record: any) => {
                /** 商户id */
                const merchantId = record.merchantId;
                /** 商户名称 */
                const merchantName = record.merchantName;
                /** 单类型 */
                const merchantOrderType = record.merchantOrderType;
                const titleStr = `商户id：${merchantId}，商户名称：${merchantName}，单类型：${merchantOrderType}，单号：${text}`;
                return < Tooltip placement="topLeft" title={titleStr} > {
                    (typeof text !== "undefined" && text !== null) ?
                        (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                        : ''
                }</Tooltip>
            }
        },
        {
            title: '关闭付款',
            dataIndex: 'merchantOrderId',
            sorter: true,
            render: (text: any) => (
                <Button type='link' onClick={() => closePayment(text)}>{'关闭付款'}</Button>
            )
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '支付订单',
        serviceId: 'pay',
        apiId,
        columns,
        showStates: true,
        showLogger: {
            loggerDisplay: true,
            loggerSingleDisplay: true,
            logger: { id: 'payLogger' }
        },
        EditComponent: PayOrderEdit
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalVisibleWxPayOrderEdit ? (<WxPayOrderEdit closeModal={() => onVisibleModalWxPayOrderEdit(false)} data={modalData} />) : null}
            {modalVisibleWxPayRefundOrder ? (<WxPayRefundOrder closeModal={() => onVisibleModalWxPayRefundOrder(false)} data={modalData} />) : null}
        </>
    )
}

export default WebComponent;