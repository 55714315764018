import { Button, InputNumber, Select, Space, Switch, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import WebConsign from './WebConsign';
import numberHandle from '../../../utils/baseLib/numberHandle';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';

/**
 * 组件 WebLogisticsOrder 
 * @param props 
 * @returns 
 */
const WebLogisticsOrder = (props: any) => {
    const apiId = 'webConsignOrder';
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    //时间
    const [time, setTime] = useState<number>(2)

    //窗口状态
    const [modalData, setModalData] = useState({});
    const [modalVisibleWebConsign, setModalVisibleWebConsign] = useState(false);
    const [consignStatusData] = useState<Array<any>>(
        [{
            id: 0,
            name: '未发货'
        },
        {
            id: 1,
            name: '已发货'
        },
        {
            id: 2,
            name: '已收货'
        }]
    )
    const [selectConsignStatusData, setSelectConsignStatusData] = useState<any>();

    /** 更改一项数据 */
    // let handleChangeOne: any;
    /** 刷新数据 */
    let initData: any;
    /** 刷新变量 */
    /** 后端数据defaultService */
    let defaultService: any = '';
    const [refreshValue, setRefreshValue] = useState(0)
    let timer: any;
    //是否选中
    const [isChecked, setIsChecked] = useState<boolean>(false);
    //确认是否已开启自动刷新
    const [status, setStatus] = useState<boolean>(false);
    //自身的ref
    const statusRef = useRef({ isChecked: isChecked, status: status })
    useEffect(() => {
        statusRef.current.isChecked = isChecked;
        statusRef.current.status = status
        /** ref.current 为空，因为只有在函数返回并呈现内容之后才设置ref。
         每当传递给useffect钩子的数组的内容值发生更改时，
         它都会触发useffect钩子。
         通过在数组中传递 handleChangeOne 并将记录 handleChangeOne 的回调传递到控制台，
         可以利用useffect钩子来完成任务。
         <<<如果要回调刷新，则要刷新变量及useEffect配合使用>>> */
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            timer = ref.current.timer;
            // isChecked=ref.current.isCheck;

        }
        if (refreshValue !== 0) {
            initData();
        }
        if (isChecked) {
            timer = setInterval(function () {
                ref.current.initData();
            }, time * 1000 || 2000)
        } else {

            clearInterval(timer)
            // clearInterval(timer)
        }

        return () => clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshValue, isChecked, time]); //初始化数据

    /** 收货资料 */
    const deliveryAddress = (text: any) => {
        if (typeof text !== 'undefined') {
            const address = text.receiver + '; ' +
                text.receivePhone + '; ' +
                text.province + text.city + text.county + (text.town ? text.town : '') + text.detail + '; ' +
                text.postalCode;
            const addressString = '收货人: ' + text.receiver + '; ' +
                '联系电话:' + text.receivePhone + '; ' +
                '地址:' + text.province + text.city + text.county + (text.town ? text.town : '') + text.detail + '; ' +
                '邮编:' + text.postalCode;
            return (
                <Tooltip placement="topLeft" title={addressString}>{
                    // (typeof address !== "undefined" && address !== null) ?
                    //     (address.length >= 50 ? address.substring(0, 50) + '......' : address)
                    address
                    //     : ''
                }</Tooltip>
            )
        }
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '微信用户',
            dataIndex: 'wxUser',
            render: (text: any, record: any) =>
            (text.nickName +
                (text.mobile ? '(' + text.mobile + ')' : ''))
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (
                text ? text.shopName : ''
            )
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus_Name',
        },
        {
            title: '下单时间',
            dataIndex: 'createdAt',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {moment(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '发货资料',
            dataIndex: 'productOrderAddress',
            render: (text: any) => (deliveryAddress(text))
        }
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 关闭发货窗口
     * @param refreshable 是否刷新
     */
    const onCloseWebConsign = (refreshable: boolean) => {
        setModalVisibleWebConsign(false);
        if (refreshable === true) {
            setRefreshValue(numberHandle.rangeNumber(1, 9999));
        }
    };

    /** 查看不同状态的订单 */
    const consignStatusChange = (e: any) => {
        setSelectConsignStatusData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { productStatusId: e }
        }
        ref.current.initData(currentData);
    };


    /**
     * 发货或查看
     * @param record 
     * @param type Edit Or Add
     */
    const consigning = async (record: any, type: string) => {
        setIsChecked(false);
        const { $masterData } = record;
        /** 交易单号 */
        let transactionNo;
        /** wx OpenId */
        let wxOpenId;
        if (typeof $masterData !== 'undefined') {
            const { orderPay } = $masterData;
            if (typeof orderPay !== 'undefined') {
                transactionNo = orderPay.transactionNo;
                wxOpenId = orderPay.wxOpenId;
            }
        }
        const sendObj = {
            type,
            productOrderId: record.productOrderId,
            productOrderDetailId: record.id,
            logisticsOrderId: record.logisticsOrderId,
            /** 交易单号 */
            transactionNo,
            /** wx OpenId */
            wxOpenId
        }
        setModalData(sendObj);
        setModalVisibleWebConsign(true);
    };

    /**
     * 删除物流订单
     * @param id 物流订单id
     */
    const removeLogisticsOrder = async (id: string) => {
        await fetchApi.DELETE(defaultService, {
            apiId: 'webLogisticsOrder',
            apiVariable: id
        })
        const currentData: initDataType = {
            apiId,
            sendPageData: { productStatusId: selectConsignStatusData }
        }
        ref.current.initData(currentData);
    };

    /** 
      * 物流发货按钮即加号
      * @param record 
      * @returns 
      */
    const consignButton = (record: any) => {


        return (<>

            {record.productStatusId === 1 ?
                <><>
                    <Tooltip placement="topLeft" title={'查看或修改物流信息'}>
                        <Button
                            type="dashed"
                            onClick={() => consigning(record, 'Edit')}>
                            {record.productStatus_Name}
                        </Button>

                    </Tooltip>
                </><Tooltip placement="topLeft" title={'删除物流单并重置为未发货状态'}>
                        <Button
                            onClick={() => removeLogisticsOrder(record.logisticsOrderId)}>
                            {'删除物流单'}
                        </Button>

                    </Tooltip></>

                :
                ''}

            {record.productStatusId === 0 ?
                <Button
                    onClick={() => consigning(record, 'Add')}>
                    发货
                </Button> :
                ''}
        </>)
    };


    /** 子表头内容 */
    const expandableColumnsHead = [
        {
            title: '名称',
            dataIndex: 'productName',
        },
        {
            title: '规格',
            dataIndex: 'productPriceSpec',
            // render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            //     (typeof text !== "undefined" && text !== null) ?
            //         (text.length >= 8 ? text.substring(0, 8) + '......' : text)
            //         : ''
            // }</Tooltip>
        },
        {
            title: '价格',
            dataIndex: 'price',
        },
        {
            title: '成交价',
            dataIndex: 'purchasePrice',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
        {
            title: '单位',
            dataIndex: 'productUnit',
        },
        {
            title: '金额',
            dataIndex: 'totalPurchasePrice',
        },
        {
            title: '产品状态',
            dataIndex: 'productStatus_Name',
        },
        {
            title: '物流',
            dataIndex: 'chargebackStatus_Name',
            render: (text: any, record: any) => (
                consignButton(record)
            )
        },
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? (
                    <Tooltip placement="topLeft" title={text.id}>{text.salesName}</Tooltip>
                ) : ''
            )
        },
    ];

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '发货订单',
        apiId,
        columns,
        disabledAdd: true,
        showOperation: false,
        showPrint: true,
        showAdd: false,
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'productOrderDetails',
        isExpandableDateIncludingMasterData: true
    };

    /**
     * 局部刷新数据
    */
    const RefreshData = (checked: boolean) => {
        setIsChecked(checked)
        setStatus(checked)
    }

    //时间的变化
    const changeTime = (value: number | null) => {
        var thisValue = 2;
        if (value !== null) {
            thisValue = value
        }
        setTime(thisValue);
    }
    return (
        <>
            <Space>
                {/* <span>是否自动刷新:</span>
                <Switch onClick={RefreshData} checked={isChecked} checkedChildren="开启" unCheckedChildren="关闭"  ></Switch>
                <Tooltip placement="topLeft" title="单位是秒,不输入默认为2秒">
                    <InputNumber
                        onChange={changeTime}
                        placeholder='请输入时间'
                        min={1}
                    >
                    </InputNumber>
                </Tooltip> */}
                <Select
                    showSearch
                    defaultValue="未发货"
                    onChange={consignStatusChange}
                    placeholder="订单状态"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(consignStatusData)}
                </Select>
            </Space>
            <span>    </span>
            <WebUniversal props={extendProps} ref={ref} />
            {/* {modalVisibleWebConsign ? (<WebConsign setIsChecked={setIsChecked} RefreshData={RefreshData} onClose={onCloseWebConsign} data={modalData} />) : null} */}
            {modalVisibleWebConsign ? (<WebConsign isChecked={isChecked} status={status} setIsChecked={setIsChecked} onClose={onCloseWebConsign} data={modalData} />) : null}

        </>
    )
}

export default WebLogisticsOrder;