import { useRef } from 'react';
import RoleGroupEdit from './RoleGroupEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const ShowPageComponent = (props: any) => {

    const ref = useRef<any>();
    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '角色组名',
            dataIndex: 'roleGroupName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '角色组',
        apiId: 'roleGroup',
        columns,
        showStates: true,
        EditComponent: RoleGroupEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default ShowPageComponent;
