import { Form, Row, Col, DatePicker } from 'antd';
import { useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification'
import dayjs from 'dayjs';
import systematic from '../../../config/systematic';

/** 后端数据defaultService */
const defaultService = systematic.bmService;

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};
const LoggerClear = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { apiId, onClose } = props;
    useEffect(() => {
    }, []);

    /** 确定 */
    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                const { clearDate } = values;
                console.log(values)
                console.log('clearDate')
                console.log(clearDate)
                console.log(dayjs(clearDate).format('YYYY-MM-DD'));
                console.log('apiId:');
                console.log(apiId);
                const res = await fetchApi.DELETE(defaultService, {
                    apiId,
                    apiExtend: 'clearLog',
                    apiData: {
                        clearDate: dayjs(clearDate).format('YYYY-MM-DD')
                    }
                });
                if (res.success === true) {
                    antMessage('info', '成功', '删除日志成功');
                } else {
                    antMessage('warning', '错误', '删除日志失败，' + res.message.toString());
                }
                onClose(false);
            })
            .catch((err: any) => {
                antMessage('warning', '无法通过数据校验', '请输入所需数据 或者 ' + err.message.toString());
            });
    };

    return (
        <>
            <AntDraggableModal
                title={'清除日志'}
                open={true}
                onOk={(e) => handleOk(e)}
                onCancel={() => onClose(false)}
                closable={false}
                maskClosable={false} >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={18}>
                            <Form.Item
                                label="清除及之前日期："
                                name="clearDate"
                                rules={[{ required: true, message: '请选择日期!' }]}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default LoggerClear;