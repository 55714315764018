import WebProductFileEdit from './WebProductFileEdit';
import UploadFileListUniversal, { UFLUExtendPropsType } from '../../filesSystem/UploadFileListUniversal';
import { columnsheadId } from '../../universal/columnshead';
import systemExpand from '../../../config/systemExpand';
import { useEffect, useState } from 'react';

//组件
const WebProductUploadFile = (props: any) => {
    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();
    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { webProductFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '产品图片',
                apiId: 'webProduct',
                filesGroupId: webProductFilesGroupId,
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                EditComponent: WebProductFileEdit,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //表头
    const columnsHead = [
        {
            title: '产品',
            dataIndex: 'product',
            render: (text: any) => (text.productName)
        },
        {
            title: '广告图片',
            dataIndex: 'isAd',
            render: (text: any) => (text ? '是' : '否')
        }
    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default WebProductUploadFile;