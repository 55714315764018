import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import fetchApi from '../../../utils/api/fetchApi'
import moment from 'moment';
import { Button, DatePicker, Select, Form, Col, Row, Table, Tooltip, Space } from 'antd';
import systematic from '../../../config/systematic'
import DownloadExcel from '../../report/DownloadExcel';

const { RangePicker } = DatePicker;
const { Option } = Select;
const WebBusinessReport = (props: any) => {

    const [form] = Form.useForm()

    const defaultService = systematic.bmService;

    // 店铺数据
    const [shopData, setShopData] = useState<any>()
    //产品状态数据
    const [productStatus, setProductStatus] = useState<any>()

    //时间类型数据
    const [timeTypeData, setTimeTypeData] = useState<any>()

    //仓库数据
    const [warehouse, setWarehouse] = useState<Array<any>>([])
    //选中的店铺
    const [chooseShop, setChooseShop] = useState<any>("")
    //选中的仓库
    const [chooseWarehouse, setChooseWarehouse] = useState<any>("")
    // 合并时间跟apiId的数据
    const [prop, setProp] = useState<any>()

    // //表头
    const columnsHead = [
        {
            title: 'id',
            dataIndex: 'productOrderDetail_productId',
            id: 'productOrderDetail_productId',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>,
            width: 100,
        },
        {
            title: '商品名称',
            dataIndex: 'productOrderDetail_productName',
            id: 'productOrderDetail_productName',
            width: 200,
        },
        {
            title: '规格',
            dataIndex: 'productOrderDetail_productPriceSpec',
            id: 'productOrderDetail_productPriceSpec',
            width: 200,
        },

        {
            title: '销售次数',
            dataIndex: 'counts',
            id: 'counts',
            width: 100,
        },
        {
            title: '单位',
            dataIndex: 'productOrderDetail_productUnit',
            id: 'productOrderDetail_productUnit',
            width: 50,
        },
        {
            title: '总价格',
            dataIndex: 'totalPurchasePrice',
            id: 'totalPurchasePrice',
            width: 100,
        },
        {
            title: '销售总数量',
            dataIndex: 'sumAmount',
            id: 'sumAmount',
            width: 100,
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (<span>{text ? text.shopName : chooseShop}</span>),
            id: 'shop',
            width: 100,
        },
        {
            title: '仓库',
            dataIndex: 'logisticsWarehouseId',
            render: (text: any, record: any) => (<span>{text ? record.logisticsWarehouse_Name : chooseWarehouse}</span>),
            id: 'logisticsWarehouseId',
            width: 100,
        }
    ];
    // //处理参数并返回
    const parameter = (item: any) => {

        let time: any = [];
        if (item.chooseTime) {
            item.chooseTime.map((item: any) => {
                time.push(moment(item.$d).format('YYYY-MM-DD'))
            })
        } else {
        }
        return { ...item, time }
    }

    // //查询按钮事件
    const onFinish = async (values: any) => {
        let retureData = parameter(values)

        if (retureData) {
            let data = await fetchApi.GET(defaultService, {
                apiId: "webReport",
                apiExtend: 'business',
                apiData: {
                    //如果有选择时间则按照选择的时间，否则结束时间是当前时间，开始时间为相对当前时间的前一个月
                    "startDate": retureData.time.length !== 0 ? retureData.time[0] + " " + "00:00:00" : moment().subtract(1, "months").format("YYYY-MM-DD") + " " + "00:00:00",
                    "endDate": retureData.time.length !== 0 ? retureData.time[1] + " " + "23:59:59" : moment().format(`YYYY-MM-DD `) + " " + "23:59:59",
                    "productStatusId": retureData.productStatus || "",
                    "chargebackStatusId": retureData.returnState || "",
                    "shopId": retureData.shopId || "",
                    "logisticsWarehouseId": retureData.logisticsWarehouseId || "",
                    "timeTypeId": retureData.timeTypeId
                }
            })

            //如果数据请求成功
            if (data.success) {
                let date = data.data.data
                setProp({ data: date, props: { apiId: "webReport-business", logisticsWarehouse_Name: data.data.queryCriteria.logisticsWarehouse_Name, queryCriteria: { ...data.data.queryCriteria } } })
                //判断店铺是否有被选择
                if (data.data.queryCriteria.shop) {
                    setChooseShop(data.data.queryCriteria.shop.shopName)
                }
                if (data.data.queryCriteria.logisticsWarehouseId !== "") {
                    setChooseWarehouse(data.data.queryCriteria.logisticsWarehouse_Name)
                } else {
                    setChooseWarehouse('')
                }
            }
        }
    }

    useEffect(() => {

        const fetch = async () => {
            //店铺数据
            let shopData = await fetchApi.GET(defaultService, {
                apiId: "shop",
                apiExtend: 'showPage'
            })
            if (shopData.success) {
                let newShopData = [{ id: "", shopName: "全部" }, ...shopData.data.rows]
                setShopData(newShopData)
            }

            //产品状态数据
            let productStatus = await fetchApi.GET(defaultService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: 'productStatus'
            })
            if (productStatus.success) {
                let newArr = productStatus.data
                setProductStatus(newArr)
            }

            //时间类型数据
            let timeTypeData = await fetchApi.GET(defaultService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: 'timeType'
            })
            if (timeTypeData.success) {
                let newArr = timeTypeData.data
                setTimeTypeData(newArr)
            }
            //物流数据
            let logisticsWarehouseData = await fetchApi.GET(defaultService, {
                apiId: "webLogisticsWarehouse",
                apiExtend: "showList",
            })
            if (logisticsWarehouseData.success) {
                let newWarehouse = [{ LWHName: "全部", id: "" }, ...logisticsWarehouseData.data]
                setWarehouse(newWarehouse)
            }
        }
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <>
            <Form
                form={form}
                onFinish={onFinish}
                name="time"
            >
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="时间类型"
                            name="timeTypeId"
                            rules={[{ required: true, message: '时间类型' }]}
                        >
                            <Select
                            >
                                {timeTypeData && timeTypeData.length > 0 ? timeTypeData.map((item: any) => (<Option key={item.id}>{item.name}</Option>)) : undefined}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="选择时间"
                            name="chooseTime"
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                            //     placeholder={[moment().subtract(1, "months").format("YYYY-MM-DD"), moment().format(`YYYY-MM-DD`)]
                            // }
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="产品状态"
                            name="productStatus"
                        >
                            <Select
                                mode="multiple"
                                // placeholder={"全部"}
                            >
                                {productStatus && productStatus.length > 0 ? productStatus.map((item: any) => (<Option key={item.id}>{item.name}</Option>)) : undefined}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="退货状态"
                            name="returnState"
                        >
                            <Select
                                placeholder={"全部"}
                            >
                                <Option key="">全部</Option>
                                <Option key="0">正常</Option>
                                <Option key="-1">有退货</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="店铺"
                            name="shopId"
                        >
                            <Select
                                optionFilterProp="children"
                                placeholder={"全部"}
                            >
                                {shopData && shopData.length > 0 ? shopData.map((item: any) => (
                                    <Option value={item.id} key={item.id}>{item.shopName ? item.shopName : chooseShop}</Option>
                                )) : undefined}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item label="仓库"
                            name="logisticsWarehouseId"
                        >
                            <Select
                                optionFilterProp="children"
                                placeholder={"全部"}
                            >
                                {warehouse && warehouse.length > 0 ? warehouse.map((item: any) => (
                                    <Option value={item.id} key={item.id}>{item.LWHName}</Option>
                                )) : undefined}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button >
                                <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                    下载EXCEL文件
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey={(record: any) => (`${record.productOrderDetail_productId}${Math.random() * 1 + 10000000000}`)}

                columns={columnsHead} dataSource={prop ? prop.data : []}
                summary={(pageData: any) => {
                    //总销售金额
                    let totalPurchasePrice = 0;

                    //总销售次数
                    let totalCounts = 0

                    //总销售数量
                    let totalSumAmount = 0;
                    pageData.forEach((item: any) => {
                        totalPurchasePrice += item.totalPurchasePrice;
                        totalCounts += item.counts ? item.counts : 0;
                        totalSumAmount += item.sumAmount ? item.sumAmount : 0
                    });
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{ }</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>{ }</Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>{totalCounts}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>{ }</Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>{`${totalPurchasePrice === 0 ? "" : totalPurchasePrice.toFixed(2)}`}</Table.Summary.Cell>
                                <Table.Summary.Cell index={6}>{totalSumAmount}</Table.Summary.Cell>
                                <Table.Summary.Cell index={7}>{ }</Table.Summary.Cell>
                                <Table.Summary.Cell index={8}>{ }</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}
            >
            </Table>
        </>
    );
}
export default WebBusinessReport