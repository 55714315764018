import { useEffect } from 'react';
import WebCountyEdit from './WebCountyEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';
import { Tooltip } from 'antd';

const WebUniversalList = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '城市',
            dataIndex: 'city',
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text ?
                    text.province.countryOrArea.countryOrAreaName + ' - ' + text.province.provinceName
                    : ''}>
                    {text ? text.cityName : ''}
                </Tooltip>
            )
        },
        {
            title: '区.县名称',
            dataIndex: 'countyName',
        },
        {
            title: 'usName',
            dataIndex: 'usName',
        },
    ];

    const columns = getColumnsheadId({ title: '代码', dataIndex: 'code' })
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '区.县',
        apiId: 'webCounty',
        columns,
        showStates: true,
        primaryKeyName: 'code',
        EditComponent: WebCountyEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebUniversalList;