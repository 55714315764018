import WebGiftMemberCardEdit from './WebGiftMemberCardEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';
import { useEffect, useRef } from 'react';
import moment from 'moment';

const WebGiftMemberCard = (props: any) => {
    const apiId = 'webGiftMemberCard';
    /** 更改一项数据 */
    let handleChangeOne: any;
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();

    useEffect(() => {
        /** ref.current 为空，因为只有在函数返回并呈现内容之后才设置ref。
        每当传递给useffect钩子的数组的内容值发生更改时，
        它都会触发useffect钩子。
        通过在数组中传递 handleChangeOne 并将记录 handleChangeOne 的回调传递到控制台，
        可以利用useffect钩子来完成任务。*/
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // initData = ref.current.initData;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '类型',
            dataIndex: 'giftMemberCardType',
            render: (text: any) => (
                text
                    ? text.typeName
                    : ''
            )
        },
        {
            title: '余额',
            dataIndex: 'balance'
        },
        {
            title: '微信',
            dataIndex: 'wxUser',
            render: (text: any) => (
                text
                    ? text.nickName
                    : ''
            )
        },
        {
            title: '实体卡',
            dataIndex: 'isPhysicalCard',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChangeOne(record.id, 'isPhysicalCard', e, apiId)}
                />
            )
        },
        {
            title: '激活',
            dataIndex: 'isActivation',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChangeOne(record.id, 'isActivation', e, apiId)}
                />
            )
        },
        {
            title: '激活日期',
            dataIndex: 'activationDate',
            render: (val: moment.MomentInput) =>
                <span>
                    {moment(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '失效日期',
            dataIndex: 'expiryDate',
            render: (val: moment.MomentInput) =>
                <span>
                    {moment(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },

    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '礼品会员卡',
        apiId,
        columns,
        showStates: true,
        EditComponent: WebGiftMemberCardEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebGiftMemberCard;
