import WebWxUserEdit from './WebWxUserEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';
import { Button, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import WebWxOpenId from './WebWxOpenId';

const WebWxUser = (props: any) => {
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    /** 默认后台service */
    const [defaultService, setDefaultService] = useState<any>();
    /** 刷新 */
    const [initData, setInitData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            setDefaultService(ref.current.defaultService);
            setInitData(() => ref.current.initData);
        }
        // const fetchData = async () => {
        // };
        // fetchData();
    }, []); //初始化数据

    const onVisibleModalWxOpenId = (state: boolean) => {
        setModalVisible(state);
    }

    //表头
    const columnsHead = [
        {
            title: '昵称',
            dataIndex: 'nickName',
            sorter: true,
        },
        {
            title: '头像',
            dataIndex: 'avatarUrl',
            render: (image: any) => <img src={image} width='60' alt="头像" />
        },
        {
            title: '手机',
            dataIndex: 'mobile',
        },
        {
            title: 'wxOpenId',
            dataIndex: 'wxOpenId',
            render: (text: any, record: any) => (
                <Button type='dashed' onClick={() => {
                    onVisibleModalWxOpenId(true);
                    setModalData({
                        unionId: record.unionId
                    });
                    onVisibleModalWxOpenId(true);


                }}>{'wxOpenId'}</Button>
            )
        },
    ];

    const columns = getColumnsheadId({
        title: '微信',
        dataIndex: 'unionId',
        pos: 'suffix'
    }).concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信用户',
        apiId: 'webWxUser',
        columns,
        showStates: true,
        primaryKeyName: 'unionId',
        showAdd: false,
        EditComponent: WebWxUserEdit,
        showLogger: {
            loggerDisplay: true,
            loggerSingleDisplay: true,
            logger: { id: 'opLogger' }
        },
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalVisible ? (<WebWxOpenId closeModal={() => onVisibleModalWxOpenId(false)} data={modalData} />) : null}
        </>
    )
}

export default WebWxUser;
