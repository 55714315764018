import React, { useEffect, useRef } from 'react';
import WebCouponEdit from './WebCouponEdit';
import WebUniversal, {WUExtendPropsType} from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import { Switch } from 'antd';

/**
 * 组件 WebCoupon 
 * @param props 
 * @returns 
 */
const WebCoupon = (props: any) => {
    const ref = useRef<any>();
    const apiId = 'webCoupon';
    let handleChangeOne:any;
    useEffect(() => {
        if (ref.current) {
            console.log(ref);
            
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        //同步获取数据 async - await
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (
                text ? text.shopName : '')
        },
        {
            title: '优惠券名称',
            dataIndex: 'couponName',
        },
        {
            title: '领用起始时间',
            dataIndex: 'collectStartDate',
            render: (val: moment.MomentInput) => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: '优惠券类型',
            dataIndex: 'couponType_Name',
        },
        {
            title: '满额',
            dataIndex: 'fullUse',
        },
        {
            title: '可抵扣',
            dataIndex: 'deduction',
        },
        {
            title: '兑换码',
            dataIndex: 'isConversionCode',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isConversionCode', e, apiId)}
                />
            )
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '优惠券',
        apiId,
        columns,
        showStates: true,
        EditComponent: WebCouponEdit,
    };

    return (
        <>
            <WebUniversal ref={ref} props={extendProps} />
        </>
    )
}

export default WebCoupon;