import React from 'react'



const Welcome = () => {

    return (
        <div>
            {'Welcome'}
        </div>
    )

}

export default Welcome;