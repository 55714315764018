import {
    DatabaseOutlined, DollarCircleOutlined, UserOutlined, UsergroupAddOutlined, ProfileOutlined,
    PaperClipOutlined, IdcardOutlined, HomeOutlined
} from '@ant-design/icons';
import React from 'react';

const AntdIcon = (props: any) => {

    const { iconName } = props;
    const getIcon = (par: string) => {
        switch (par) {
            case 'DatabaseOutlined':
                return (< DatabaseOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            case 'DollarCircleOutlined':
                return (< DollarCircleOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            case 'UserOutlined':
                return (< UserOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            case 'UsergroupAddOutlined':
                return (< UsergroupAddOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            case 'ProfileOutlined':
                return (< ProfileOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            case 'PaperClipOutlined':
                return (< PaperClipOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            case 'IdcardOutlined':
                return (< IdcardOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);
            default:
                return (< HomeOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />);

        }
    }

    return (
        getIcon(iconName)
    )
}

export default AntdIcon;