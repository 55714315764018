import WebProductUnitEdit from './WebProductUnitEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const webProductUnit = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '产品单位',
            dataIndex: 'unitName',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品单位',
        apiId: 'webProductUnit',
        columns,
        showStates: true,
        EditComponent: WebProductUnitEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default webProductUnit;
