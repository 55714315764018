import React, { useState } from 'react';
import { useEffect } from 'react';
import fetchApi from '../../../utils/api/fetchApi'
import moment from 'moment';
import { Button, DatePicker, Select, Form, Col, Row, Table, Tooltip, Space } from 'antd';
import systematic from '../../../config/systematic'

import DownloadExcel from '../../report/DownloadExcel';

const { RangePicker } = DatePicker;
const { Option } = Select;
const WebReport = (props: any) => {
    const [form] = Form.useForm()
    const defaultService = systematic.bmService;
    // 店铺数据
    const [shopData, setShopData] = useState<any>()

    //选中的店铺
    const [chooseShop, setChooseShop] = useState<any>("")

    // 合并时间跟apiId的数据
    const [prop, setProp] = useState<any>()

    //表头
    const columnsHead = [
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 200,
        },
        {
            title: '次数',
            dataIndex: 'sumAmount',
            id: 'sumAmount',
            width: 100,
        },

        {
            title: '价格',
            dataIndex: 'totalPurchasePrice',
            id: 'totalPurchasePrice',
            width: 100,
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (<span>{text ? text.shopName : chooseShop}</span>),
            id: 'shop',
            width: 100,
        }
    ];
    //处理参数并返回
    const parameter = (item: any) => {
        
        let time: any = [];
        if (item.chooseTime) {
            item.chooseTime.map((item: any) => {
                time.push(moment(item.$d).format('YYYY-MM-DD'))
            })
        } else {
     
        }
        
        return{ ...item, time }
    }

    //查询按钮事件
    const onFinish = async(values: any) => {
        let retureData= parameter(values)
        if (retureData) {
            let data = await fetchApi.GET(defaultService, {
                apiId: "webReport",
                apiExtend: 'daily',
                apiData: {
                    //如果有选择时间则按照选择的时间，否则结束时间是当前时间，开始时间为相对当前时间的前一个月
                    "startDate": retureData.time.length !== 0 ? retureData.time[0] + " " + "00:00:00" : moment().subtract(1, "days").format("YYYY-MM-DD") + " " + "00:00:00",
                    "endDate": retureData.time.length !== 0 ? retureData.time[1] + " " + "23:59:59" : moment().subtract(1, "days").format(`YYYY-MM-DD `) + " " + "23:59:59",
                    "shopId": retureData.shopId || ""
                }
            })
            
            //如果数据请求成功
            if (data.success) {
                let date = data.data.data
                setProp({ data: date, props: { apiId: "webReport-daily", queryCriteria: { ...data.data.queryCriteria } } })
                //判断店铺是否有被选择
                if (data.data.queryCriteria.shop) {
                    setChooseShop(data.data.queryCriteria.shop.shopName)
                }
            }
        }
    }

    useEffect(() => {
        const fetch = async () => {
            //店铺数据
            let shopData = await fetchApi.GET(defaultService, {
                apiId: "shop",
                apiExtend: 'showList'
            })
         
            if (shopData.success) {
                let newShopData = [{ id: "", shopName: "全部" }, ...shopData.data]
                setShopData(newShopData)
            }
        }
        fetch()
    }, [])

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col >
                        <Form.Item
                            label="选择时间"
                            name="chooseTime"
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                                placeholder={[moment().subtract(1, "days").format("YYYY-MM-DD"), moment().subtract(1, "days").format(`YYYY-MM-DD`)]}

                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label="店铺"
                            name="shopId"

                        >
                            <Select
                                optionFilterProp="children"
                                placeholder={"全部"}
                            >
                                {shopData && shopData.length > 0 ? shopData.map((item: any) => (
                                    <Option value={item.id} key={item.id}>{item.shopName ? item.shopName : chooseShop}</Option>
                                )) : undefined}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button >
                                <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                    下载EXCEL文件
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey={(record: any) => (`${record.productOrderDetail_productId}${Math.random() * 1 + 10000000000}`)}
                columns={columnsHead} dataSource={prop ? prop.data : []}
                summary={(pageData: any) => {
                    //总价格
                    let totalPrice = 0;

                    //总数量
                    let totalSumAmount = 0;
                    pageData.forEach((item: any) => {
                        totalPrice += item.totalPurchasePrice
                        totalSumAmount += item.sumAmount
                    });

                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{`${totalSumAmount === 0 ? "" : totalSumAmount}`}</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>{`${totalPrice === 0 ? "" : totalPrice.toFixed(2)}`}</Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>{""}</Table.Summary.Cell>

                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}
            >
            </Table>
        </>
    );
}
export default WebReport