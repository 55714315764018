import { useEffect } from 'react';
import WebLogisticsOrderEdit from './WebLogisticsOrderEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import logistics from '../general/logistics';

/**
 * 组件 WebLogisticsOrder 
 * @param props 
 * @returns 
 */
const WebLogisticsOrder = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '物流公司',
            dataIndex: 'logisticsCompany_Name',
        },
        {
            title: '物流单号',
            dataIndex: 'logisticsOrderNo',
            render: (text: any, record: any) => (
                <Tooltip placement="topLeft" title={'查看物流状态'}>
                    <Button size={"small"} type="dashed"
                        onClick={() => logistics.routeQuery(record.id)}>
                        {text}
                    </Button>
                </Tooltip>
            )
        },
        {
            title: '发货仓库',
            dataIndex: 'logisticsWarehouse',
            render: (text: any) => (
                text ? text.LWHName : ''
            )
        },
        {
            title: '收货人',
            dataIndex: 'receiver',
        },
        {
            title: '收货电话',
            dataIndex: 'receivePhone',
        },
        {
            title: '国家或地区',
            dataIndex: 'consignCountryOrArea_Name',
        },
        {
            title: '收货地址',
            dataIndex: 'receiveDetail',
            render: (text: any, record: any) => {
                const address = (record ?
                    (record.receiveProvince ? record.receiveProvince : '') +
                    (record.receiveCity ? record.receiveCity : '') +
                    (record.receiveCounty ? record.receiveCounty : '') +
                    (record.receiveTown ? record.receiveTown : '') +
                    text
                    : '');
                const shortAddress = (address.length >= 15 ? address.substring(0, 15) + '...' : address);
                return (<Tooltip placement="topLeft" title={address}>
                    {shortAddress}
                </Tooltip>)
            }
        },
        {
            title: '发货日期',
            dataIndex: 'consignDate',
            render: (val: moment.MomentInput) =>
                <span>
                    {moment(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '物流订单',
        apiId: 'webLogisticsOrder',
        columns,
        disabledAdd: true,
        showStates: true,
        EditComponent: WebLogisticsOrderEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebLogisticsOrder;