import { useEffect } from 'react';
import WebLogisticsWarehouseEdit from './WebLogisticsWarehouseEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 WebLogisticsWarehouse 
const WebLogisticsWarehouse = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (
                text ? text.shopName : ''
            )
        },
        {
            title: '仓库名',
            dataIndex: 'LWHName',
        },
        {
            title: '仓库类型',
            dataIndex: 'LWHType_Name',
        },
        {
            title: '联系人',
            dataIndex: 'contacts',
        },
        {
            title: '联系电话',
            dataIndex: 'contactPhone',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '物流仓库',
        apiId: 'webLogisticsWarehouse',
        columns,
        showStates: true,
        EditComponent: WebLogisticsWarehouseEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebLogisticsWarehouse;