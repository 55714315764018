import { useEffect, useRef, useState } from 'react';
import PassageEdit from './PassageEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Tooltip, Image, Button, Upload, Switch } from 'antd';
import systematic from "../../config/systematic";
import fetchApi from '../../utils/api/fetchApi';
import antMessage from '../../utils/extend/AntdNotification';
import numberHandle from '../../utils/baseLib/numberHandle';

const Passage = (props: any) => {
    const apiId = 'passage';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    /** 刷新数据 */
    let initData: any;
    /** 后端数据defaultService */
    let defaultService: any = systematic.bmService;
    /** 更改一项数据 */
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        const fetchData = async () => {

        };
        fetchData();
        if (refresh !== 0) {
            initData();
        };
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '运行模式',
            dataIndex: 'runMode_Name',
        },
        {
            title: '网址',
            dataIndex: 'url',
            render: (text: any) =>
                <Tooltip placement="topLeft" title={text}>{
                    (typeof text !== "undefined" && text !== null ?
                        text.length >= 8 ? text.substring(0, 8) + '......' : text
                        : '')
                }</Tooltip>
        },
        {
            title: '请求类型',
            dataIndex: 'method',
        },
        {
            title: 'api内容',
            dataIndex: 'apiContent',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null ?
                    text.length >= 8 ? text.substring(0, 8) + '......' : text
                    : '')
            }</Tooltip>
        },
        {
            title: '已执行/总数',
            dataIndex: 'count',
            render: (text: any, record: any) => {
                const counts = record.counts;
                const executeCounts = record.executeCounts;
                return (executeCounts + '/' + counts)
            }
        },
        {
            title: '直到成功停止',
            dataIndex: 'isUntilSuccessfullyStopped',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isUntilSuccessfullyStopped', e, apiId)}
                />
            )
        },
        {
            title: '已执行',
            dataIndex: 'isExecuted',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isExecuted', e, apiId)}
                />
            )
        },
        {
            title: '执行状态',
            dataIndex: 'isExeSuccess',
            render: (text: any, record: any) => (
                <Switch checkedChildren="成功" unCheckedChildren="失败"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isExeSuccess', e, apiId)}
                />
            )
        }

    ];

    ;

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '通道',
        apiId,
        columns,
        showStates: true,
        EditComponent: PassageEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Passage;