import './login.css';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import systematic from '../../../config/systematic';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';

//组件 Login 
const Login = (props: any) => {
    const navigator = useNavigate()

    //判断是否选中
    const [ischecked, setIsChecked] = useState<boolean>(true)
    /** 后端数据defaultService */
    let defaultService: any = systematic.bmService;

    const onCheck = (values: any) => {
        setIsChecked(values.target.checked)
    }
    const onFinish = async (values: any) => {
        let data = {
            apiId: 'login',
            apiExtend: 'login',
            apiData: {
                logId: values.logId,
                logPass: values.logPass,
            }
        }
        fetchApi.GET(defaultService,
            data).then(res => {
                if (res.success === true) {
                    if (ischecked) {
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('userId', res.data.userId);
                        localStorage.setItem('userName', res.data.userName);
                        navigator("/")

                    } else {
                        sessionStorage.setItem('token', res.data.token);
                        sessionStorage.setItem('userId', res.data.userId);
                        sessionStorage.setItem('userName', res.data.userName);
                        navigator("/")
                    }

                }
                else {
                    antMessage('error', '登录失败', '');
                }
            }).catch(err => {
                antMessage('error', '登录失败', err.message + ' status:' + err.errors.status);
            })
    };

    return (
        <Modal
            title='登录'
            open={true}
            footer={false}
            closable={false}
            maskClosable={false}
            width={400}
            centered
        >
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="logId"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录ID!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />} placeholder="登录ID" />
                </Form.Item>
                <Form.Item
                    name="logPass"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />}
                        type="password"
                        placeholder="密码"
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox onChange={onCheck}>Remember me</Checkbox>
                    </Form.Item>

                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        登 录
                    </Button>
                </Form.Item>
            </Form>

        </Modal>
    );
}

export default Login;