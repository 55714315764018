import { Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import getList from '../universal/getList';
import WebUniversal, { WUExtendPropsType, initDataType } from './WebUniversalNewEx'

//组件 WebGlobalData
const WebGlobalData = (props: any) => {
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'globalData';
    const [id, setId] = useState('');
    const [globalDataRow, setGlobalDataRow] = useState();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'globalData',
                    apiExtend: 'list'
                }).then(res => {
                    if (res.success) {
                        setGlobalDataRow(res.data);
                    }
                })
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /** 查看不同id的全局变量 */
    const idChange = (e: any) => {
        console.log(e);

        setId(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { id: e }
        }
        ref.current.initData(currentData);
    };

    //表头
    const columns = [
        {
            title: '变量内容',
            dataIndex: 'id',
            sorter: true,
            render: (text: any, record: any) => (
                JSON.stringify(record)
            )
        },
    ];

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '全局变量',
        apiId,
        columns,
        showAdd: false,
        showOperation: false,
        showQuery: false
    };

    return (
        <>
            <Select
                showSearch
                defaultValue=""
                onChange={idChange}
                placeholder="变量id"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(globalDataRow, undefined, undefined, true)}
            </Select>
            <text>   </text>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebGlobalData;