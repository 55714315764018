import BrandEdit from './BrandEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 
const WebComponent = (props: any) => {

    /** api服务id */
    const apiId = 'brand';

    //表头
    const columnsHead = [
        {
            title: '品牌英文名称',
            dataIndex: 'brandEnglishName',
            sorter: true,
        },
        {
            title: '品牌名称',
            dataIndex: 'brandName',
            sorter: true,
        },
        {
            title: '公司名称',
            dataIndex: 'companyName',
            sorter: true,
        },
        {
            title: '联系人',
            dataIndex: 'contacts',
        },
        {
            title: '联系电话',
            dataIndex: 'contactCall',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '品牌',
        apiId,
        columns,
        showStates: true,
        EditComponent: BrandEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponent;
