import { useEffect } from 'react';
import FilesGroupEdit from './FilesGroupEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Switch, Tooltip } from 'antd';

/**
 * 组件 FilesGroup 
 * @param props 
 * @returns 
 */
const FilesGroup = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '组名',
            dataIndex: 'groupName',
        },
        {
            title: "服务号",
            dataIndex: 'service',
            render: (text: any) => (
                text
                    ? text.serviceName
                    : ''
            )
        },
        {
            title: '文件夹',
            dataIndex: 'folder',
        },
        {
            title: '日期目录',
            dataIndex: 'isCreateDateDirectory',
            render: (text: any, record: any) => (
                <Tooltip placement="topLeft" title={"是否每天生成日期子目录存放文件，" +
                    "如[是]刚不能自定义子目录?，" +
                    "如[否]可上传自定义子目录(上传带参数subdirectory)。"}>
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                    /></Tooltip>)
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '文件组',
        apiId: 'filesGroup',
        columns,
        showStates: true,
        EditComponent: FilesGroupEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default FilesGroup;