import fetchApi from '../utils/api/fetchApi';
import systematic from './systematic';
import systemExpand from './systemExpand';

const serverUrl = systematic.serverUrl;

const getApiUrl = async (licenseId: string) => {
    return await fetchApi.GET(
        serverUrl + '/system/apiUrl/' + licenseId,
        { "baseUrl": serverUrl });
};
const getFilesApiUrl = async (licenseId: string) => {
    const filesApiUrl = (await systemExpand.filesServer()).apiServiceUrl;
    return await fetchApi.GET(
        filesApiUrl + '/system/apiUrl/' + licenseId,
        { "baseUrl": filesApiUrl });
};
const getUrl = async (apiName: string) => {
    let res = await getApiUrl(apiName);
    let rData = '';
    if (res.success === true) {
        rData = res.data.baseUrl as string + res.data.apiUrl as string;
    }
    return rData;
};
const getFilesUrl = async (apiName: string) => {
    let res = await getFilesApiUrl(apiName);
    let rData = '';
    if (res.success === true) {
        rData = res.data.baseUrl as string + res.data.apiUrl as string;
    }
    return rData;
};

/**
 * 获取api地址
 */
const fetchDataUrl = {
    /**
    * 通用api接口
    * @param apiName api接口名称
    * @param apiVariable ? api接口变量，不需可用undefined
    * @param apiExtend ? api接口扩展名称，不需可用undefined
    */
    general: async (apiName: any, apiVariable?: any, apiExtend?: any) =>
        await getUrl(apiName) +
        (typeof apiExtend !== "undefined" ? apiExtend : '') +
        (typeof apiVariable !== "undefined" ? '/' + apiVariable : ''),

    /**
     * 通用filesApi接口
     * @param apiName api接口名称
     * @param apiVariable ? api接口变量，不需可用undefined
     * @param apiExtend ? api接口扩展名称，不需可用undefined 
     * @returns 
     */
    filesGeneral: async (apiName: any, apiVariable?: any, apiExtend?: any) =>
        await getFilesUrl(apiName) +
        (typeof apiExtend !== "undefined" ? apiExtend : '') +
        (typeof apiVariable !== "undefined" ? '/' + apiVariable : ''),

    // showSingle: async (apiName: string) => await getUrl(apiName) + '/',
    showAll: async (apiName: string) => await getUrl(apiName) + '/',
    // showList: async (apiName: string) => await getUrl(apiName) + 'ShowList',
    showPage: async (apiName: string) => await getUrl(apiName) + 'ShowPage',
    query: async (apiName: string, queryString: string) => await getUrl(apiName) + 'Query?' + queryString,
    create: async (apiName: string) => await getUrl(apiName) + '/',
    update: async (apiName: string) => await getUrl(apiName),
    remove: async (apiName: string) => await getUrl(apiName) + '/',
    register: async (apiName: string) => await getUrl(apiName) + 'Register/',
    other: async (apiName: string) => await getUrl(apiName),
    extend: async (apiName: string, extendName: string) => await getUrl(apiName + extendName) + '/',
    uploadFile: async (apiName: string) => await getUrl(apiName) + 'UploadFile',
    deleteFile: async (apiName: string) => await getUrl(apiName) + 'DeleteFile',
    public: (apiName: string) => serverUrl + '/public/' + apiName + '/',
}

export default fetchDataUrl;



