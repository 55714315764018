import { useEffect, useState } from 'react';
import { Layout, Breadcrumb, Col, Row, Space, Avatar, Button, Popconfirm } from 'antd';
import SiderMenu from './components/system/SiderMenu';
import {
    useNavigate,
    Outlet
} from "react-router-dom";


const { Header, Content, Footer, Sider } = Layout;

const AppBm = (props: any) => {
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false)
    const onCollapse = (collapsed: any) => {
        setCollapsed(collapsed);
    };
    const exit = () => {
        if (localStorage.getItem('token')) {
            localStorage.clear();
            navigate('/login')
        } else  {
            sessionStorage.clear()
            navigate('/login')
        }
    }
    return (
        <Layout style={{ minHeight: '100vh' }}>

            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <div className="logo" style={{ textAlign: 'center', lineHeight: "32px" }}> 菜单</div>
                <SiderMenu />
            </Sider>

            <Layout className="site-layout"style={{ color:"#fff" }} > 
                <Header className="site-layout-background" style={{ padding: 0, height: '8vh', verticalAlign: 'middle' }}>
                    <Row>
                        <Col span={6} >
                            <Breadcrumb>

                            </Breadcrumb>
                        </Col>
                        <Col span={18} >
                            <div className="header-right">
                                <Space>
                                    <Avatar size={30} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>USER</Avatar>
                                    <Popconfirm title="确定要退出登录吗？" onConfirm={exit} okText="确定" cancelText="取消">
                                        <div className='exit' style={{ color: "#1d5edc" }} >登出</div>
                                    </Popconfirm>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>©2023 CopyRight BrandName</Footer>
            </Layout>
        </Layout>
    )
};

export default AppBm;