import { useEffect, useRef } from 'react';
import WebCarouselAdEdit from './WebCarouselAdEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import WebCarouselAdUploadFile from './WebCarouselAdUploadFile';
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';

//组件 
const WebCarouselAd = (props: any) => {
    const apiId = 'webCarouselAd';
    const ref = useRef<any>();
    /** 后端数据defaultService */
    // let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
        }
        //同步获取数据 async - await
        // const fetchData = async () => {
        // }
        // fetchData();

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'adName',
            sorter: true,
        },
        {
            title: '类型',
            dataIndex: 'carouselAdType_Name',
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            render: (val: moment.MomentInput) => <span>{moment(val).format('YYYY-MM-DD')}</span>,
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            render: (val: moment.MomentInput) => <span>{moment(val).format('YYYY-MM-DD')}</span>,
        },
        {
            title: '剩余数',
            dataIndex: 'remainingShows',
            sorter: true,
        },
        {
            title: '显示模式',
            dataIndex: 'adShowModel_Name',
            sorter: true,
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '图片广告',
        apiId,
        columns,
        showUploadFile: true,
        showImage: true,
        showStates: true,
        EditComponent: WebCarouselAdEdit,
        UploadFileComponent: WebCarouselAdUploadFile,
        arrayFilesName: 'carouselAdFiles' 
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebCarouselAd;