import { useEffect, useRef } from 'react';
import StockEdit from './StockEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';

const Stock = (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'stock';
    let handleChangeOne: any;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '股票名称',
            dataIndex: 'stockName',
        },
        {
            title: '所属行业',
            dataIndex: 'industry',
            render: (text: any) => (text ? text.industryName : '')
        },
        {
            title: '上市日期',
            dataIndex: 'listingDate',
            render: (val: moment.MomentInput) =>
                <span>
                    {moment(val).format('YYYY-MM-DD')}
                </span>,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '股票',
        apiId,
        columns,
        showStates: true,
        EditComponent: StockEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Stock;
