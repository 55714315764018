import { Form, Input, Row, Col, Select, Button, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx';
import getList from "../universal/getList";
import fetchApi from '../../utils/api/fetchApi';
import stringHandle from '../../utils/baseLib/stringHandle';
import antMessage from '../../utils/extend/AntdNotification';
import ReactJson from 'react-json-view';
import objectHandle from '../../utils/baseLib/objectHandle';
const { TextArea } = Input;
//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const DevelopApiTest = (props: any) => {
    const [form] = Form.useForm();
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    //项目数据
    const [developProjectsData, setDevelopProjectsData] = useState<any>();
    //方法数据
    const [methodData, setMethodData] = useState<any>();
    //apiExtend数据
    const [apiExtendData, setApiExtendData] = useState<any>();
    //用法的数据
    const [usage, setUsage] = useState<any>();
    //是否使用token
    const [isToken, setIsToken] = useState(false);
    //展示的数据
    const [testResultData, setTestResultData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    /**
     * 开始测试
     */
    const startTest = async () => {
        /** token值 */
        const token = form.getFieldValue('token');
        if (isToken === true && objectHandle.isEmpty(token, true) === true) {
            antMessage('error', '测试错误', 'token值不能为空');
            return;
        }
        /** id */
        const id = form.getFieldValue('id');
        /** 项目id */
        const developProjectsId = form.getFieldValue('developProjectsId');
        /** apiId */
        const apiId = form.getFieldValue('apiId');
        /** api变量 */
        let apiVariable = stringHandle.trim(form.getFieldValue('apiVariable'));
        if (apiVariable === null) {
            apiVariable = '';
        }
        /** api扩展 */
        let apiExtendId = form.getFieldValue('apiExtendId') ?
            form.getFieldValue('apiExtendId') : '';
        if (apiExtendId === null) {
            apiExtendId = '';
        }
        /** api数据 */
        let apiData = stringHandle.trim(form.getFieldValue('apiData'));
        if (apiData === null) {
            apiData = '';
        }
        /** 方法 */
        const methodId = form.getFieldValue('methodId');
        /** 服务地址 */
        const serviceUrl = form.getFieldValue('serviceUrl');
        if (apiId !== null) {
            //测试
            try {
                let res: any = {};
                switch (methodId) {
                    case 'GET':
                        res = await fetchApi.GET(serviceUrl,
                            {
                                apiId,
                                apiVariable,
                                apiExtend: apiExtendId,
                                apiData
                            },
                            undefined,
                            token)
                        break;
                    case 'POST':
                        console.log("xxx",apiData);
                        
                        res = await fetchApi.POST(serviceUrl,
                            {
                                apiId,
                                apiVariable,
                                apiExtend: apiExtendId,
                                apiData: apiData ? JSON.parse(apiData) : undefined
                            },
                            undefined,
                            undefined,
                            token)
                        break;
                    case 'PUT':
                        res = await fetchApi.PUT(serviceUrl,
                            {
                                apiId,
                                apiVariable,
                                apiExtend: apiExtendId,
                                apiData: apiData ? JSON.parse(apiData) : undefined
                            },
                            undefined,
                            undefined,
                            token)
                        break;
                    case 'DELETE':
                        res = await fetchApi.DELETE(serviceUrl,
                            {
                                apiId,
                                apiVariable,
                                apiExtend: apiExtendId,
                                apiData: apiData ? JSON.parse(apiData) : undefined
                            },
                            undefined,
                            undefined,
                            token)
                        break;
                }
                antMessage('success', '测试成功', 'success');
                setTestResultData(res);
                //保存值
                if (token) {
                    localStorage.setItem(developProjectsId + 'Token', token);
                }
                localStorage.setItem(id + 'ApiVariable', apiVariable);
                localStorage.setItem(id + 'ApiData', apiData);
            }
            catch (err: any) {
                antMessage('error', '测试错误', '数据错误，检查apiData数据');
                console.log(err);
                setTestResultData(err);
            }
        }
        else {
            antMessage('error', '测试错误', '数据错误，apiId为空');
        }
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
                if (res.success === true) {
                    setIsToken(res.data.isToken);
                    //获取apiVariable、apiData、serviceUrl、token等值
                    setUsage(res.data.usage);
                    let token: any;
                    if (res.data.isToken === true) {
                        token = localStorage.getItem(res.data.developProjectsId + 'Token');
                    }
                    const localStorageApiVariable = localStorage.getItem(res.data.id + 'ApiVariable');
                    const localStorageApiData = localStorage.getItem(res.data.id + 'ApiData');
                    form.setFieldsValue({
                        token,
                        serviceUrl: res.data.developProjects.serviceUrl,
                        apiVariable: localStorageApiVariable ? localStorageApiVariable : res.data.apiVariable,
                        apiData: localStorageApiData ? localStorageApiData : res.data.apiData
                    })
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'developProjects',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDevelopProjectsData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'method',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setMethodData(res.data);
                    }
                })
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'apiExtend',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setApiExtendData(res.data);
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'developApi',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={'测试开发Api'}
                open={true}
                 okButtonProps={{style:{display:"none"}}}
                cancelText='退出'
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1000}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                >

                    <Row gutter={[8, 0]}>
                        <Col span={4}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="项目"
                                name="developProjectsId">
                                <Select
                                    showSearch
                                    placeholder="项目"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(developProjectsData, 'projectName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="名称"
                                name="apiName">
                                <Input disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="版本"
                                name="version">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="功能"
                                name="effect">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="说明"
                                name="explain">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="apiId"
                                name="apiId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="apiVariable"
                                name="apiVariable">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="方法"
                                name="methodId">
                                <Select
                                    disabled={true}
                                    showSearch
                                    placeholder="方法"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(methodData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="apiExtend"
                                name="apiExtendId">
                                <Select
                                    disabled={true}
                                    showSearch
                                    placeholder="api扩展"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(apiExtendData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="服务地址"
                                name="serviceUrl">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Tooltip placement="topLeft"
                                title={'必须JSON stringify格式，例如: {"name": "小陈","age": 7}'}>
                                <Form.Item
                                    label="apiData"
                                    name="apiData">
                                    <TextArea
                                        rows={2}>
                                    </TextArea>
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={21}>
                            <Form.Item
                                label="token"
                                name="token"
                                rules={[{ required: isToken, message: '请输入token值!' }]}>
                                <TextArea
                                    disabled={!isToken}
                                    placeholder="请输入token值"
                                    rows={3}>
                                </TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item>
                                <Tooltip placement="left" title={'对api接口进行测试，用法：' + usage}>
                                    <Button onClick={() => startTest()}
                                    >开始测试</Button>
                                </Tooltip>
                            </Form.Item>
                        </Col>


                        <ReactJson
                            src={testResultData}
                            collapsed={true}
                            theme="google"
                        />


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default DevelopApiTest;