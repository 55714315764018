import { useEffect } from 'react';
import ShopEdit from './ShopEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 Shops 
const Shops = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '店铺名称',
            dataIndex: 'shopName',
            sorter: true,
        },
        {
            title: '联系人',
            dataIndex: 'contacts',
        },
        {
            title: '联系电话',
            dataIndex: 'contactCall',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '店铺',
        apiId: 'shop',
        columns,
        showStates: true,
        EditComponent: ShopEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default Shops;