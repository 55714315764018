import ExcelJS from "exceljs"
import moment from 'moment';
import objectHandle from "../../utils/baseLib/objectHandle";
//const ExcelJS = require('exceljs');
//import { FileSaver } from 'file-saver';
const FileSaver = require('file-saver');

//组件 DownloadExcel 
const DownloadExcel = (props: any, rows: any) => {

    /** 这个是 【 订单发货 】 的数据和Excel格式 */
    if (props.props.apiId === "webConsignOrder") {
        console.log("该内容是" + props.props.apiId);
        console.log(props);

        // 创建工作薄
        const workbook = new ExcelJS.Workbook();

        // 添加工作表并进行页面设置
        // 使用 A4 横向的页面设置创建新工作表
        const worksheet = workbook.addWorksheet('待发货订单', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });

        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.4, right: 0.3,
            top: 0.5, bottom: 0.5,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '下单时间', width: 12 },
            { header: '(电话)微信用户', width: 14 },
            { header: '品名', width: 27 },
            { header: '数量', width: 7 },
            { header: '成交价', width: 8 },
            { header: '收货地址及联系人', width: 38 },
            { header: '销售', width: 10 },
            { header: '备注', width: 25 }
        ];

        // 数据
        let data = rows
        //console.log("获取表格内容", data)

        let productbegin: any = []     //  用来记录产品开始循环的位置
        let productend: any = []     //  用来记录产品结束循环的位置

        let j = 2

        let dataTable: any = []
        data.map((item: any) => {
            let i = 1;  // 由于productOrderDetails数据里面，最后的2条是没用的，为了防止通过map循环全部读取，这里设置一个i用了记录当前数据的位置
            let productCuror = 1  // 记录商品的指针，用于设置名称等内容

            productbegin.push(j);   // 将每一个数据开始的位置记录，方便用了合并单元格

            item.productOrderDetails.map((productitem: any) => {
                if (i <= item.productOrderDetails.length - 2) {
                    // 在该订单内商品循环,并将内容push入数组
                    if (item.productOrderDetails.length - 2 === 1) {
                        //productbegin.push(j);   // 将每一个数据开始的位置记录，方便用了合并单元格

                        dataTable.push([
                            moment(item.sysDate.createdAt).format('YYYY-MM-DD'), item.wxUser.mobile ? "(" + item.wxUser.mobile + ")" + item.wxUser.nickName : item.wxUser.nickName, productitem.productName + "(" + productitem.productPriceSpec + ")", productitem.amount, productitem.totalPurchasePrice, item.productOrderAddress.receiver + ";" + item.productOrderAddress.receivePhone + ";" + item.productOrderAddress.province + item.productOrderAddress.city + item.productOrderAddress.county + (objectHandle.isEmpty(item.productOrderAddress.town) ? "" : item.productOrderAddress.town) + item.productOrderAddress.detail, productitem.sales ? productitem.sales.salesName : "", item.memo
                        ])
                    }
                    else {
                        if (productCuror === 1) {
                            dataTable.push([
                                moment(item.sysDate.createdAt).format('YYYY-MM-DD'), item.wxUser.mobile ? "(" + item.wxUser.mobile + ")" + item.wxUser.nickName : item.wxUser.nickName, productitem.productName + "(" + productitem.productPriceSpec + ")", productitem.amount, productitem.totalPurchasePrice, item.productOrderAddress.receiver + ";" + item.productOrderAddress.receivePhone + ";" + item.productOrderAddress.province + item.productOrderAddress.city + item.productOrderAddress.county + (objectHandle.isEmpty(item.productOrderAddress.town) ? "" : item.productOrderAddress.town) + item.productOrderAddress.detail, productitem.sales ? productitem.sales.salesName : "", item.memo
                            ])

                            productCuror = productCuror + 1
                        }
                        else {
                            dataTable.push([
                                "", "", productitem.productName + "(" + productitem.productPriceSpec + ")", productitem.amount, productitem.totalPurchasePrice, "", "", ""
                            ])

                            productCuror = productCuror + 1
                        }
                    }

                    // 判断是否已经将该订单内商品循环完了，如果循环完了就显示公共信息内容(如下单时间、备注)

                    if (i === item.productOrderDetails.length - 2) {
                        //console.log( item.id +'的到最后了');
                        if (item.productOrderDetails.length - 2 === 1) {
                            productend.push(j);
                            j = j + 1;
                        }
                        else {
                            productend.push(j + item.productOrderDetails.length - 3);   // 将每一个数据结束的位置记录，方便用了合并单元格
                            j = j + item.productOrderDetails.length - 2;
                        }
                    }


                    i = i + 1;
                }
            })
        })

        // 将数据插入
        worksheet.addRows(dataTable);

        // 设置第一行的行高
        worksheet.getRow(1).height = 20;

        // 设置第一行居中对齐
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('E1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('F1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('G1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('H1').alignment = { vertical: 'middle', horizontal: 'center' };

        // 设置第一行边框
        worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('B1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('C1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('D1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('E1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('F1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('G1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell('H1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        //  设置边框、对齐、合并、行高等操作
        for (var curor = 2; curor <= productend[productend.length - 1]; curor++) {
            //  设置行高
            console.log(curor)
            worksheet.getRow(curor).height = 60;
        }

        for (var k = 0, len = productbegin.length; k < len; k++) {
            if (productend[k] - productbegin[k] >= 0) {

                for (var productroll = productbegin[k]; productroll <= productend[k]; productroll++) {
                    //  设置对齐方式和是否自动换行
                    worksheet.getCell('A' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('B' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('C' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('D' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('E' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('F' + productroll + '').alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
                    worksheet.getCell('G' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell('H' + productroll + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                    // 设置边框
                    worksheet.getCell('A' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('B' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('C' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('D' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('E' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('F' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('G' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                    worksheet.getCell('H' + productroll + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } };
                }
            }
        }

        //  将除了(微信用户、品名、地址)的单元格都设置为居中
        for (var product = 0; product < productbegin.length; product++) {

            if (productend[product] - productbegin[product] > 0) {

                //  合并单元格
                worksheet.mergeCells('A' + productbegin[product] + ':A' + productend[product] + '');
                worksheet.mergeCells('B' + productbegin[product] + ':B' + productend[product] + '');
                worksheet.mergeCells('F' + productbegin[product] + ':F' + productend[product] + '');
                worksheet.mergeCells('G' + productbegin[product] + ':G' + productend[product] + '');
                worksheet.mergeCells('H' + productbegin[product] + ':H' + productend[product] + '');
            }

        }

        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";

        // 将工作薄导出为excel文件
        workbook.xlsx.writeBuffer().then((item) => {
            const blob = new Blob([item], { type: "EXCEL_TYPE" });
            FileSaver.saveAs(blob, '品选名汇小程序  ' + moment().format('YYYY-MM-DD') + '  待发货订单.xlsx');
        })
    }

    /** 这个是 【 营业报表 】 的数据和Excel格式 */
    if (props.props.apiId === "webReport-business") {
        console.log("该内容是" + props.props.apiId);
        console.log(props);

        let chargebackStatus = (props.props.queryCriteria.chargebackStatusId === "") ? "全部" : props.props.queryCriteria.chargebackStatus_Name;     //   退货状态
        let productStatus = (props.props.queryCriteria.productStatusId === "") ? "全部" : props.props.queryCriteria.productStatus_Name;     //   商品状态
        let productShop = (props.props.queryCriteria.shop === undefined) ? "全部" : props.props.queryCriteria.shop.shopName;     //   店铺名称
        let logisticsWarehouse = (props.props.queryCriteria.logisticsWarehouseId === "") ? "全部" : props.props.queryCriteria.logisticsWarehouse_Name;     //   仓库名称

        // 创建工作薄
        const workbook = new ExcelJS.Workbook();

        // 添加工作表并进行页面设置
        // 使用 A4 横向的页面设置创建新工作表
        const worksheet = workbook.addWorksheet('销售报表', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });

        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.75, bottom: 0.75,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 25 },
            { header: '', width: 25 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 15 }
        ];

        // 数据
        let data = rows
        //console.log("获取表格内容", data)

        let dataTable: any = []
        let totalPurchasePrice = 0
        let sumAmount = 0
        let wxServiceCharge = 0
        //let commissionsAmount = 0
        let totalRevenue = 0

        data.map((item: any) => {
            dataTable.push([
                item.productOrderDetail_productName, item.productOrderDetail_productPriceSpec, item.productOrderDetail_productUnit, item.sumAmount, item.totalPurchasePrice, parseFloat((item.totalPurchasePrice * 0.0054).toFixed(2)), "", item.totalPurchasePrice - parseFloat((item.totalPurchasePrice * 0.0054).toFixed(2)), (logisticsWarehouse === "全部") ? "" : logisticsWarehouse, (productShop === "全部") ? item.shop.shopName : productShop
            ])
            totalPurchasePrice += item.totalPurchasePrice;    //  由于ExeclJs不能设置公式计算，所以要提前算好合计数
            sumAmount += item.sumAmount;
            wxServiceCharge += parseFloat((item.totalPurchasePrice * 0.0054).toFixed(2));   //  将手续费进行四舍五入
            //commissionsAmount = commissionsAmount + xxxxxxxx;
            totalRevenue = totalPurchasePrice - wxServiceCharge;
            //console.log(wxServiceCharge);
        })

        // 将数据插入
        worksheet.addRow(["时间:" + (moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + "至" + moment(props.props.queryCriteria.endDate).format('YYYY-MM-DD')) + "    退货状态:" + chargebackStatus + "    产品状态:" + productStatus + "    店铺:" + productShop + "    发货仓库:" + logisticsWarehouse + "", "", "", "", "", ""]);
        worksheet.addRow(['商品名称', '规格', '单位', '销售数量', '销售总额', '服务费', '佣金奖励', '实收金额', '发货仓库', '店铺']);
        worksheet.addRows(dataTable);
        worksheet.addRow(['合计', '', '', '', '', '']);

        // 获取一个行对象。
        //const row = worksheet.lastRow;    // 获取工作表中的最后一个可编辑行（如果没有，则为 `undefined`）
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "营业报表"; // A1 的值设置为"营业报表"
        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 30;

        const totalrow = worksheet.getRow(rows.length + 4);
        totalrow.getCell('D').value = sumAmount;   //  将值设置为销售数量
        totalrow.getCell('E').value = totalPurchasePrice;  // 将值设置为销售总额
        totalrow.getCell('F').value = wxServiceCharge;  // 将值设置为服务费
        //totalrow.getCell('G').value = commissionsAmount;   //  将值设置为佣金奖励
        totalrow.getCell('H').value = totalRevenue;  // 将值设置为实收金额

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 16,
            bold: true
        };

        let sumEnd = rows.length + 4    // 算总数的最后一个位置

        for (var i = 1; i <= sumEnd; i++) {
            if (i === 1) {
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            }
            else {
                if (i === 2) {
                    worksheet.getCell('A' + i + '').border = { left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                }
                else {
                    if (i === len) {
                        worksheet.getRow(i).height = 20;
                    }
                    worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('G' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('H' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('I' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('J' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                }
            }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('I' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('J' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // 合并单元格
        worksheet.mergeCells('A1:J1');
        worksheet.mergeCells('A2:J2');
        worksheet.mergeCells('A' + sumEnd + ':C' + sumEnd + '');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };

        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";

        // 将工作薄导出为excel文件
        if (props.props.queryCriteria.startDate === props.props.queryCriteria.endDate) {
            workbook.xlsx.writeBuffer().then((item) => {
                const blob = new Blob([item], { type: "EXCEL_TYPE" });
                FileSaver.saveAs(blob, '' + moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + '销售报表.xlsx');
            })
        }
        else {
            workbook.xlsx.writeBuffer().then((item) => {
                const blob = new Blob([item], { type: "EXCEL_TYPE" });
                FileSaver.saveAs(blob, '' + moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + "至" + moment(props.props.queryCriteria.endDate).format('YYYY-MM-DD') + '销售报表.xlsx');
            })
        }
    }


    /** 这个是 【 每日报表 】 的数据和Excel格式 */
    if (props.props.apiId === "webReport-daily") {
        console.log("该内容是" + props.props.apiId);
        console.log(props);

        let productShop = (props.props.queryCriteria.shop === undefined) ? "全部" : props.props.queryCriteria.shop.shopName;     //   店铺名称

        // 创建工作薄
        const workbook = new ExcelJS.Workbook();

        // 添加工作表并进行页面设置
        // 使用 A4 横向的页面设置创建新工作表
        const worksheet = workbook.addWorksheet('日报表', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });

        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.75, bottom: 0.75,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 20 },
            { header: '', width: 25 },
            { header: '', width: 20 },
            { header: '', width: 20 }
        ];

        // 数据
        let data = rows
        //console.log("获取表格内容", data)

        let dataTable: any = []
        let sumAmount = 0
        let totalPurchasePrice = 0

        data.map((item: any) => {
            dataTable.push([
                item.createdAt, item.sumAmount, item.totalPurchasePrice, (item.shop === undefined) ? productShop : item.shop.shopName
            ])
            totalPurchasePrice += item.totalPurchasePrice;    //  由于ExeclJs不能设置公式计算，所以要提前算好合计数
            sumAmount += item.sumAmount;
        })

        // 将数据插入
        worksheet.addRow(["时间:" + (moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + "至" + moment(props.props.queryCriteria.endDate).format('YYYY-MM-DD')) + "    店铺:" + productShop + "", "", "", "", "", ""]);
        worksheet.addRow(['日期', '订单数', '销售金额', '店铺']);
        worksheet.addRows(dataTable);
        worksheet.addRow(['合计', '', '', '']);

        // 获取一个行对象。    
        //const row = worksheet.lastRow;    // 获取工作表中的最后一个可编辑行（如果没有，则为 'undefined'）
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "日报表"; // A1 的值设置为"日报表"
        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 30;

        const totalrow = worksheet.getRow(rows.length + 4);
        totalrow.getCell('B').value = sumAmount;  // 将值设置为订单总数
        totalrow.getCell('C').value = totalPurchasePrice;   // 将值设置为销售总价格

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 16,
            bold: true
        };

        let sumEnd = rows.length + 4    // 算总数的最后一个位置

        for (var i = 1; i <= sumEnd; i++) {

            // 设置每个单元格的周围设置单个细边框
            if (i === 1) {
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            }
            else {
                if (i === 2) {
                    worksheet.getCell('A' + i + '').border = { left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                }
                else {
                    if (i === len) {
                        worksheet.getRow(i).height = 20;
                    }
                    worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                }
            }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // 合并单元格
        worksheet.mergeCells('A1:D1');
        worksheet.mergeCells('A2:D2');
        //worksheet.mergeCells('A' + sumEnd + ':C'+ sumEnd +'');    //  合并总计前的单元格

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };

        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";

        // 将工作薄导出为excel文件
        if (props.props.queryCriteria.startDate === props.props.queryCriteria.endDate) {
            workbook.xlsx.writeBuffer().then((item) => {
                const blob = new Blob([item], { type: "EXCEL_TYPE" });
                FileSaver.saveAs(blob, '' + moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + '日报表.xlsx');
            })
        }
        else {
            workbook.xlsx.writeBuffer().then((item) => {
                const blob = new Blob([item], { type: "EXCEL_TYPE" });
                FileSaver.saveAs(blob, '' + moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + "至" + moment(props.props.queryCriteria.endDate).format('YYYY-MM-DD') + '日报表.xlsx');
            })
        }
    }

    /** 这个是 【 销售提成报表 】 的数据和Excel格式 */
    if (props.props.apiId === "webReport-salesCommission") {
        console.log("该内容是" + props.props.apiId);
        console.log(props);

        let productShop = (props.props.queryCriteria.shop === undefined) ? "全部" : props.props.queryCriteria.shop.shopName;     //   店铺名称

        // 创建工作薄
        const workbook = new ExcelJS.Workbook();

        // 添加工作表并进行页面设置
        // 使用 A4 横向的页面设置创建新工作表
        const worksheet = workbook.addWorksheet('销售提成报表', {
            pageSetup: { paperSize: 9, orientation: 'portrait' }   // 纵向：portrait  横向：landscape； paperSize：9为A4纸、8为A3纸、11为A5纸 具体可查看 https://github.com/exceljs/exceljs/blob/master/README_zh.md#页面设置
        });

        // 调整页面设置配置
        worksheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.75, bottom: 0.75,
            header: 0.3, footer: 0.3
        };

        // 添加列标题并定义列键和宽度
        worksheet.columns = [
            { header: '', width: 12 },
            { header: '', width: 25 },
            { header: '', width: 18 },
            { header: '', width: 10 },
            { header: '', width: 10 },
            { header: '', width: 15 }
        ];

        // 数据
        let data = rows
        //console.log("获取表格内容", data)

        let dataTable: any = []
        let totalPurchasePrice = 0
        let commissionsAmount = 0

        data.map((item: any) => {
            dataTable.push([
                (item.sales === undefined) ? "" : item.sales.salesName, item.productOrderDetail_productName, item.productOrderDetail_productPriceSpec, item.totalPurchasePrice, (item.commissionsAmount === undefined) ? 0 : Number(item.commissionsAmount), (item.shop === undefined) ? productShop : item.shop.shopName
            ])
            let commissions = (item.commissionsAmount === undefined) ? 0 : Number(item.commissionsAmount);
            totalPurchasePrice += item.totalPurchasePrice;    //  由于ExeclJs不能设置公式计算，所以要提前算好合计数
            commissionsAmount += commissions;
        })

        // 将数据插入
        worksheet.addRow(["时间:" + (moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + "至" + moment(props.props.queryCriteria.endDate).format('YYYY-MM-DD')) + "    店铺:" + productShop + "", "", "", "", "", ""]);
        worksheet.addRow(['销售人', '商品名称', '规格', '销售金额', '销售提成', '店铺']);
        worksheet.addRows(dataTable);
        worksheet.addRow(['合计', '', '', '', '', '']);

        // 获取一个行对象。    
        //const row = worksheet.lastRow;    // 获取工作表中的最后一个可编辑行（如果没有，则为 `undefined`）
        const titlerow = worksheet.getRow(1);
        titlerow.getCell(1).value = "销售提成报表"; // A1 的值设置为"销售提成报表"
        // 设置标题行的行高
        titlerow.height = 40;

        const searchParameterrow = worksheet.getRow(2);
        // 设置第二行(搜索参数)的行高
        searchParameterrow.height = 30;

        const totalrow = worksheet.getRow(rows.length + 4);
        totalrow.getCell('D').value = totalPurchasePrice;  // 将值设置为销售总价格
        totalrow.getCell('E').value = commissionsAmount;   //  将值设置为销售提成总数量

        // 设置字体
        worksheet.getCell('A1').font = {
            name: '宋体',
            family: 4,
            size: 16,
            bold: true
        };

        let sumEnd = rows.length + 4    // 算总数的最后一个位置

        for (var i = 1; i <= sumEnd; i++) {

            // 设置每个单元格的周围设置单个细边框
            if (i === 1) {
                worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } };
            }
            else {
                if (i === 2) {
                    worksheet.getCell('A' + i + '').border = { left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                }
                else {
                    if (i === len) {
                        worksheet.getRow(i).height = 20;
                    }
                    worksheet.getCell('A' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('B' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('C' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('D' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('E' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                    worksheet.getCell('F' + i + '').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                }
            }

            //  居中对齐并自动换行
            worksheet.getCell('A' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('B' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('C' + i + '').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('D' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F' + i + '').alignment = { vertical: 'middle', horizontal: 'center' };
        }

        // 合并单元格
        worksheet.mergeCells('A1:F1');
        worksheet.mergeCells('A2:F2');
        worksheet.mergeCells('A' + sumEnd + ':C' + sumEnd + '');

        // 居中对齐并自动换行
        worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
        worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center', wrapText: true };

        //  设置页脚(默认居中)
        worksheet.headerFooter.oddFooter = "第 &P 页，共 &N 页";

        // 将工作薄导出为excel文件
        if (props.props.queryCriteria.startDate === props.props.queryCriteria.endDate) {
            workbook.xlsx.writeBuffer().then((item) => {
                const blob = new Blob([item], { type: "EXCEL_TYPE" });
                FileSaver.saveAs(blob, '' + moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + '销售提成报表.xlsx');
            })
        }
        else {
            workbook.xlsx.writeBuffer().then((item) => {
                const blob = new Blob([item], { type: "EXCEL_TYPE" });
                FileSaver.saveAs(blob, '' + moment(props.props.queryCriteria.startDate).format('YYYY-MM-DD') + "至" + moment(props.props.queryCriteria.endDate).format('YYYY-MM-DD') + '销售提成报表.xlsx');
            })
        }
    }

    /** 这个是 【 xx报表 】 的数据和Excel格式 */
    if (props.props.apiId === "xxxxxx") {
        console.log("该内容是" + props.props.apiId);
        console.log(props);
    }



}

export default DownloadExcel;