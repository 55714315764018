import React, { useEffect, useRef } from 'react';
// import WebWxUserAddressEdit from './WebWxUserAddressEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

//组件 WebWxUserAddress 
const WebWxUserAddress = (props: any) => {

    const ref = useRef<any>();
    const apiId = 'webWxUserAddress';
    let handleChangeOne: any = {};

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '联系人',
            dataIndex: 'contacts',
        },
        {
            title: '联系电话',
            dataIndex: 'contactPhone',
        },
        {
            title: '国家或地区',
            dataIndex: 'countryOrArea',
            render: (text: any) => (
                text ? text.countryOrAreaName : ''
            )
        },
        {
            title: '省',
            dataIndex: 'province',
            render: (text: any) => (
                text ? text.provinceName : ''
            )
        },
        {
            title: '城市',
            dataIndex: 'city',
            render: (text: any) => (
                text ? text.cityName : ''
            )
        },
        {
            title: '区.县',
            dataIndex: 'county',
            render: (text: any) => (
                text ? text.countyName : ''
            )
        },
        {
            title: '街道.镇',
            dataIndex: 'town',
            render: (text: any) => (
                text ? text.townName : ''
            )
        },
        {
            title: '详细地地',
            dataIndex: 'detail',
        },
        {
            title: '邮编',
            dataIndex: 'postalCode',
        },
        {
            title: '默认',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChangeOne(record.id, 'isDefault', e, apiId)}
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '微信用户地址',
        apiId,
        columns,
        showStates: true,
        showAdd: false,
        queryTitle: '请输入详细地址或邮编查询',
        showOperation: true,
        showEdit: false
        // EditComponent: WebWxUserAddressEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebWxUserAddress;