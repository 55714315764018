import { useEffect, useRef, useState } from 'react';
import SmsServiceEdit from './SmsServiceEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

/**
 * 组件 
 * @param props 
 * @returns 
 */
const SmsService = (props: any) => {
    const apiId = 'smsService';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    /** 刷新数据 */
    let initData: any;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '服务名称',
            dataIndex: 'serviceName',
        },
        {
            title: '短信服务商',
            dataIndex: 'smsProvider_Name',
        },
        {
            title: '用户号',
            dataIndex: 'userId',
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '短信服务',
        apiId,
        columns,
        showStates: true,
        EditComponent: SmsServiceEdit,
        serviceId: 'message',
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default SmsService;