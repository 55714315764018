import { useEffect } from 'react';
import WebProvinceEdit from './WebProvinceEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';

const WebUniversalList = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '国家或地区',
            dataIndex: 'countryOrArea',
            render: (text: any) => (
                text ? text.countryOrAreaName : ''
            )
        },
        {
            title: '省名称',
            dataIndex: 'provinceName',
        },
        {
            title: 'usName',
            dataIndex: 'usName',
        },
    ];

    const columns = getColumnsheadId({ title: '代码', dataIndex: 'code' })
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '省',
        apiId: 'webProvince',
        columns,
        showStates: true,
        primaryKeyName: 'code',
        EditComponent: WebProvinceEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebUniversalList;