import { Tooltip } from "antd";

/** 位置类型 */
export declare type positionType = 'prefix' | 'suffix';

/**
 * 显示的内容
 * @param str 字符串
 * @param showLength ? 显示的字符长度，默认8位 
 * @param pos 位置
 */
const showText = (text: string, showLength?: number, pos?: positionType) => {
    pos = pos ? pos : 'prefix';
    return (
        <Tooltip placement="topLeft" title={text}>{
            (typeof text !== "undefined" && text !== null) ?
                (pos === 'prefix' ?
                    (text.length >= showLength! ? text.substring(0, showLength) + '......' : text)
                    :
                    (text.length >= showLength! ? '......' + text.substring(text.length - showLength!, text.length) : text)
                )
                : ''
        }</Tooltip>
    )
}

/**
 * 表头id显示
 * @param options 选项
 * @example getColumnsheadId({
        title: '微信',
        dataIndex: 'unionId',
        showLength: 10,
        pos: 'suffix',
        text: any
    })
 * @returns 
 */
const getColumnsheadId = (options?: {
    /** 展示的名称，默认:id */
    title?: string,
    /** 数据名，默认:id */
    dataIndex?: string,
    /** 显示的字符长度，默认8位  */
    showLength?: number,
    /** 位置 */
    pos?: positionType,
    /** 返回模式 */
    returnType?: 'Array' | 'Object',
    /** 文字内容 */
    text?: string
}) => {
    if (typeof options === 'undefined') {
        options = {};
    }
    let { title, dataIndex, showLength, pos, returnType, text } = options;
    showLength = showLength ? showLength : 8;
    pos = pos ? pos : 'prefix';
    returnType = returnType ? returnType : 'Array';
    let columnsheadId: any;
    const getToolTip = (textAny?: string) => {
        return (
            <Tooltip placement="topLeft" title={textAny}>{
                (typeof textAny !== "undefined" && textAny !== null) ?
                    (pos === 'prefix' ?
                        (textAny.length >= showLength! ? textAny.substring(0, showLength) + '......' : textAny)
                        :
                        (textAny.length >= showLength! ? '......' + textAny.substring(textAny.length - showLength!, textAny.length) : textAny)
                    )
                    : ''
            }</Tooltip>
        )
    }
    switch (returnType) {
        case 'Object':
            columnsheadId = getToolTip(text);
            break;
        default:
            columnsheadId = [
                {
                    title: typeof title !== "undefined" ? title : 'id',
                    dataIndex: typeof dataIndex !== "undefined" ? dataIndex : 'id',
                    render: (currentText: any) => getToolTip(currentText)
                },]
            break;
    }
    return columnsheadId;
};

/**
 * 表头id显示，头8位......
 */
const columnsheadId = [
    {
        title: 'id',
        dataIndex: 'id',
        render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            (typeof text !== "undefined" && text !== null) ?
                (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                : ''
        }</Tooltip>
    },];

/**
 * 表头id显示，尾8位......
 */
const columnsheadEndId = [
    {
        title: 'id',
        dataIndex: 'id',
        render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            (typeof text !== "undefined" && text !== null) ?
                (text.length >= 8 ? '......' + text.substring(text.length - 8, text.length) : text)
                : ''
        }</Tooltip>
    },];

/**
 * 表头id显示，Uuid，13位......
 */
const columnsheadUuid = [
    {
        title: 'id',
        dataIndex: 'id',
        render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            typeof text !== "undefined" ?
                (text.length >= 13 ? text.substring(0, 13) + '......' : text)
                : ''
        }</Tooltip>
    },];

export {
    getColumnsheadId,
    columnsheadId,
    columnsheadEndId,
    columnsheadUuid,
    showText
};