//webWx/user
import WebWxUser from '../components/webWx/user/WebWxUser';
import WebSalesLevel from '../components/webWx/user/WebSalesLevel';
import WebSales from '../components/webWx/user/WebSales';
import WebWxUserAddress from '../components/webWx/user/WebWxUserAddress';
import WebCountryOrArea from '../components/webWx/user/WebCountryOrArea';
import WebProvince from '../components/webWx/user/WebProvince';
import WebCity from '../components/webWx/user/WebCity';
import WebCounty from '../components/webWx/user/WebCounty';
import WebTown from '../components/webWx/user/WebTown';
//webWx/ad
import WebCarouselAd from '../components/webWx/ad/WebCarouselAd';

//webWx/general
import WebLogisticsWarehouse from '../components/webWx/general/WebLogisticsWarehouse';
import WebSubscribeMessageType from '../components/webWx/general/WebSubscribeMessageType';
import WebSubscribeMessage from '../components/webWx/general/WebSubscribeMessage';
//webWx/marketing
import WebCoupon from "../components/webWx/marketing/WebCoupon";
import WebUserCoupon from "../components/webWx/marketing/WebUserCoupon";
import WebConversionCode from "../components/webWx/marketing/WebConversionCode";


//webWx/product
import WebProductCategory from '../components/webWx/product/WebProductCategory';
import WebProductType from '../components/webWx/product/WebProductType';
import WebProductUnit from '../components/webWx/product/WebProductUnit';
import WebProductSpecType from '../components/webWx/product/WebProductSpecType';
import WebProduct from '../components/webWx/product/WebProduct';

//webWx/order
import WebProductShoppingCart from "../components/webWx/order/WebProductShoppingCart";
import WebProductOrder from "../components/webWx/order/WebProductOrder";
import WebChargeback from "../components/webWx/order/WebChargeback";
import WebLogisticsOrder from "../components/webWx/order/WebLogisticsOrder";
import WebConsignOrder from "../components/webWx/order/WebConsignOrder";
import WebCommissionsOrder from '../components/webWx/order/WebCommissionsOrder';

//webWx/report
import WebCommissionsOrderHandling from '../components/webWx/report/WebCommissionsOrderHandling';
import WebSalesIncome from '../components/webWx/report/WebSalesIncome';
import WebSalesCashOut from '../components/webWx/report/WebSalesCashOut';
import WebBusinessReport from '../components/webWx/report/WebBusinessReport';
import WebDailyReport from '../components/webWx/report/WebDailyReport';
import WebSalesCommissionReport from '../components/webWx/report/WebSalesCommissionReport';
import WebGiftCardConsumptionList from '../components/webWx/moneyCard/WebGiftCardConsumptionList';
//webWx/lzh
import Industry from '../components/webWx/lzh/Industry';
import Stock from '../components/webWx/lzh/Stock';
import Trading from '../components/webWx/lzh/Trading';
//webWx/moneyCard
import WebGiftMemberCardType from '../components/webWx/moneyCard/WebGiftMemberCardType';
import WebGiftMemberCard from '../components/webWx/moneyCard/WebGiftMemberCard';

const webWxRouters = [
    //webWx/user
    {
        path: "/webWxUser",
        element: <WebWxUser />,
    },
    {
        path: "/webSalesLevel",
        element: <WebSalesLevel />,
    },
    {
        path: "/webSales",
        element: <WebSales />,
    },
    {
        path: "/webWxUserAddress",
        element: <WebWxUserAddress />,
    },
    {
        path: "/webCountryOrArea",
        element: <WebCountryOrArea />,
    },
    {
        path: "/webProvince",
        element: <WebProvince />,
    },
    {
        path: "/webCity",
        element: <WebCity />,
    },
    {
        path: "/webCounty",
        element: <WebCounty />,
    },
    {
        path: "/webTown",
        element: <WebTown />,
    },

    //webWx/ad
    {
        path: "/webCarouselAd",
        element: <WebCarouselAd />,
    },
    //webWx/general  
    {
        path: "/webLogisticsWarehouse",
        element: <WebLogisticsWarehouse />,
    },
    {
        path: "/webSubscribeMessageType",
        element: <WebSubscribeMessageType />,
    },
    {
        path: "/webSubscribeMessage",
        element: <WebSubscribeMessage />,
    },

    //webWx/marketing
    {
        path: "/webCoupon",
        element: <WebCoupon />,
    },
    {
        path: "/webUserCoupon",
        element: <WebUserCoupon />,
    },
    {
        path: "/webConversionCode",
        element: <WebConversionCode />,
    },

    //webWx/product
    {
        path: "/webProductCategory",
        element: <WebProductCategory />,
    },
    {
        path: "/webProductType",
        element: <WebProductType />,
    },
    {
        path: "/webProductUnit",
        element: <WebProductUnit />,
    },
    {
        path: "/webProductSpecType",
        element: <WebProductSpecType />,
    },
    {
        path: "/webProduct",
        element: <WebProduct />,
    },

    //wxWeb/order
    {
        path: "/webProductShoppingCart",
        element: <WebProductShoppingCart />,
    },
    {
        path: "/webProductOrder",
        element: <WebProductOrder />,

    },
    {
        path: "/webChargeback",
        element: <WebChargeback />,
    },
    {
        path: "/webLogisticsOrder",
        element: <WebLogisticsOrder />,
    },
    {
        path: "/webConsignOrder",
        element: <WebConsignOrder />,
    },
    {
        path: "/webCommissionsOrder",
        element: <WebCommissionsOrder />,
    },

    //wxWeb/report
    {
        path: "/webCommissionsOrderHandling",
        element: <WebCommissionsOrderHandling />,
    },
    {
        path: "/webSalesIncome",
        element: <WebSalesIncome />,
    },
    {
        path: "/webSalesCashOut",
        element: <WebSalesCashOut />,
    },
    {
        path: "/webBusinessReport",
        element: <WebBusinessReport />,
    },
    {
        path: "/webDailyReport",
        element: <WebDailyReport />,
    },
    {
        path: "/webSalesCommissionReport",
        element: <WebSalesCommissionReport />,
    },
    {
        path: "/webGiftCardConsumptionList",
        element: <WebGiftCardConsumptionList />,
    },
    //webWx/lzh
    {
        path: "/industry",
        element: <Industry />,
    },
    {
        path: "/stock",
        element: <Stock />,
    },
    {
        path: "/trading",
        element: <Trading />,
    },

    //moneyCard
    {
        path: "/webGiftMemberCardType",
        element: <WebGiftMemberCardType />,

    },
    {
        path: "/webGiftMemberCard",
        element: <WebGiftMemberCard />,
    },

]

export default webWxRouters;