import AntDraggableModal from '../../utils/extend/AntDraggableModal'
import SystemLogger from './logger/SystemLogger';
import OpLogger from './logger/OpLogger';
import WxPayLogger from './logger/PayLogger';

/** 日志类型枚举 */
declare enum loggerTypeEnum {
    /** 操作日志 */
    opLogger = 'opLogger',
    /** 系统日志 */
    systemLogger = 'systemLogger',
    /** 微信支付 */
    payLogger = 'payLogger'
};

/** 日志类型详情 */
declare interface loggerTypeDetails {
    [loggerTypeEnum.opLogger]: {
        /** id */
        id: 'opLogger',
        /** 名称 */
        name?: '操作日志'
    },
    [loggerTypeEnum.systemLogger]: {
        /** id */
        id: 'systemLogger',
        /** 名称 */
        name?: '系统日志'
    },
    [loggerTypeEnum.payLogger]: {
        /** id */
        id: 'payLogger',
        /** 名称 */
        name?: '微信支付日志'
    },
};

/** 日志类型 */
export declare type loggerType = loggerTypeDetails[loggerTypeEnum];

const WebUniversalLogger = (props: any) => {
    const { data, closeModal } = props;
    const { logger, queryType, queryId } = data;
    let loggerName = '操作';
    let Logger = OpLogger;
    if (logger) {
        switch (logger.id) {
            case 'systemLogger':
                Logger = SystemLogger;
                loggerName = '系统';
                break;
            case 'payLogger':
                Logger = WxPayLogger;
                loggerName = '支付';
                break;
            default:
                Logger = OpLogger;
                loggerName = '操作';
                break;
        }
    }
    return (
        <>
            <AntDraggableModal
                title={`${loggerName}【${queryType}${queryId ? '-' + queryId : ''}】日志`}
                open={true}
                okText='关闭'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={closeModal}
                closable={false}
                maskClosable={false}
                width={1080}
            >
                <Logger data={data} />
            </AntDraggableModal>
        </>
    )
}

export default WebUniversalLogger;