import { Table, Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { InitData } from '../../store/system/showPage';
import fetchApi from '../../utils/api/fetchApi'
import httpUrl from '../../config/httpUrl'
import antMessage from '../../utils/extend/AntdNotification'
import WebBehaviors from './behaviors/WebBehaviorsNew';

//Redux-React
const mapStateToProps = (state: {}) => {
    return {
        ...state,
    };
};
const mapDispatchToProps = { InitData }

/**
 * 通用Web组件 WebUniversal 
 * @param props 
 * @param ref 
 * @returns 
 */
let WebUniversal = (props: any, ref: any) => {
    const {
        arrayFilesName,
        /**
         * 编辑组件
         */
        EditComponent,
        /**
         * 文件上传组件
         */
        UploadFileComponent,
        /**
         * 标题
         */
        titleName,
        /**
         * 表头名称
         */
        apiName,
        /**
         * 主键名称
         */
        primaryKeyName,
        filePath,
        /**
         * 是否显示文件上传按键
         */
        showUploadFile,
        /**
         * 是否显示图片
         */
        showImage,
        /**
         * 是否显示状态
         */
        showStates,
        /**
         * 是否显示操作区域
         */
        showOperation,
        /**
         * 是否显示删除键
         */
        showDelete,
        /**
         * 是否显示编辑键
         */
        showEdit,
        /** 是否显示打印键 */
        showPrint,
        /**
         * 是否禁用增加键
         */
        disabledAdd,
        /**
         * 是否禁用上传键
         */
        disabledUpload,
        /**
         * 页面传送其它数据
         */
        sendPageData,
        /**
         * 是否有子表格
         */
        isExpandable,
        /**
         * 子表格表头
         */
        expandableColumns,
        expandableDataName,
        //总结栏
        summaryRender,
    } = props.props; //这个由父组件传过来
    let {
        /**
         * 是否显示增加键
         */
        showAdd } = props.props;//这个由父组件传过来
    if (typeof showAdd === "undefined") { showAdd = true };


    /**
     * 
     */
    const [expandedData, setExpandedData] = useState<any>();
    /**
     * 子表格
     * @param item 
     * @returns 
     */
    const expandedRowRender = (item: any) => {
        return (
            <Table
                columns={expandableColumns}
                pagination={false}
                dataSource={
                    expandedData[item.id].constructor === Array ?
                        expandedData[item.id] :
                        [expandedData[item.id]]}
            />
        )
    }

    /**
     * 展开时触发的回调
     * @param status 
     * @param item 
     * @returns 
     */
    const onExpandClick = (status: any, item: any) => {
        if (!status) return
        setExpandedData({ ...expandedData, [item.id]: item[expandableDataName] })
    }

    const { InitData, showPageData } = props;
    let data: any = {
        pageSize: 10,
        current: 1,
        queryString: '',
        total: 0,
        rows: undefined,
        extendData: {}
    };

    try {
        if (typeof showPageData[apiName].data !== "undefined") {
            data = showPageData[apiName].data;
        }
    } catch { }
    let { pageSize,
        current,
        total,
        rows } = data;
    const [modalVisible, setModalVisible] = useState(false);
    const [modalUploadVisible, setModalUploadVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [queryInput, setQueryInput] = useState(showPageData.queryString);
    const [uploadSendData, setUploadSendData] = useState<any>();
    let loading = false;
    let initialState = {
        apiName
    } as any;

    if (total === 0) { rows = undefined };
    useEffect(() => {
        const initStartData = async () => {
            let fetchUrl = await httpUrl.showPage(apiName);
            if (typeof apiName !== "undefined") {
                initialState[apiName] = {
                    data: {
                        current: 1,
                        pageSize: 10,
                        queryString: '',
                        orderByString: ''
                    },
                    fetchUrl,
                    sendPageData,
                };
                InitData(initialState);
            }
        }
        initStartData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //改变数据
    const handleChangeOne = async (id: number, columnName: string, value: boolean, urlName: string) => {
        const bodys = {
            columnName,
            value,
        };
        if (await fetchApi.PUT(await httpUrl.general(urlName, id, 'UpdateOne'), bodys)) {
            antMessage('success', 'updateOne编辑成功', 'success');
            InitData({
                ...showPageData,
            });
            return true;
        }
        else {
            antMessage('error', '删编辑失败', 'error');
            return false;
        }
    };

    //ref 父组件可用函数
    useImperativeHandle(ref, () => ({
        handleChangeOne: (id: number, columnName: string, value: boolean, urlName: string) =>
            handleChangeOne(id, columnName, value, urlName),
        /**
         * 刷新数据
         * @param sendPageData 状态
         * @returns 
         */
        initData: (sendPageData: any) => {
            showPageData[apiName].sendPageData = sendPageData
            InitData({
                ...showPageData,
            })
        },
    }));

    /**
     * 删除动作
     * @param recordData 
     */
    const handleDelete = async (recordData: any) => {
        let id = recordData.id;
        if (typeof primaryKeyName !== "undefined") {
            id = recordData[primaryKeyName]
        }
        fetchApi.DELETE(await httpUrl.remove(apiName) + id).then(res => {
            if (res.success === true) {
                antMessage('success', '删除成功', 'success');
                //刷新表格内容
                InitData({
                    ...showPageData,
                });
            }
            else {
                antMessage('error', '删除失败', 'error');
            }
        }).catch(err => {
            antMessage('error', '删除失败', err.message);
        })
    };

    //编辑
    const handleEdit = (recordData: any) => {
        let id = recordData.id;
        if (typeof primaryKeyName !== "undefined") {
            id = recordData[primaryKeyName]
        }
        openModal('EDIT', id);
    };

    //文件上传
    const hanldUpload = async (e: any) => {
        setUploadSendData({
            id: e,
            disabledUpload,
            // handleChangeOne,

        })
        setModalUploadVisible(true);
    };

    //关闭上传
    const onCloseUpload = (state: boolean) => {
        setModalUploadVisible(false);
        // if (state === true) {
        //刷新表格内容
        InitData({
            ...showPageData,
        });
        // }
    };

    //排序
    const handleSort = (sort: any) => {
        let orderByString = '';
        switch (sort.order) {
            case 'descend':
                orderByString = sort.field + '@DESC'
                break;
            case 'ascend':
                orderByString = sort.field + '@ASC'
                break;
            default:
                break;
        }
        //刷新表格内容
        if (loading === false) {
            initialState[apiName] = {
                data: {
                    orderByString,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    let behaviorsData = {
        showImage,
        showUploadFile,
        showStates,
        showOperation,
        showDelete,
        showEdit,
        filePath,
        hanldUpload,
        handleDelete,
        handleEdit,
        handleChangeOne,
        apiId: apiName,
        InitData,
        showPageData,
        behaviorsColumns: props.props.columns,
        arrayFilesName
    };

    let columns = WebBehaviors(behaviorsData);

    let sendData = {
        type: '',
        id: '',
        sendPageData: {},

    }

    //打开[新增-编辑]框
    const openModal = (type: string, id: string) => {
        sendData.type = type;
        sendData.id = id;
        sendData.sendPageData = sendPageData;
        setModalData(sendData);
        setModalVisible(true);
    }

    //子组件回调函数，关闭modal
    const onCloseModal = (state: boolean) => {
        setModalVisible(false)
        if (state === true) {
            //刷新表格内容
            InitData({
                ...showPageData,
            });
        }
    }

    //查询
    const handleQuery = (e: any) => {
        let value = e.target.value;
        setQueryInput(value);
    };

    const queryData = (queryString: string) => {
        initialState[apiName] = {
            data: {
                queryString,
                current: data.queryString === queryString ? data.current : 1,
            },
        };
        InitData(initialState);
    };

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize,
        current,
        total,
        onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
        onChange: (current: number) => changePage(current, pageSize),
    };

    // 回调函数，切换页数
    const changePage = (current: number, pageSize: number) => {
        if (loading === false) {
            initialState[apiName] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    }

    // 回调函数,每页显示多少条
    const changePageSize = (pageSize: number, current: number) => {
        if (loading === false) {
            initialState[apiName] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    /** 打印 */
    const handlePrint = (id: string) => {
        window.document.body.innerHTML = window.document.getElementById(id)?.innerHTML!;
        // let x: any = window.document.body.getElementsByClassName(id);
        // window.alert(x)
        // var link = window.document.createElement('link');
        // link.type = 'text/css';
        // link.rel = 'stylesheet';
        // link.href = '~antd/dist/antd.css';
        window.print();
        window.location.reload();
    };

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                {showAdd ?
                    <Button disabled={disabledAdd} onClick={() => openModal('NEW', '')}>新增{titleName}</Button>
                    : ''}
                <Input
                    placeholder="请输入查询内容"
                    defaultValue={queryInput}
                    onChange={(e: any) => handleQuery(e)}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter')
                            queryData(queryInput)
                    }}
                />
                <Button icon={<SearchOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />} onClick={() => queryData(queryInput)}>查询</Button>
                {showPrint ?
                    <Button onClick={() => handlePrint('table')} >打印</Button>
                    : ''}
            </Space>
            <Table
                id='table'
                rowKey={row => (primaryKeyName ? row[primaryKeyName] : row.id)}
                columns={columns}
                dataSource={rows}
                pagination={paginationProps}
                size="small"
                onChange={(_a, _b, par) => handleSort(par)}
                //子表格
                expandable={isExpandable ? { expandedRowRender } : undefined}
                onExpand={onExpandClick} // 展开时触发的回调
                //总结栏
                summary={() => {
                    if (typeof summaryRender === 'undefined') {
                        return null;
                    }
                    let sumData
                    if (data['extendData']) {
                        sumData = data['extendData'][summaryRender.sumDataName]
                        console.log(sumData);
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>总计:</Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    {sumData}
                                </Table.Summary.Cell >
                            </Table.Summary.Row>
                        )

                    }


                }}
            //
            />
            {modalVisible ? (<EditComponent closeModal={onCloseModal} data={modalData} />) : null}
            {modalUploadVisible ? (<UploadFileComponent onClose={onCloseUpload} data={uploadSendData} />) : null}
        </>
    )
}

/**
 * 通用Web组件 WebUniversal 
 * @param props 
 * @param ref 
 * @returns 
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true })
    (forwardRef(WebUniversal));
