import { useEffect, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import systematic from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import { Anchor, Button, Col, Form, Row, Switch } from 'antd';
import stringHandle from '../../../utils/baseLib/stringHandle';
import antMessage from '../../../utils/extend/AntdNotification'
import { InputNumber } from 'antd';

/** 角色或用户权限（特殊）扩展 */
const CustomComponent = (props: any) => {
    const { closeModal, useType, info } = props;
    const { roleOrUserId, itemName } = info;
    let id = 'roleId';
    /** 执行的apiId */
    let roleOrUserApiId = 'roleLicenseExpand';
    if (useType === 'userLicense') {
        id = 'userId';
        roleOrUserApiId = 'userLicenseExpand';
    }
    const [form] = Form.useForm(); //form 
    const [licenseTypeData, setLicenseTypeData] = useState<any>();
    const [divCmpt, setDivCmpt] = useState<any>();
    const [buttonCmpt, setButtonCmpt] = useState<any>();
    /** 保存数据方法 */
    var saveFunction: Function;
    /** 后端数据bmService */
    const bmService = systematic.bmService;

    let title: any;
    switch (useType) {
        case 'userLicense':
            title = '用户 [ ' + itemName + ' ] 权限（特殊）扩展';
            break;
        case 'roleLicense':
            title = '角色 [ ' + itemName + ' ] 权限（特殊）扩展';
            break;
    };

    useEffect(() => {
        const fetchData = async () => {
            fetchApi.GET(bmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'licenseType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        let arr: any = [];
                        res.data.map((item: any) => {
                            arr = arr.concat({
                                key: item.id,
                                href: '#' + item.id,
                                title: item.name,
                                id: item.id
                            })
                            return undefined;
                        })
                        setLicenseTypeData(arr);
                        // getDivComponent("#wisca")
                    }
                });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = () => {
        closeModal();
    };

    /**
     * 初始化组件
     * @param licenseType 权限类型
     */
    const getDivComponent = (licenseTypeId: any) => {
        licenseTypeId = stringHandle.removeExtension(licenseTypeId, '#', 3);
        switch (licenseTypeId) {
            case 'web':
                //生成组件
                setDivCmpt(<div id="licenseManage">
                    <Form
                        form={form}
                        name="Edit">
                        <Row gutter={[8, 0]}>
                            {/* 以下可以修改 */}
                            <Col span={8}>
                                <Form.Item
                                    label="仓库统计数据不跟随仓库："
                                    name="statisticsIsEn"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="是"
                                        unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                            {/* 以下可以修改 */}
                            <Col span={8}>
                                <Form.Item
                                    label="部门不跟随区域："
                                    name="deptIsArea"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="是"
                                        unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                            {/* --- */}
                        </Row>
                    </Form>
                </div>);
                //获取数据
                fetchApi.GET(bmService,
                    {
                        apiId: roleOrUserApiId,
                        apiExtend: 'getLicense',
                        apiData: {
                            [id]: roleOrUserId,
                            licenseTypeId
                        }
                    }).then(res => {
                        if (res.success) {
                            try {
                                /** 用户权限扩展对象数据 */
                                const licenseExpandObj = JSON.parse(res.data);
                                //以下可以修改
                                const { statisticsIsEn, deptIsArea } = licenseExpandObj;
                                form.setFieldsValue({
                                    "statisticsIsEn": statisticsIsEn,
                                    "deptIsArea": deptIsArea,
                                })
                                //--------
                            }
                            catch (err: any) {
                                antMessage('error', '无法获取数据！', err.message);
                            }
                        }
                    }).catch(err => {
                        console.log("catch", err);
                        antMessage('error', '无法获取数据！', err.message);
                    });
                //保存数据
                saveFunction = () => {
                    /** 用户权限扩展 */
                    const licenseExpandObj = {
                        //以下可以修改
                        statisticsIsEn: Boolean(form.getFieldValue('statisticsIsEn')),
                        deptIsArea: Boolean(form.getFieldValue('deptIsArea')),
                        //---
                    }
                    fetchApi.PUT(bmService,
                        {
                            apiId: roleOrUserApiId,
                            apiExtend: 'setLicense',
                            apiData: {
                                [id]: roleOrUserId,
                                licenseTypeId,
                                licenseExpand: JSON.stringify(licenseExpandObj)
                            }
                        }).then(res => {
                            if (res.success) {
                                antMessage('success', '保存数据成功！', 'success');
                                closeModal();
                            }
                        }).catch(err => {
                            antMessage('error', '保存数据失败！', err.message);
                        });
                }
                setButtonCmpt(
                    <Button onClick={() => saveFunction()}>保存数据</Button>
                )
                break;
            case 'wx':
                //生成组件
                setDivCmpt(<div id="wxManageWisca">
                    <Form
                        form={form}
                        name="Edit">
                        <Row gutter={[8, 0]}>
                            {/* 以下可以修改 */}
                            <Col span={6}>
                                <Form.Item
                                    label="收货单验收签名："
                                    name="puOrderAcSign"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="查看完成收货订单："
                                    name="checkPuOrderCom"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="调拨单发出签名："
                                    name="trOrderExSign"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="调拨单运输签名："
                                    name="trOrderTrSign"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="调拨单验收签名："
                                    name="trOrderAcSign"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="查看完成调拨订单"
                                    name="checkTrOrderCom"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="惠食佳管理微信订阅"
                                    name="wiscaMaWxSub"
                                    valuePropName="checked">
                                    < Switch checked={false}
                                        checkedChildren="启用"
                                        unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="查看完成调拨订单时间:"
                                    name="checkTrOrderComTime"
                                >
                                    <InputNumber addonAfter={"分钟"} min={0} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="查看完成收货订单时间："
                                    name="checkPuOrderComTime"
                                >
                                    <InputNumber addonAfter={"分钟"} min={0} />
                                </Form.Item>
                            </Col>

                            {/* sub */}
                        </Row>
                    </Form>
                </div>);
                fetchApi.GET(bmService,
                    {
                        apiId: roleOrUserApiId,
                        apiExtend: 'getLicense',
                        apiData: {
                            [id]: roleOrUserId,
                            licenseTypeId
                        }
                    }).then(res => {
                        if (res.success) {
                            try {
                                /** 用户权限扩展对象数据 */
                                const licenseExpandObj = JSON.parse(res.data);

                                //以下可以修改
                                const { puOrderAcSign, checkPuOrderCom, trOrderExSign, trOrderTrSign, trOrderAcSign, checkTrOrderCom, wiscaMaWxSub, checkTrOrderComTime, checkPuOrderComTime } = licenseExpandObj;
                                form.setFieldsValue({
                                    'puOrderAcSign': puOrderAcSign,
                                    "checkPuOrderCom": checkPuOrderCom,
                                    "trOrderExSign": trOrderExSign,
                                    "trOrderTrSign": trOrderTrSign,
                                    "trOrderAcSign": trOrderAcSign,
                                    "checkTrOrderCom": checkTrOrderCom,
                                    "wiscaMaWxSub": wiscaMaWxSub,
                                    "checkTrOrderComTime": checkTrOrderComTime,
                                    "checkPuOrderComTime": checkPuOrderComTime,

                                })
                                //--------
                            }
                            catch (err: any) {
                                antMessage('error', '无法获取数据！', err.message);
                            }
                        }
                    }).catch(err => {
                        console.log("catch", err);
                        antMessage('error', '无法获取数据！', err.message);
                    });
                //保存数据
                saveFunction = () => {
                    /** 权限扩展 */
                    const licenseExpandObj = {
                        //以下可以修改
                        puOrderAcSign: Boolean(form.getFieldValue('puOrderAcSign')),
                        checkPuOrderCom: Boolean(form.getFieldValue('checkPuOrderCom')),
                        trOrderExSign: Boolean(form.getFieldValue('trOrderExSign')),
                        trOrderTrSign: Boolean(form.getFieldValue('trOrderTrSign')),
                        trOrderAcSign: Boolean(form.getFieldValue('trOrderAcSign')),
                        checkTrOrderCom: Boolean(form.getFieldValue('checkTrOrderCom')),
                        wiscaMaWxSub: Boolean(form.getFieldValue('wiscaMaWxSub')),
                        checkTrOrderComTime: form.getFieldValue('checkTrOrderComTime'),
                        checkPuOrderComTime: form.getFieldValue('checkPuOrderComTime')

                    }
                    console.log("xxxxxxxx", licenseExpandObj);

                    fetchApi.PUT(bmService,
                        {
                            apiId: roleOrUserApiId,
                            apiExtend: 'setLicense',
                            apiData: {
                                [id]: roleOrUserId,
                                licenseTypeId,
                                licenseExpand: JSON.stringify(licenseExpandObj)
                            }
                        }).then(res => {
                            if (res.success) {
                                antMessage('success', '保存数据成功！', 'success');
                                closeModal();
                            }
                        }).catch(err => {
                            antMessage('error', '保存数据失败！', err.message);
                        });
                }
                setButtonCmpt(<Button onClick={() => saveFunction()}>保存数据</Button>);
                break;
            default:
                setDivCmpt(undefined);
                setButtonCmpt(undefined);
                break;
        }
    };

    return (
        <>
            <AntDraggableModal
                title={title}
                open={true}
                okText='关闭'
                onOk={handleOk}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                maskClosable={false}
                width={800}>
                <Anchor
                    affix={true}
                    direction='horizontal'
                    items={licenseTypeData}
                    onChange={getDivComponent}
                />
                <br />
                <div id='main'>
                    {divCmpt}
                    {buttonCmpt}
                </div>
            </AntDraggableModal>
        </>
    )
}

export default CustomComponent;