import { columnsheadId } from '../../universal/columnshead';
import LicenseGroupEdit from './LicenseGroupEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'

//组件 LicenseGroup 
const LicenseGroup = (props: any) => {

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '权限组',
            dataIndex: 'licenseGroupName',
            sorter: true,
        },
        {
            title: '图标',
            dataIndex: 'icon',
        },
        {
            title: '排序',
            dataIndex: 'licenseGroupSort',
            sorter: true,
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '权限组',
        apiId: 'licenseGroup',
        columns,
        pagination:true,
        EditComponent: LicenseGroupEdit,
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default LicenseGroup;