import React, { useEffect } from 'react';
import DeliveryAddressEdit from './DeliveryAddressEdit';
import WebUniversal from '../../system/WebUniversal'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

//组件 DeliveryAddress 
const DeliveryAddress = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '用户',
            dataIndex: 'user_Name',
        },
        {
            title: '微信',
            dataIndex: 'wxUser_Name',
        },
        {
            title: '收货人',
            dataIndex: 'deliveryName',
        },
        {
            title: '电话',
            dataIndex: 'phone',
        },
        {
            title: '邮编',
            dataIndex: 'postcode',
        },
        {
            title: '默认',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChangeOne(record.id, 'isDefault', e, apiName)}
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: any = {
        ...props,
        titleName: '收货地址',
        apiName: 'deliveryAddress',
        columns,
        showStates: true,
        EditComponent: DeliveryAddressEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default DeliveryAddress;