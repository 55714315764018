/** 对象方法 */
export default async function objectHandle() { };


// 类型检查
/**
 * 类型检查
 * @param obj 对象
 * @returns 
 */
objectHandle.isObject = (obj: any) => {
    return Object.prototype.toString.call(obj) === '[object Object]'
};

/**
 * 类型检查Copy
 * typeof null //"object"
 * typeof {} //"object"
 * typeof [] //"object"
 * typeof function foo(){} //"function" (特殊情况)
 * @param obj 对象
 * @returns 
 */
objectHandle.isObjectCopy = (obj: any) => {
    return typeof obj === 'object' && obj !== null
};

/**
 * 合并对象
 * @param def 默认对象(合并时会被修改)
 * @param obj 所要合并对象
 * @param numberAdd 是否需要数值相加，默认false
 * @returns 返回合并后对象
 */
objectHandle.mergeObject = (def: { [x: string]: any; }, obj: { [x: string]: any; }, numberAdd?: boolean) => {
    // console.log('----------start------------');
    // console.log('def:');
    // console.log(def);
    // console.log('obj:');
    // console.log(obj);
    // console.log('--------end--------------');
    if (!obj) {
        return def;
    }
    else if (!def) {
        return obj;
    }
    for (var i in obj) {
        // if its an object
        if (typeof obj[i] !== 'undefined' && obj[i] !== null && obj[i].constructor === Object) {
            def[i] = objectHandle.mergeObject(def[i], obj[i]);
        }
        // if its an array, simple values need to be joined.  Object values need to be re-merged.
        else if (typeof obj[i] !== 'undefined' && obj[i] !== null && (obj[i] instanceof Array) && obj[i].length > 0) {
            // test to see if the first element is an object or not so we know the type of array we're dealing with.
            if (obj[i][0].constructor === Object) {
                var newobjs = [];
                // create an index of all the existing object IDs for quick access.  There is no way to know how many items will be in the arrays.
                var objids = {} as any;
                for (var x = 0, l = def[i].length; x < l; x++) {
                    objids[def[i][x].id] = x;
                }
                // now walk through the objects in the new array
                // if the ID exists, then merge the objects.
                // if the ID does not exist, push to the end of the def array
                for (let x = 0, l = obj[i].length; x < l; x++) {
                    var newobj = obj[i][x];
                    if (typeof objids[newobj.id] !== "undefined" && objids[newobj.id] !== null) {
                        def[i][x] = objectHandle.mergeObject(def[i][x], newobj);
                    }
                    else {
                        newobjs.push(newobj);
                    }
                }
                for (let x = 0, l = newobjs.length; x < l; x++) {
                    def[i].push(newobjs[x]);
                }
            }
            else {
                for (let x = 0; x < obj[i].length; x++) {
                    let idxObj = obj[i][x];
                    if (def[i].indexOf(idxObj) === -1) {
                        def[i].push(idxObj);
                    }
                }
            }
        }
        else {
            if (isNaN(obj[i]) || i.indexOf('_key') > -1) {
                def[i] = obj[i];
            }
            else {
                //不需要数值相加
                if (numberAdd === true) {
                    def[i] += obj[i];
                }
                else {
                    def[i] = obj[i];
                }
            }
        }
    }
    // console.log('finish: ');
    // console.log(def);
    // console.log('------------finish-----------');
    return def;
};

/**
 * 判断对象是否为空：undefined、null、''
 * @param obj 对象
 * @param force 是否对字符’undefined' 判断为空值
 * @returns boolean
 */
objectHandle.isEmpty = (obj: any, force?: boolean) => {
    let res = false;
    if (typeof obj === 'undefined' || obj === null || obj === '') {
        res = true;
    }
    if (force === true) {
        if (obj === 'undefined') {
            res = true;
        }
    }
    return res;
};