import { Form, Input, Row, Col, Switch, Select, InputNumber, DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from "../../universal/getList";

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebChargebackEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [chargebackTypeData, setChargebackTypeData] = useState<any>();
    const [chargebackReasonData, setChargebackReasonData] = useState<any>();
    const [chargebackProcessData, setChargebackProcessData] = useState<any>();

    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
                console.log(res);
                if (res.success === true) {
                    form.setFieldValue('createdAt', moment(res.data.sysDate.createdAt));
                    form.setFieldValue('updatedAt', moment(res.data.sysDate.updatedAt));
                }

            })

        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'chargebackType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setChargebackTypeData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'chargebackReason',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setChargebackReasonData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'chargebackProcess',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setChargebackProcessData(res.data);
                    }
                });
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webChargeback'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '退单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                label="退款金额"
                                name="refundAmount">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="退单类型"
                                name="chargebackTypeId"
                                rules={[{ required: true, message: '退单类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="退单类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(chargebackTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="退单原因"
                                name="chargebackReasonId">
                                <Select
                                    showSearch
                                    placeholder="退单原因"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(chargebackReasonData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="退单流程"
                                name="chargebackProcessId">
                                <Select
                                    showSearch
                                    placeholder="退单流程"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(chargebackProcessData)}
                                </Select>
                            </Form.Item>
                        </Col>



                        <Col span={12}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="完成"
                                name="isFinish"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="创建时间"
                                name="createdAt">
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="更新时间"
                                name="updatedAt">
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebChargebackEdit;