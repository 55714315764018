import Brand from '../components/basic/general/Brand';
import Company from '../components/basic/general/Company';
import Shop from '../components/basic/general/Shop';
import DeliveryAddress from '../components/basic/general/DeliveryAddress';


import LicenseGroup from '../components/basic/license/LicenseGroup';
import License from '../components/basic/license/License';
import RoleGroup from '../components/basic/license/RoleGroup';
import Role from '../components/basic/license/Role';

import UserGroup from '../components/basic/user/UserGroup';
import User from '../components/basic/user/User';
import UserLogin from '../components/basic/user/UserLogin';


const basicRouters = [
    //basic/general
    {
        path: "/brand",
        element: <Brand />,
    },
    {
        path: "/company",
        element: <Company />,
    },
    {
        path: "/shop",
        element: <Shop />,
    },
    {
        path: "/deliveryAddress",
        element: <DeliveryAddress />,
    },

    // /basic/license
    {
        path: "/licenseGroup",
        element: <LicenseGroup />,
    },
    {
        path: "/license",
        element: <License />,
    },
    {
        path: "/roleGroup",
        element: <RoleGroup />,
    },
    {
        path: "/role",
        element: <Role />,
    },

    // /basic/user
    {
        path: "/userGroup",
        element: <UserGroup />,
    },
    {
        path: "/user",
        element: <User />,
    },
    {
        path: "/userLogin",
        element: <UserLogin />,
    },

]

export default basicRouters;