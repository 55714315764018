import { RouteObject } from "react-router-dom"
//sms
import PayOrder from '../components/payCenter/PayOrder';

const payRouters: RouteObject[] = [
    {
        path: "/payOrder",
        element: <PayOrder />,
    },
];


export default payRouters;