import { useEffect, useRef } from 'react';
import IndustryEdit from './IndustryEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const Industry = (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'industry';
    let handleChangeOne: any;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '行业名称',
            dataIndex: 'industryName',
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '行业',
        apiId,
        columns,
        showStates: true,
        EditComponent: IndustryEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Industry;
