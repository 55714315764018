import { useEffect } from 'react';
import WebSubscribeMessageTypeEdit from './WebSubscribeMessageTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 WebLogisticsWarehouse 
const WebLogisticsWarehouse = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '类型',
            dataIndex: 'id_Name',
        },
        {
            title: '模板id',
            dataIndex: 'templetId',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '订阅信息类型',
        apiId: 'webSubscribeMessageType',
        columns,
        showStates: true,
        EditComponent: WebSubscribeMessageTypeEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebLogisticsWarehouse;