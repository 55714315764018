import LicenseEdit from './LicenseEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { Button, Space, Tooltip } from 'antd';
import { columnsheadId } from '../../universal/columnshead';
import LicenseExtend from './LicenseExtend';
import { useState } from 'react';

//组件 License 
const License = (props: any) => {

    const [modalLicenseExtend, setModalLicenseExtend] = useState(false);
    const [licenseInfo, setLicenseInfo] = useState<any>();

    /** 打开extend */
    const openExtend = (record: any) => {
        setLicenseInfo({
            id: record.id,
            licenseName: record.licenseName
        })
        setModalLicenseExtend(true);
    };

    //子组件回调函数，关闭modal
    const onCloseModalExtend = (state: boolean) => {
        setModalLicenseExtend(false)
    };

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '权限',
            dataIndex: 'licenseName',
            sorter: true,
        },
        {
            title: '类型',
            dataIndex: 'licenseType_Name',
        },
        {
            title: '权限组',
            dataIndex: 'licenseGroup',
            sorter: true,
            render: (text: any) => (
                text ? text.licenseGroupName : ''
            )
        },
        {
            title: '页面路径',
            dataIndex: 'pageUrl',
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}>{
                typeof text !== "undefined" ?
                    (text.length >= 20 ? '......' + text.substring(-20, 20) : text)
                    : ''
            }</Tooltip>,
        },
        {
            title: '排序',
            dataIndex: 'licenseSort',
            sorter: true,
        },
        {
            title: '扩展',
            dataIndex: 'apiExtend',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} onClick={() => openExtend(record)}>扩展</Button>
                </Space>
        },
    ];
    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '权限',
        apiId: 'license',
        columns,
        pagination: true,
        showStates: true,
        EditComponent: LicenseEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
            {modalLicenseExtend ? (<LicenseExtend
                closeModal={onCloseModalExtend}
                info={licenseInfo}
                useType={'roleLicense'} />)
                : null}
        </>
    )
}

export default License;