import { useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import TinyMCEEdit, { TinMCEEditPropsType } from '../../system/TinyMCEEdit';

const WebProductDetails = (props: any) => {
    const { data } = props;
    const ref = useRef<any>();
    let extendProps: TinMCEEditPropsType = {
        ...props,
        apiId: 'webProduct',
    };

    return (
        <>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '产品详情 [' + data.productName + ']'}
                open={true}
                closable={false}
                maskClosable={false}
                width={470}
                footer={false}
                centered={true}>
                <TinyMCEEdit ref={ref} props={extendProps} />
            </AntDraggableModal>
        </>
    )
}

export default WebProductDetails;