import { Form, Space, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import WebEditUniversal from '../system/WebEditUniversalNewEx';
import antMessage from '../../utils/extend/AntdNotification'
import WebBehaviors, { behaviorsDataType } from '../system/behaviors/WebBehaviorsNew';
import systematic from '../../config/systematic';
import PhotoGallery from './PhotoGallery';
import FilesManage from './FilesManage';
import systemExpand from '../../config/systemExpand';

/** UploadFileListUniversal ExtendProps数据类型 */
export declare type UFLUExtendPropsType = {
    /** apiId */
    apiId: string,
    /** 标题名称 */
    titleName: string,
    /** 是否显示图片 */
    showImage?: boolean,
    /** 是否显示下载按钮 */
    showDownload?: boolean,
    /** 是否显示默认选项 */
    showDefault?: boolean,
    /** 是否显示状态选项 */
    showStates?: boolean,
    /** 组件：文件编辑组件 */
    EditComponent?: any,
    /** 附加数据 */
    sendPageData?: any,
    /** 表头 */
    filesColumns?: Array<any>,
    /** 文件组id */
    filesGroupId: string,
    /** 子目录 */
    subdirectory?: string,
    /** 第二子目录 */
    secondSubdirectory?: string,
    /** 数据 */
    data?: any,
    /** 方法：关闭窗口 */
    onClose?: Function,
};

/**
 * 上传文件通用组件
 * @param props 
 * @returns 
 */
const UploadFileListUniversal = (props: any) => {
    /** 数据刷新中 */
    let loading = false;
    const [form] = Form.useForm(); //form
    let extendProps: UFLUExtendPropsType;
    extendProps = props.props;
    const {
        apiId,
        titleName,
        showImage,
        showDownload,
        showDefault,
        showStates,
        EditComponent,
        sendPageData,
        filesColumns,
        filesGroupId,
        subdirectory,
        secondSubdirectory,
        data,
        onClose,
    } = extendProps
    const { disabledUpload } = data;
    const ref = useRef<any>();
    const [modalEditData, setModalEditData] = useState({});
    //row数据
    const [rows, setRows] = useState<any>();
    //当前页
    const [currentData, setCurrentData] = useState(1);
    //每页行数
    const [pageSizeData, setPageSizeData] = useState(20);
    //总行数
    const [totalData, setTotalData] = useState(0);
    //查询条件
    const [queryData, setQueryData] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    //打开文件库
    const [photoGalleryVisible,
        setPhotoGalleryVisible] = useState(false);
    const [filesGroupData, setFilesGroupData] = useState<any>();
    const [filesData, setFilesData] = useState<any>();
    const [defaultTotal, setDefaultTotal] = useState<any>();
    /** 后端数据defaultService */
    const defaultService = systematic.bmService;
    //上传文件
    const [upload, setUpload] = useState<any>();
    //文件服务manageUrl
    const [filesManageService, setFilesManageService] = useState<any>();
    //文件服务apiUrl
    // const [filesApiService, setFilesApiService] = useState<any>();
    //文件服务id
    const [serviceId, setServiceId] = useState<any>();

    useEffect(() => {
        //初始化
        const fetchData = async () => {
            const filesServer = await systemExpand.filesServer();
            const { apiServiceUrl, manageServiceUrl, serviceId } = filesServer as any;
            setFilesManageService(manageServiceUrl);
            // setFilesApiService(apiServiceUrl)
            setServiceId(serviceId);
            setUpload(await FilesManage.upload(
                apiServiceUrl,
                serviceId,
                filesGroupId,
                {
                    subdirectory,
                    secondSubdirectory,
                    successCallback,
                })
            );
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //改变数据
    const handleChangeOne = (id: number, columnName: string, value: boolean, urlName: string) => {
        //同步数据 async - await
        const fetchData = async () => {
            const bodys = {
                apiId: apiId + 'File',
                apiVariable: id,
                apiExtend: 'updateOne',
                apiData: {
                    columnName,
                    value
                }
            };
            if (await fetchApi.PUT(defaultService, bodys)) {
                antMessage('success', '编辑成功', 'updateOne ' + columnName + ' success');
                getFileList(currentData, pageSizeData, queryData);
            }
        }
        fetchData();
    };

    /** 上传图片参数 */
    const uploadData = {
        userId: 'system'
    };

    /** 关闭 */
    const handleClose = () => {
        if (typeof onClose !== "undefined") {
            onClose(true);
        }
    }

    /** 打开图库 */
    const openPhotoGallery = async () => {
        //由于没有选择默认图库的组，所以提前设定第一个组为默认
        fetchApi.GET(filesManageService,
            {
                apiId: 'filesGroup',
                apiExtend: 'showList'
            }, {
            serviceId
        }).then(res => {
            if (res.success) {
                res.data.map(async (item: any, index: any) => {
                    if (index === 0) {
                        //读取数据库，首先将分组先set好
                        fetchApi.GET(filesManageService,
                            {
                                apiId: 'filesGroup',
                                apiExtend: 'showList'
                            }, {
                            serviceId
                        }).then(res => {
                            if (res.success) {
                                setFilesGroupData(res.data);
                            }
                        })
                        //根据该分组读取数据库，将数据都set好
                        const res = await fetchApi.GET(filesManageService, {
                            apiId: 'files',
                            apiExtend: 'groupShowPage',
                            current: 1,
                            pageSize: 32,
                            /** 文件组id */
                            filesGroupId
                        }, {
                            serviceId
                        })
                        if (res.success) {
                            setFilesData(res.data.rows);
                            setDefaultTotal(res.data.total);
                        }
                        setPhotoGalleryVisible(true);
                    }
                })
            }
        })
    };

    /**
     * 关闭图库
     */
    const closePhotoGallery = (state: boolean) => {
        setPhotoGalleryVisible(false);
    };

    /**
     * 获取选择好的图片，并添加到数据库
     */
    const getCheckPhoto = async (filesData: any, editor: any) => {
        console.log('---------------');
        console.log('insertFiles：' + apiId + 'File');
        console.log(filesData);
        fetchApi.POST(defaultService,
            {
                apiId: apiId + 'File',
                apiExtend: 'insertFiles',
                apiData: {
                    filesData,
                    dataId: data.id
                }
            }).then(res => {
                if (res.success === true) {
                    //刷新表格内容
                    getFileList(currentData, pageSizeData, queryData);
                }
            })
    };

    /**
     * 删除图片
     * @param record 内容
     * @param isChecked 是否物理删除图片
     * @returns 
     */
    const handleDelete = async (record: any, isChecked: boolean) => {
        //删除文件数据
        const res = await fetchApi.DELETE(defaultService,
            {
                apiId: apiId + 'File',
                apiVariable: record.id
            })
        if (res.success) {
            try {
                const filesIds = [{ id: record.fileId }];
                let delMessage: any;
                //删除文件管理的数据(物理删除)
                if (isChecked === true) {
                    delMessage = await fetchApi.DELETE(filesManageService,
                        {
                            apiId: 'files',
                            apiExtend: 'deleteFiles',
                            apiData: {
                                filesIds
                            }
                        }, undefined, {
                        'serviceId': serviceId
                    })
                }
                antMessage('success', '文件删除成功', delMessage ? delMessage.data : 'success');
            }
            catch (err: any) {
                antMessage('error', '删除失败', err.message);
            }
        }
        else {
            antMessage('error', '图片删除失败', 'error');
        }
        //刷新
        getFileList(currentData, pageSizeData, queryData);
    };

    //更改文件数据
    let sendData = {
        type: '',
        id: '',
    }
    //打开[文件编辑]框
    const handleEdit = (e: any) => {
        sendData.type = 'EDIT';
        sendData.id = e.id;
        setModalEditData(sendData);
        setModalEditVisible(true);
    }

    //子组件回调函数，关闭modalEdit
    const onCloseModalEdit = (state: boolean) => {
        setModalEditVisible(false)
        if (state === true) {
            //刷新表格内容
            getFileList(currentData, pageSizeData, queryData);
        }
    };

    /**
     * 图片上传后回调的方法 
     * @param info 文件数据
     */
    const successCallback = async (info: any) => {
        console.log('上传文件数据：');
        console.log(info);

        //保存数据
        let insertId = apiId + "Id";
        await fetchApi.POST(defaultService,
            {
                apiId: apiId + 'File',
                apiData: {
                    [insertId]: data.id,
                    fileId: info.data.id,
                    fileDestination: info.data.destination + '/' + info.data.fileName,
                    fileType: info.data.fileType,
                    fileSize: info.data.fileSize,
                    imageWidth: info.data.imageWidth,
                    imageHeight: info.data.imageHeight,
                    isDefault: false,
                    isUse: true
                }
            })
        //刷新
        getFileList(currentData, pageSizeData, queryData);
    };

    /**
     * 获取文件列表数据（刷新）
     */
    const getFileList = async (
        current: number,
        pageSize: number,
        queryString: string) => {
        if (loading === true) { return }
        loading = true;
        try {
            const res = await fetchApi.GET(
                defaultService, {
                apiId: apiId + 'File',
                apiVariable: data.id,
                apiExtend: 'showPage',
                apiData: {
                    sendPageData,
                    current,
                    pageSize,
                    queryString
                }
            }); //req.query 
            if (res.success) {
                console.log(res.data);

                setRows(res.data.rows);
                setCurrentData(res.data.current);
                setPageSizeData(res.data.pageSize);
                setTotalData(res.data.total);
                setQueryData(res.data.queryString)
            }
            loading = false;
        }
        catch { }
    };

    //初始化
    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
            await getFileList(currentData, pageSizeData, queryData);
            setModalVisible(true);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    let uploadExtendProps: any = {
        ...props.props,
        form,
        apiId,
    };
    let behaviorsColumns = filesColumns!
        .concat([


        ]);

    let behaviorsData: behaviorsDataType = {
        apiId: apiId + 'File',
        showImage,
        showDownload,
        showDefault,
        showStates,
        handleDelete,
        handleEdit,
        handleChangeOne,
        behaviorsColumns,
        deleteButtonConfig: {
            useCheckBox: true,
            checkBoxContent: '同时物理删除图片',
            isChecked: false,
        }
    };

    let columns = WebBehaviors(behaviorsData);

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${totalData}条`,
        pageSize: pageSizeData,
        current: currentData,
        total: totalData,
        onShowSizeChange: (current: number, pageSize: number) =>
            getFileList(
                current,
                pageSize,
                queryData),
        onChange: (current: number) =>
            getFileList(
                current,
                pageSizeData,
                queryData),
    };

    return (
        <><WebEditUniversal ref={ref} props={uploadExtendProps} />
            <AntDraggableModal
                title={titleName}
                // visible={modalVisible}
                open={modalVisible}
                okText='退出'
                onOk={handleClose}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                maskClosable={false}
                width={1080}
            >
                <Space>
                    <Upload
                        {...upload
                        }
                        data={() => uploadData}
                        showUploadList={false}>
                        <Button disabled={disabledUpload} icon={<UploadOutlined onPointerOverCapture={undefined} onPointerMoveCapture={undefined} />}>文件上传</Button>
                    </Upload>
                    <Button type="primary" onClick={openPhotoGallery}>文件库选取</Button>
                </Space>
                <p />
                <Table
                    rowKey={row => row.id}
                    columns={columns}
                    dataSource={rows}
                    pagination={paginationProps}
                    size="small"
                />

            </AntDraggableModal>

            {modalEditVisible ? (<EditComponent closeModal={onCloseModalEdit} data={modalEditData} />) : null}
            {photoGalleryVisible
                ? (<PhotoGallery
                    closeModal={closePhotoGallery}
                    filesGroupData={filesGroupData}
                    defaultfilesData={filesData}
                    defaultMenu={filesGroupId}
                    defaultTotal={defaultTotal}
                    getCheckPhoto={getCheckPhoto}
                />)
                : null}
        </>
    )
}

export default UploadFileListUniversal;