import { Form, Input, Row, Col, Switch, DatePicker, Button, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversal';
import moment from 'moment';
import stringHandle from '../../../utils/baseLib/stringHandle';
import logistics from '../general/logistics';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebRefundOrderEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 刷新数据 */
    let initData: any;

    //当前数据
    const [row, setRow] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            /** 当前数据 */
            ref.current.row.then((res: any) => {
                if (res.success === true) {
                    setRow(res.data);
                }
            })
        }
        const fetchData = async () => {
            // fetchApi.GET(await httpUrl.general('generalData', 'transactionMode', 'ShowList')).then(res => {
            //     if (res.success) {
            //         setTransactionModeDat(res.data)
            //     }
            // });
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiName: 'webReturnOrder',
        dateColumnName: ['createdAt'],
        isExtractSysDate: true
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '退货订单'}
                open={true}
                 okButtonProps={{style:{display:"none"}}}
                cancelText="取消"
                onOk={stringHandle.isUndefined}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="退货日期"
                                name='createdAt'
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    disabled={false}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="物流订单"
                                name="logisticsOrderId">
                                <Tooltip placement="topLeft" title={'查看物流状态'}>
                                    <Button type='dashed'
                                        onClick={() => logistics.routeQuery(row.logisticsOrderId)}>
                                        {row ? row.logisticsOrderId : ''}
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input disabled={false} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="确认"
                                name="isConfirm"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch
                                    disabled={false}
                                    checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch
                                    disabled={false}
                                    checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebRefundOrderEdit;