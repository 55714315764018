import { useEffect } from 'react';
import WebTownEdit from './WebTownEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';
import { Tooltip } from 'antd';

const WebUniversalList = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '区.县',
            dataIndex: 'county',
            render: (text: any) => (
                <Tooltip placement="topLeft" title={text ?
                    text.city.province.countryOrArea.countryOrAreaName + ' - ' + text.city.province.provinceName + ' - ' + text.city.cityName
                    : ''}>
                    {text ? text.countyName : ''}
                </Tooltip>
            )
        },
        {
            title: '街道.镇.乡名称',
            dataIndex: 'townName',
        },
        {
            title: 'usName',
            dataIndex: 'usName',
        },
    ];

    const columns = getColumnsheadId({ title: '代码', dataIndex: 'code' })
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '街道.镇.乡',
        apiId: 'webTown',
        columns,
        showStates: true,
        primaryKeyName: 'code',
        EditComponent: WebTownEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebUniversalList;