import { Button, Space, Image, Switch, Tooltip, Checkbox, Popover } from 'antd';
import { useEffect, useState } from 'react';

/** 删除按钮对象类型 */
export interface deleteButtonConfigType {
    /** 是否使用复选框 */
    useCheckBox?: boolean,
    /** 复选框内容 */
    checkBoxContent?: String,
    /** 复选框是否被选中(默认不选中) */
    isChecked?: boolean,
    /** 删除按钮索引，默认'id' */
    index?: string,
};

/** behaviors数据类型 */
export interface behaviorsDataType {
    /** apiId */
    apiId: string,
    /** 主键名称 */
    primaryKeyName?: string,
    /** 是否显示图片 */
    showImage?: boolean,
    /** 是否显示文件上传按钮 */
    showUploadFile?: boolean,
    /** 是否显示下载按钮 */
    showDownload?: boolean,
    /** 是否显示默认选项 */
    showDefault?: boolean,
    /** 是否显示状态选项 */
    showStates?: boolean,
    /** 是否显示操作区域 */
    showOperation?: boolean,
    /** 是否显示删除按钮 */
    showDelete?: boolean,
    /** 是否显示编辑按钮 */
    showEdit?: boolean,
    /** 方法：文件上传 */
    hanldUpload?: Function,
    /** 方法：删除 */
    handleDelete?: Function,
    /** 方法：编辑 */
    handleEdit?: Function,
    /** 方法：改变选项 */
    handleChangeOne?: Function,
    /** 表头 */
    behaviorsColumns?: Array<any>,
    /** 图片数据数组名称 */
    arrayFilesName?: string,
    /** 删除按钮配置 */
    deleteButtonConfig?: deleteButtonConfigType,
    /** 单个数据日志显示 */
    loggerSingleDisplay?: boolean,
    /** 单个数据日志操作 */
    handleLoggerSingle?: Function
};

/**
 * behaviors 表头操作区域
 * @param behaviorsData 
 * @returns 
 */
const WebBehaviors = (behaviorsData: behaviorsDataType) => {
    let {
        apiId,
        showImage,
        showUploadFile,
        showDownload,
        showDefault,
        showStates,
        showOperation,
        showDelete,
        showEdit,
        hanldUpload,
        handleDelete,
        handleEdit,
        handleChangeOne,
        behaviorsColumns,
        arrayFilesName,
        deleteButtonConfig,
        loggerSingleDisplay,
        handleLoggerSingle,
    } = behaviorsData;

    //删除按纽显示的popover
    const [dBtnPopoverVisible, setDBtnPopoverVisible] = useState<any>({});
    //删除按纽的checkBox是否checked
    const [dBtnChecked, setDBtnChecked] = useState(false);
    //删除按纽索引
    const [dBtnIndex, setdBtnIndex] = useState('id');

    if (typeof showOperation === "undefined") { showOperation = true };
    if (typeof showDelete === "undefined") { showDelete = true };
    if (typeof showEdit === "undefined") { showEdit = true };

    /** 主键名称 */
    const primaryKeyName = behaviorsData.primaryKeyName ? behaviorsData.primaryKeyName : 'id';

    useEffect(() => {
        //如果primaryKeyName不为空，配置删除键
        if (typeof primaryKeyName !== 'undefined') {
            setdBtnIndex(primaryKeyName);
        }
        //删除键配置
        if (typeof deleteButtonConfig === 'undefined') {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            deleteButtonConfig = {
                isChecked: false
            }
        }
        else {
            if (typeof deleteButtonConfig.isChecked !== 'undefined') {
                setDBtnChecked(deleteButtonConfig.isChecked);
            }
            if (typeof deleteButtonConfig.index !== 'undefined') {
                setdBtnIndex(deleteButtonConfig.index);
            }
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    /**
     * 获取文件
     * @param record 
     * @returns 
     */
    const getFile = (record: any) => {
        // console.log('1111111111');
        /** 返回值 */
        let res = {
            /** 文件地址 */
            fileUrl: '',
            /** 是否图片 */
            isImage: false
        }
        if (typeof record === 'undefined') {
            return res;
        }
        /** 文件类型 */
        let fileType;
        //优先级高
        try {
            // console.log('22222222222222');
            // console.log(arrayFilesName);
            if (typeof arrayFilesName !== 'undefined') {
                if (typeof record[arrayFilesName!] !== 'undefined') {
                    fileType = record[arrayFilesName!][0].fileType;
                    res.fileUrl = record.filesServerUrl + "/" + record[arrayFilesName!][0].fileDestination;
                }
            }
            else {

                if (typeof record[apiId + "Files"] !== "undefined") {
                    // console.log('3333333');
                    // console.log(record[apiId + "Files"]);
                    fileType = record[apiId + "Files"][0].fileType;
                    res.fileUrl = record.filesServerUrl + "/" + record[apiId + "Files"][0].fileDestination;
                }
                else {
                    // console.log('444444444');
                    // console.log(record);

                    fileType = record.fileType;
                    res.fileUrl = record.filesServerUrl + '/' + record.fileDestination;
                }
            }
            // console.log('55555');
            // console.log(res.fileUrl);
            if (typeof fileType !== 'undefined') {
                if (fileType.slice(0, 5) === 'image') {
                    res.isImage = true
                }
            }
        }
        catch { }
        return res;
    };

    /** 表头 */
    let operationColumns = [
        (showImage ? {
            title: '图片',
            dataIndex: 'id',
            render: (text: any, record: any) => (
                (getFile(record)).isImage ?
                    < Image
                        src={(getFile(record)).fileUrl}
                        width={60}
                        alt="图片" /> :
                    <text>{record.fileType}</text>
            )
        } : {}),
        (showUploadFile ? {
            title: '文件',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary"
                        onClick={() =>
                            typeof hanldUpload !== 'undefined' ?
                                hanldUpload(record.id) :
                                undefined
                        }>文件</Button>
                </Space>
        } : {}),
        (showDownload ? {
            title: '下载',
            dataIndex: 'fileName',
            render: (text: any, record: any) =>
                <a href={(getFile(record)).fileUrl} >
                    <Tooltip title={'请用鼠标右键点击下载（另存）！'}>下载</Tooltip></a>
        } : {}),
        (showDefault ? {
            title: '默认显示',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record.id, 'isDefault', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        (showStates ? {
            title: '状态',
            dataIndex: 'isUse',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record[primaryKeyName]}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record[primaryKeyName], 'isUse', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        (showOperation ? {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    {showDelete ?
                        <>
                            <Popover content={(
                                <>  {deleteButtonConfig?.useCheckBox ?
                                    <Checkbox
                                        checked={dBtnChecked}
                                        onClick={() => { setDBtnChecked(!dBtnChecked) }}>
                                        {deleteButtonConfig?.checkBoxContent ? deleteButtonConfig.checkBoxContent : '是否删除'}
                                    </Checkbox>
                                    : ''}
                                    <p />
                                    <Space>
                                        <Button onClick={() => {
                                            setDBtnPopoverVisible({
                                                [record[dBtnIndex]]: false
                                            })
                                        }}>否</Button>
                                        <Button onClick={() => {
                                            if (typeof handleDelete !== 'undefined') {
                                                handleDelete(record, dBtnChecked);
                                                setDBtnPopoverVisible({ [record[dBtnIndex]]: false })
                                            }
                                        }}
                                        >是</Button>
                                    </Space>
                                </>
                            )}
                                title="是否删除？"
                                trigger="click"
                                open={dBtnPopoverVisible[record[dBtnIndex]] ? dBtnPopoverVisible[record[dBtnIndex]] : false}
                                onOpenChange={(e) => {
                                    setDBtnPopoverVisible({
                                        [record[dBtnIndex]]: e
                                    })
                                }}>
                                <Button size={"small"} type="text" danger >删除</Button>
                            </Popover>
                        </> : ''}
                    {showEdit ?
                        <Button size={"small"} type="text" onClick={() =>
                            typeof handleEdit !== "undefined" ?
                                handleEdit(record) :
                                undefined
                        }>编辑</Button> : ''}
                </Space>
        } : {}),
        (loggerSingleDisplay ? {
            title: '日志',
            dataIndex: 'logger',
            key: 'logger',
            render: (text: any, record: any) =>
                <Button size={"small"} type="text" onClick={() => {
                    if (handleLoggerSingle) {
                        handleLoggerSingle(record[primaryKeyName]);
                    }
                }
                }>日志</Button>
        } : {})
    ];
    /**
     * 合拼表头
     */
    let columns;
    if (typeof behaviorsColumns !== "undefined") {
        columns = behaviorsColumns.concat(operationColumns);
    }
    //去除operationColumns 的空对象
    operationColumns = operationColumns.filter(valeus => Object.keys(valeus).length !== 0);
    if (typeof behaviorsColumns !== "undefined") {
        columns = behaviorsColumns.concat(operationColumns);
    }
    return columns;
}
export default WebBehaviors;