import { useEffect, useRef, useState } from 'react';
import WebProductEdit from './WebProductEdit';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import WebProductUploadFile from './WebProductUploadFile';
import { columnsheadId } from '../../universal/columnshead';
import { Button, Space, Tooltip } from 'antd';
import WebProductPrice from './WebProductPrice';
import WebProductSpec from './WebProductSpec';
import WebProductDetails from './WebProductDetails';
import numberHandle from '../../../utils/baseLib/numberHandle';

//组件 WebProduct 
const WebProduct = (props: any) => {
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'webProduct';
    //规格窗口
    const [modalProductSpecVisible,
        setModalProductSpecVisible] = useState(false);
    //规格数据
    const [productSpecData, setProductSpecData] = useState<any>();
    //价格窗口
    const [modalProductPriceVisible,
        setModalProductPriceVisible] = useState(false);
    //价格数据
    const [productPriceData, setProductPriceData] = useState<any>();
    //产品详情窗口
    const [modalProductDetailsVisible,
        setModalProductDetailsVisible] = useState(false);
    //产品详情数据    
    const [productDetailsData, setProductDetailsData] = useState<any>();
    /** 刷新数据 */
    let initData: any;
    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 后端数据defaultService */
    // let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //同步获取数据 async - await
        // const fetchData = async () => {
        // }
        // fetchData();
        if (refresh !== 0) {
            const currentData: initDataType = {
                apiId
            }
            initData(currentData);
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]); //初始化数据


    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        productName: ''
    }
    //打开产品价格
    const hanldProductPrice = (e: any) => {
        sendData.id = e;
        setProductPriceData(sendData);
        setModalProductPriceVisible(true);
    };
    //关闭产品价格，子组件回调函数
    const onCloseModalPriceVisible = (state: boolean) => {
        setModalProductPriceVisible(false);
        setRefresh(numberHandle.rangeNumber(1, 9999));
    };
    //打开产品规格
    const hanldProductSpec = (e: any) => {
        sendData.id = e;
        setProductSpecData(sendData);
        setModalProductSpecVisible(true);
    };
    //关闭产品规格，子组件回调函数
    const onCloseModalSpecVisible = (state: boolean) => {
        setModalProductSpecVisible(false);
        setRefresh(numberHandle.rangeNumber(1, 9999));
    };
    //打开产品详情
    const hanldProductDetails = (record: any) => {
        sendData.id = record.id;
        sendData.productName = record.productName
        setProductDetailsData(sendData);
        setModalProductDetailsVisible(true);
    }
    //关闭产品详情，子组件回调函数
    const onCloseModalDetailsVisible = (state: boolean) => {
        setModalProductDetailsVisible(false);
    };


    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'productName',
            sorter: true,
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '月销量',
            dataIndex: 'monthlyQuantitySold',
            sorter: true,
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (
                text ? text.shopName : ''
            )

        },
        {
            title: '分类',
            dataIndex: 'productType',
            sorter: true,
            render: (text: any) => (
                text ? text.typeName : '')
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            key: 'productSpec',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => hanldProductSpec(record.id)}>规格</Button>
                </Space>
        },
        {
            title: '价格',
            dataIndex: 'productPrice',
            key: 'productPrice',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => hanldProductPrice(record.id)}>价格</Button>
                </Space>
        },
        {
            title: '详情',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => hanldProductDetails(record)}>详情</Button>
                </Space>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品',
        apiId,
        columns,
        showUploadFile: true,
        showImage: true,
        showStates: true,
        EditComponent: WebProductEdit,
        UploadFileComponent: WebProductUploadFile,
        arrayFilesName: 'productFiles'
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalProductPriceVisible
                ? (<WebProductPrice
                    closeModal={onCloseModalPriceVisible}
                    data={productPriceData} />)
                : null}
            {modalProductSpecVisible
                ? (<WebProductSpec
                    closeModal={onCloseModalSpecVisible}
                    data={productSpecData} />)
                : null}
            {modalProductDetailsVisible
                ? (<WebProductDetails
                    onClose={onCloseModalDetailsVisible}
                    data={productDetailsData} />)
                : null}
        </>
    )
}

export default WebProduct;