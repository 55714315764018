import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { columnsheadId } from '../../universal/columnshead';
import systematic from '../../../config/systematic';
import LicenseExtendEdit from './LicenseExtendEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'

const LicenseExtend = (props: any) => {
    const ref = useRef<any>();
    /** 使用类型 userLicense or roleLicense */
    const useType = props.useType;
    const { info, closeModal } = props;
    const { id, licenseName } = info

    /** 后端数据wmService */
    const wmService = systematic.bmService;
    let title = '[ ' + licenseName + ' ] 扩展权限';

    useEffect(() => {

        const fetchData = async () => {
            // fetchApi.GET(wmService,
            //     {
            //         apiId: 'generalData',
            //         apiVariable: 'licenseType',
            //         apiExtend: 'showList'
            //     }).then(res => {
            //         if (res.success) {
            //             setLicenseTypeData(res.data);
            //         }
            //     });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = (e: any) => {
        closeModal();
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '扩展id',
            dataIndex: 'exId'
        },
        {
            title: '扩展名称',
            dataIndex: 'exName'
        }
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: title,
        apiId: 'licenseExtend',
        columns,
        showStates: true,
        showQuery: false,
        sendPageData: {
            licenseId: id
        },
        EditComponent: LicenseExtendEdit,
        // extendedParameter:{ix:'dddd'}
    };

    return (
        <>
            <AntDraggableModal
                title={title}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={850} >
                <WebUniversal props={extendProps} ref={ref} />
            </AntDraggableModal>
        </>
    )
}

export default LicenseExtend;
