// import WebSalesIncomeEdit from './WebSalesIncomeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';

//组件 WebSalesIncome 
const WebSalesIncome = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? text.salesName : ''
            )
        },
        {
            title: '收入日期',
            dataIndex: 'sysDate',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {moment(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '佣金',
            dataIndex: 'commissionsAmount',
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '销售员收入表',
        apiId: 'webSalesIncome',
        columns,
        showAdd: false,
        showStates: true,
        showOperation: false
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebSalesIncome;
