import { Form, Input, Row, Col, Switch, Select, DatePicker } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from "../../universal/getList";
import moment from 'moment';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

/**
 * 佣金订单处理编辑
 * @param props 
 * @returns 
 */
const WebCommissionsOrderHandlingEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [commissionsModeData, setCommissionsModeData] = useState<any>();
    const [commissionsOrderStatusData, setCommissionsOrderStatusData] = useState<any>();
    const [salesName, setSalesName] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
                if (typeof res !== 'undefined') {
                    setSalesName(res.data.sales.salesName);
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'commissionsMode',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setCommissionsModeData(res.data)
                    }
                });

            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'commissionsOrderStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setCommissionsOrderStatusData(res.data)
                    }
                });
        };

        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webCommissionsOrderHandling',
        dateColumnName: ['receiveDate', 'checkDate', 'settlementDate'],
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '佣金订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="销售"
                                name="salesName">
                                <text>{salesName}</text>
                                {/* <Input disabled={true} /> */}
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="产品名称"
                                name="productName">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="规格"
                                name="productPriceSpec">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="总成交价格"
                                name="totalPurchasePrice">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="成交数量"
                                name="amount">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="佣金模式"
                                name="commissionsModeId">
                                <Select
                                    showSearch
                                    placeholder="佣金模式"
                                    optionFilterProp="children"
                                    disabled={true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(commissionsModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="佣金金额"
                                name="commissionsAmount">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="收货时间"
                                name="receiveDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="佣金订单状态"
                                name="commissionsOrderStatusId">
                                <Select
                                    showSearch
                                    placeholder="佣金订单状态"
                                    disabled={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(commissionsOrderStatusData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="核实"
                                name="isCheck"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    disabled={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="核实时间"
                                name="checkDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="核实id"
                                name="checkId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="结算时间"
                                name="settlementDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    disabled={true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebCommissionsOrderHandlingEdit;
