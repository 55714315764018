import WebProductSpecValueEdit from './WebProductSpecValueEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

const webProductSpecValue = (props: any) => {
    const { data, closeModal } = props;

    //表头
    const columnsHead = [
        {
            title: '规格类别',
            dataIndex: 'productSpec',
            render: (text: any) => (
                text ? text.productSpecType.specTypeName : ''
            )
        },
        {
            title: '规格内容',
            dataIndex: 'specValue',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(true);
        // ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        closeModal(false);
        // ref.current.handleCancel();
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品规格内容',
        apiId: 'webProductSpecValue',
        columns,
        showStates: true,
        EditComponent: WebProductSpecValueEdit,
        sendPageData: { productSpecId: data.id },
    };

    return (
        <>
            <AntDraggableModal
                title={'产品规格内容'}
                open={true}
                okText='退出'
                cancelText='取消'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversal props={extendProps}></WebUniversal>
            </AntDraggableModal>
        </>
    )
}

export default webProductSpecValue;
