import WebCarouselAdFileEdit from './WebCarouselAdFileEdit';
import UploadFileListUniversal, { UFLUExtendPropsType } from '../../filesSystem/UploadFileListUniversal'; 
import { columnsheadId } from '../../universal/columnshead';
import { useEffect, useState } from 'react';
import systemExpand from '../../../config/systemExpand';

/** 组件 */
const WebCarouselAdUploadFile = (props: any) => {
    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();
    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { webCarouselAdFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '广告图片',
                apiId: 'webCarouselAd',
                filesGroupId: webCarouselAdFilesGroupId,
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                EditComponent: WebCarouselAdFileEdit,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //表头
    const columnsHead = [
        {
            title: '广告',
            dataIndex: 'carouselAd',
            render: (text: any) => (text.adName)
        },
        {
            title: '产品',
            dataIndex: 'product',
            render: (text: any) =>
                (text ? text.productName : '')
        },
    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    if (typeof extendProps !== 'undefined') {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default WebCarouselAdUploadFile;