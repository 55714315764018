import { Form, Input, Row, Col, Select, Button, Space, Divider, Tooltip, DatePicker, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import systematic from '../../../config/systematic';
import getList from "../../universal/getList";
import dayjs from 'dayjs';
import AddressEdit from '../../system/AddressEdit';
import antMessage from '../../../utils/extend/AntdNotification';
import '../../css/formItem.css';

const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebConsign = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { Option } = Select;
    const { data, setIsChecked, status } = props;
    /** 关闭窗口方法，参数true or false */
    const onClose = props.onClose;
    /** 物流订单id */
    const logisticsOrderId = data.logisticsOrderId;
    const [queryString, setQueryString] = useState('');
    //新增或编辑状态
    const [editType, setEditType] = useState(false);
    //是否使用旧物流单
    const [importModel, setImportModel] = useState(false);
    const [logisticsTypeData, setLogisticsTypeData] = useState<any>();
    const [deliveryModeData, setDeliveryModeData] = useState<any>();
    const [logisticsCompanyData, setLogisticsCompanyData] = useState<any>();
    const [productOrderDetailData, setProductOrderDetailData] = useState<any>();
    const [defaultSelectProductOrderDetail, setDefaultSelectProductOrderDetail] = useState<any>();
    //物流仓库列表（数组）
    const [logisticsWarehouseData, setLogisticsWarehouseData] = useState<any>();
    //自动下单状态
    const [autoOrderData, setAutoOrderData] = useState(false);
    const [row, setRow] = useState<any>();
    //货品详情
    const [goodsDetailsValue, setGoodsDetailsValue] = useState<any>();
    /** 后端数据defaultService */
    const defaultService = systematic.bmService;

    useEffect(() => {
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'logisticsType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setLogisticsTypeData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'deliveryMode',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDeliveryModeData(res.data);
                    }
                });
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'logisticsCompany',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setLogisticsCompanyData(res.data);
                    }
                });

            /** 查找仓库列表参数：产品价格列表 */
            const productPriceIds: any[] = [];
            if (data.type === 'Add') {
                const res = await fetchApi.GET(defaultService,
                    {
                        apiId: 'webProductOrder',
                        apiVariable: data.productOrderId,
                        apiData: {
                            sendPageData: {
                                productStatusId: 0
                            }
                        }
                    })
                if (res.success) {
                    const children = [];
                    const defaulChildren = [];

                    //合并的货品详情
                    let goodsDetailsMerge = '';
                    for (let i = 0, len = res.data.productOrderDetails.length; i < len; i++) {
                        const { id, productName, productPriceSpec, amount, productUnit, productPriceId } = res.data.productOrderDetails[i];
                        children.push(<Option key={id} value={id}>
                            {productName + '-' + productPriceSpec + '-' + amount + productUnit}
                        </Option>);
                        defaulChildren.push(id);
                        productPriceIds.push(productPriceId);
                        if (goodsDetailsMerge === '') {
                            goodsDetailsMerge = productName + '-' + productPriceSpec + '-' + amount + productUnit;
                        }
                        else {
                            goodsDetailsMerge += '，' + productName + '-' + productPriceSpec + '-' + amount + productUnit;
                        }
                        setGoodsDetailsValue(goodsDetailsMerge);
                    }
                    setProductOrderDetailData(children);
                    setDefaultSelectProductOrderDetail(defaulChildren);
                }
                resetNew();
            }
            if (data.type === 'Edit') {
                setEditType(true);
                setImportModel(false);
                //获取数据
                const res = await fetchApi.GET(defaultService,
                    {
                        apiId: 'webLogisticsOrder',
                        apiVariable: logisticsOrderId
                    });
                if (res.success) {
                    setRow(res.data);
                    //日期转换moment格式
                    res.data['consignDate'] = dayjs(res.data['consignDate'])
                    form.setFieldsValue({
                        ...res.data,
                    })
                    for (let i = 0, len = res.data.productOrderDetails.length; i < len; i++) {
                        const { productPriceId } = res.data.productOrderDetails[i];
                        productPriceIds.push(productPriceId);
                    }
                }
            }
            if (productPriceIds.length > 0) {
                //获取产品价格的仓库列表
                const resWarehouseRows = await fetchApi.GET(defaultService,
                    {
                        apiId: 'webProductPrice',
                        apiExtend: 'getLogisticsWarehouse',
                        apiData: {
                            productPriceIds
                        }
                    })
                /** 获取物流仓库数组 */
                if (resWarehouseRows.success === true) {
                    setLogisticsWarehouseData(resWarehouseRows.data)
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 确认
     * @param e 
     */
    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                const editData = {
                    ...values,
                }
                if (editType === false) {
                    if (importModel === true) {
                        //导入物流订单
                        fetchApi.PUT(defaultService,
                            {
                                apiId: 'webConsignOrder',
                                apiExtend: 'logisticsOrder',
                                apiData: editData
                            }).then(res => {
                                antMessage('success', '导入物流订单成功', 'success');
                                if (setIsChecked) {
                                    if (status) {
                                        setIsChecked(true)
                                    } else {
                                        setIsChecked(false)
                                    }
                                }
                                onClose(true);
                            }).catch(err => {
                                antMessage('error', '错误，无法导入', err.message);
                            })
                    }
                    else {
                        //保存(新增)物流订单
                        fetchApi.POST(defaultService,
                            {
                                apiId: 'webConsignOrder',
                                apiExtend: 'createLogisticsOrder',
                                apiData: editData
                            }).then(res => {
                                antMessage('success', '新增物流订单成功', 'success');
                                if (setIsChecked) {
                                    if (status) {
                                        setIsChecked(true)
                                    } else {
                                        setIsChecked(false)
                                    }
                                }
                                onClose(true);
                            }).catch(err => {
                                antMessage('error', '错误，无法保存', err.message);
                            })
                    }
                }
                else {
                    //编辑模式
                    //更新物流订单
                    fetchApi.PUT(defaultService,
                        {
                            apiId: 'webLogisticsOrder',
                            apiVariable: logisticsOrderId,
                            apiExtend: 'updateLogisticsOrder',
                            apiData: editData
                        }).then(res => {
                            antMessage('success', '更新物流订单成功', 'success');
                            onClose(true);
                        }).catch(err => {
                            antMessage('error', '错误，无法更新物流订单', err.message + ' code:' + err.errors.code);
                        })
                }
            })
    };

    /**
     * cancel 关闭窗口
     */
    const handleCancel = () => {
        if (setIsChecked) {
            if (status) {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }
        }
        onClose(false)
    };

    /** 是否自动下单 */
    const handleAutoOrder = (status: boolean) => {
        setAutoOrderData(status);
    };

    /** 重置为新数据 */
    const resetNew = async () => {
        form.resetFields()
        fetchApi.GET(defaultService,
            {
                apiId: 'webProductOrderDetail',
                apiVariable: data.productOrderDetailId
            }).then(res => {
                if (res.success) {
                    const { receiver,
                        receivePhone,
                        countryOrAreaCode,
                        province,
                        city,
                        county,
                        town,
                        detail,
                        postalCode } =
                        res.data.productOrder.productOrderAddress;
                    form.setFieldsValue({
                        receiver,
                        receivePhone,
                        receiveCountryOrAreaCode: countryOrAreaCode,
                        receiveProvince: province,
                        receiveCity: city,
                        receiveCounty: county,
                        receiveTown: town,
                        receiveDetail: detail,
                        postalCode
                    });
                }
            });
    };

    /**
     * 改变模式
     * @param model 
     */
    const onChangeModel = (model: boolean) => {
        if (model === false) {
            resetNew();
        }
        setImportModel(model);
    };

    /**
     * 查询物流订单
     */
    const onQueryLogisticsOrder = async () => {
        fetchApi.GET(defaultService,
            {
                apiId: 'webLogisticsOrder',
                apiExtend: 'query',
                apiData: {
                    id: queryString,
                    logisticsOrderNo: queryString
                }
            }).then(res => {
                if (res.success === true) {
                    const { id,
                        logisticsCompanyId,
                        logisticsOrderNo,
                        consignor,
                        consignPhone,
                        consignCountryOrAreaCode,
                        consignRegion,
                        consignDetail,
                        receiver,
                        receivePhone,
                        receiveCountryOrAreaCode,
                        receiveRegion,
                        receiveDetail,
                        postalCode,
                        consignDate,
                        goodsDetails,
                        memo } = res.data;
                    form.setFieldsValue({
                        id,
                        logisticsCompanyId,
                        logisticsOrderNo,
                        consignor,
                        consignCountryOrAreaCode,
                        consignPhone,
                        consignRegion,
                        consignDetail,
                        receiver,
                        receivePhone,
                        receiveCountryOrAreaCode,
                        receiveRegion,
                        receiveDetail,
                        postalCode,
                        consignDate: dayjs(consignDate),
                        goodsDetails,
                        memo
                    });
                    setImportModel(true);
                    antMessage('success', '查询成功', '物流订单id:' + id);
                }
            }).catch(error => {
                antMessage('error', '错误，无法查询到相关物流订单', error.message);
            })
    };

    /**
     * 选择物流仓库，返回发货人资料
     * @param id
     */
    const onSelectLogisticsWarehouse = async (id: string) => {
        fetchApi.GET(defaultService,
            {
                apiId: 'webLogisticsWarehouse',
                apiVariable: id
            }).then(res => {
                if (res.success) {
                    const { contacts,
                        contactPhone,
                        countryOrAreaCode,
                        provinceName,
                        cityName,
                        countyName,
                        townName,
                        detail } = res.data;
                    form.setFieldsValue({
                        consignor: contacts,
                        consignPhone: contactPhone,
                        consignCountryOrAreaCode: countryOrAreaCode,
                        consignProvince: provinceName,
                        consignCity: cityName,
                        consignCounty: countyName,
                        consignTown: townName,
                        consignDetail: detail
                    });
                }
            })
    };

    /**
     * 设置货品详情
     * @param rows 数据
     */
    const setGoodsDetails = (rows: any) => {
        /** 货品详情 */
        let goodsDetails = '';
        for (let i = 0, lenI = rows.length; i < lenI; i++) {
            if (goodsDetails === '') {
                goodsDetails = rows[i].children;
            }
            else {
                goodsDetails += '，' + rows[i].children;
            }
        }
        form.setFieldsValue({
            goodsDetails
        })
    };

    return (
        <> <AntDraggableModal
            title={editType ? '编辑物流订单: ' + logisticsOrderId : '新增物流订单'}
            open={true}
            okText='确定'
            cancelText='取消'
            onOk={handleOk}
            onCancel={handleCancel}
            closable={false}
            maskClosable={false}
            centered={true}
            width={1080}>
            <Row gutter={[8, 0]} style={{ marginBottom: '-3vh' }}>
                <Tooltip placement="topLeft"
                    title={'请输入物流订单id或物流公司单号'}>
                    <Col span={12}>
                        <Form.Item
                            label=""
                            name="id">
                            <Input type="text"
                                disabled={editType}
                                onChange={(e) => setQueryString(e.target.value)} />
                        </Form.Item>
                    </Col>
                </Tooltip>
                <Col span={12}>
                    <Space>
                        <Button disabled={editType}
                            onClick={(e) => onQueryLogisticsOrder()}>
                            查询并使用物流订单
                        </Button>
                        <Button disabled={editType}
                            type="dashed" onClick={() => onChangeModel(false)}
                        >新物流订单
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Divider dashed={true}>物流订单详情</Divider>
            <Form
                {...layout}
                form={form}
                name="Edit"
                onFinish={handleOk}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        handleOk(e)
                    }
                }}>
                <Row>
                    <Col span={24}>
                        <Tooltip placement="topLeft"
                            title={'请选择订单详情发货'}>
                            <Form.Item
                                label="订单详情:"
                                name="productOrderDetailIds"
                                initialValue={defaultSelectProductOrderDetail}
                                rules={[{ required: !editType, message: '请选择订单详情发货!' }]}>
                                <Select
                                    mode="multiple"
                                    placeholder="请选择"
                                    style={{ width: '100%' }}
                                    disabled={editType}
                                    onChange={(e, rows) => setGoodsDetails(rows)}>
                                    {productOrderDetailData}
                                </Select>
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={7}>
                        <Tooltip placement="topLeft"
                            title={logisticsOrderId}>
                            <Form.Item
                                label="物流订单id:"
                                name="id"
                                rules={[{ required: importModel, message: 'id!' }]}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={7}>
                        <Form.Item
                            label="物流模式"
                            name="logisticsTypeId"
                            rules={[{ required: true, message: '物流模式!' }]}>
                            <Select
                                showSearch
                                placeholder="物流模式"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.generalEx(logisticsTypeData)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item
                            label="发货模式"
                            name="deliveryModeId"
                            rules={[{ required: true, message: '发货模式!' }]}>
                            <Select
                                showSearch
                                placeholder="发货模式"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.generalEx(deliveryModeData)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={3}>
                        <Tooltip placement="topLeft"
                            title={'分拆发货模式时必填，用于标识分拆发货模式下是否已全部发货完成，只有全部发货完成的情况下才会向用户推送发货完成通知'}>
                            <Form.Item
                                label="发货完成:"
                                name="isAllDelivered" >
                                <Checkbox
                                    onChange={(e) => {
                                        form.setFieldValue('isAllDelivered', e.target.checked);
                                    }} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={8}>
                        <Tooltip placement="topLeft"
                            title={data.productOrderId}>
                            <Form.Item
                                label="产品订单id:"
                                name="productOrderId"
                                initialValue={data.productOrderId}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={8}>
                        <Tooltip placement="topLeft"
                            title={data.transactionNo}>
                            <Form.Item
                                label="交易号:"
                                name="transactionNo"
                                initialValue={data.transactionNo}
                                rules={[{ required: true, message: '原支付交易对应的微信订单号!' }]}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="物流公司"
                            name="logisticsCompanyId"
                            rules={[{ required: true, message: '物流公司!' }]}>
                            <Select
                                showSearch
                                placeholder="物流公司"
                                optionFilterProp="children"
                                disabled={importModel}
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.generalEx(logisticsCompanyData)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Tooltip placement="topLeft"
                            title={'物流(快递)公司的运单号'}>
                            <Form.Item
                                label="运单号:"
                                name="logisticsOrderNo"
                                rules={[{ required: !autoOrderData, message: '物流(快递)公司的运单号!' }]}>
                                <Input disabled={autoOrderData} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={3}>
                        <Tooltip placement="topLeft"
                            title={'当选择自动下单，则自动到相关物流(快递)公司下单，并生成物流(快递)公司运单号'}>
                            <Form.Item
                                label="自动下单:"
                                name="autoOrder">
                                <Checkbox
                                    checked={autoOrderData}
                                    onChange={(e) => {
                                        handleAutoOrder(!autoOrderData);
                                        form.setFieldValue('autoOrder', e.target.checked);
                                    }}
                                    disabled={importModel} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={9}>
                        <Form.Item
                            label="发货仓库"
                            name="logisticsWarehouseId">
                            <Select
                                showSearch
                                placeholder="发货仓库"
                                optionFilterProp="children"
                                disabled={editType}
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                onChange={(e: string) => onSelectLogisticsWarehouse(e)}>
                                {getList.general(logisticsWarehouseData, 'LWHName')}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="发货人:"
                            name="consignor"
                            rules={[{ required: true, message: '发货人!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="发货电话:"
                            name="consignPhone"
                            rules={[{ required: true, message: '发货电话!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <AddressEdit isRequired={true} row={row} form={form} visibleList={{
                        countryOrAreaVisible: true,
                        countryOrAreaCodeName: 'consignCountryOrAreaCode',
                        countryOrAreaSpan: 6
                    }} />

                    <Col span={6}>
                        <Form.Item
                            label="省:"
                            name="consignProvince"
                            rules={[{ required: true, message: '省!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label="城市:"
                            name="consignCity"
                            rules={[{ required: true, message: '城市!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label="区.县:"
                            name="consignCounty"
                            rules={[{ required: true, message: '区.县!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            label="街镇:"
                            name="consignTown">
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item
                            label="发货地址:"
                            name="consignDetail"
                            rules={[{ required: true, message: '发货地址!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="收货人:"
                            name="receiver"
                            rules={[{ required: true, message: '收货人!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="收货电话:"
                            name="receivePhone"
                            rules={[{ required: true, message: '收货电话!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <AddressEdit isRequired={true} row={row} form={form} visibleList={{
                        countryOrAreaVisible: true,
                        countryOrAreaCodeName: 'receiveCountryOrAreaCode',
                        countryOrAreaSpan: 6
                    }} />

                    <Col span={6}>
                        <Form.Item
                            label="省:"
                            name="receiveProvince"
                            rules={[{ required: true, message: '省!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label="城市:"
                            name="receiveCity"
                            rules={[{ required: true, message: '城市!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label="区.县:"
                            name="receiveCounty"
                            rules={[{ required: true, message: '区.县!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            label="街镇:"
                            name="receiveTown">
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item
                            label="收货地址:"
                            name="receiveDetail"
                            rules={[{ required: true, message: '收货地址!' }]}>
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="发货日期"
                            name="consignDate"
                            initialValue={dayjs(new Date())}
                            rules={[{ required: true, message: '发货日期!' }]}>
                            <DatePicker
                                disabled={importModel}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            label="邮编:"
                            name="postalCode">
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Tooltip placement="topLeft"
                            title={data.wxOpenId}>
                            <Form.Item
                                label="微信openid:"
                                name="wxOpenId"
                                initialValue={data.wxOpenId}
                                rules={[{ required: true, message: '微信openid!' }]}>
                                <Input disabled={true} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="备注:"
                            name="memo">
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={22}>
                        <Form.Item
                            label="货品详情:"
                            name="goodsDetails"
                            initialValue={goodsDetailsValue} >
                            <Input disabled={importModel} />
                        </Form.Item>
                    </Col>

                    <Col span={2}>
                        <Tooltip placement="topLeft"
                            title={'不进行微信小程序发货检测'}>
                            <Form.Item
                                label="不检测:"
                                name="isNotWxMiniAppChecked">
                                <Checkbox
                                    onChange={(e) => {
                                        form.setFieldValue('isNotWxMiniAppChecked', e.target.checked);
                                    }} />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                </Row>
            </Form>
        </AntDraggableModal>
        </>
    )
}

export default WebConsign;