import { Switch } from 'antd';
import React, { useEffect } from 'react';
// import WebProductShoppingCartEdit from './WebProductShoppingCartEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 WebProductShoppingCart 
const WebProductShoppingCart = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    //表头
    const columnsHead = [
        {
            title: '微信',
            dataIndex: 'wxUser',
            render: (text: any) => (
                text
                    ? text.nickName +
                    '(' + text.mobile + ')'
                    : ''
            )
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any) => (text.shopName)
        },
        {
            title: '产品价格',
            dataIndex: 'productPrice',
            render: (text: any) => (text.price)
        },
        {
            title: '加入时价格',
            dataIndex: 'priceAtJoin',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
        {
            title: '选择',
            dataIndex: 'isSelect',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text}
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品购物车',
        apiId: 'webProductShoppingCart',
        columns,
        showStates: true,
        showAdd: false,
        showEdit: false
        // EditComponent: WebProductShoppingCartEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebProductShoppingCart;