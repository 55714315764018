import { forwardRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import fetchApi from '../../utils/api/fetchApi';
import antMessage from '../../utils/extend/AntdNotification'
import { Button, Modal } from 'antd';
import moment from 'moment';
import systematic from "../../config/systematic";
import PhotoGallery from '../filesSystem/PhotoGallery';
import systemExpand from '../../config/systemExpand';

export declare type TinMCEEditPropsType = {
    /** api服务id */
    apiId: string,
    /** 数据 */
    data?: any,
    /** 方法：关闭窗口 */
    onClose?: Function,
};

/**
 * TinMCEEdit TinMCE富文本编辑器
 * @param props 
 * @param ref 
 * @returns 
 */
const TinMCEEdit = (props: any, ref: any) => {
    /** 后端数据defaultService */
    const defaultService = systematic.bmService;
    let extendProps: TinMCEEditPropsType;
    extendProps = props.props;
    const {
        apiId,
        data,
        onClose,
    } = extendProps;
    const { id } = data;
    /**
     * 本地储存id
     */
    const localStorageId = 'TinyMCE_id' + id;
    //富文本数据    
    const [richTextData, setRichTextData] = useState<any>();
    //加载缓存窗口
    const [modalVisible, setModalVisible] = useState(false);
    //退出时检查窗口
    const [modalExitVisible, setModalExitVisible] = useState(false);
    //临时数据
    const [tempData, setTempData] = useState<any>();
    //首次load
    const [fristLoad, setFristLoad] = useState(true);

    const [photoGalleryVisible,
        setPhotoGalleryVisible] = useState(false);
    const [filesGroupData, setFilesGroupData] = useState<any>([]);
    const [defaultMenu, setDefaultMenu] = useState<any>();
    const [editorData, setEditorData] = useState<any>();

    //文件服务manageUrl
    // const [filesManageService, setFilesManageService] = useState<any>();
    //文件服务id
    // const [serviceId, setServiceId] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            getData();
            let obj = getLocalStorage();
            setTempData(obj);
            if (typeof obj !== "undefined") {
                setModalVisible(true);
            }
            console.log('初始化');
            // const filesServer = await systemExpand.filesServer();
            // const { apiServiceUrl, manageServiceUrl, serviceId } = filesServer as any;
            // setFilesManageService(manageServiceUrl);
            // setServiceId(serviceId);
            // console.log(filesServer);
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * 上传图片参数
     */
    // const uploadData = {
    //     userId: 'system'
    // };
    /**
     * 文件上传
     */
    // let upload = {
    //     name: 'file',
    //     action: uploadActionData,
    //     headers: {
    //         filesgroupid: filesGroupId,
    //         subdirectory: id
    //     },
    // }

    /**
     * 获取网络数据
     */
    const getData = async () => {
        const res = await fetchApi.GET(defaultService,
            {
                apiId,
                apiVariable: id,
                apiExtend: 'showRichText'
            });
        if (res.success) {
            setRichTextData(res.data);
            if (typeof res.data === 'undefined' || res.data === null || res.data === '') {
                setFristLoad(false);
            }
        }
        else {
            setFristLoad(false);
        }
        return res;
    };

    /**
     * 富文本组件数据改变
     * @param content 
     * @param editor 
     */
    const handleEditorChange = (content: any, editor: any) => {
        if (fristLoad === false) {
            saveLocalStorage(content);
        } else {
            setFristLoad(false);
        }
    }

    /**
     * 保存临时文件
     * @param data 
     */
    const saveLocalStorage = (data: any) => {
        let obj = {
            data,
            updateAt: new Date()
        }
        localStorage.setItem(localStorageId, JSON.stringify(obj));
    };

    /**
     * 获取临时文件
     */
    const getLocalStorage = () => {
        let objItem = localStorage.getItem(localStorageId);
        let obj;
        if (typeof objItem !== "undefined" && objItem !== null) {
            obj = JSON.parse(objItem);
        }
        return obj;
    };

    /**
     * 保存富文件
     */
    const saveFile = async () => {
        let obj = getLocalStorage();
        let tinyMceData = '';
        let returnString = true;
        if (typeof obj !== "undefined") {
            tinyMceData = obj.data;
            const res = await fetchApi.PUT(defaultService,
                {
                    apiId,
                    apiVariable: id,
                    apiExtend: 'updateRichText',
                    apiData: {
                        details: tinyMceData
                    }
                })
            if (res.success) {
                antMessage('success', '保存成功', 'success');
                //指定删除临时文件
                localStorage.removeItem(localStorageId);
            }
            else {
                antMessage('error', '错误，无法保存', res.message);
                returnString = false;
            }
        }
        else {
            antMessage('info', '无数据更改!', '');
        }
        return returnString;
    };

    /**
     * 打开图库
     */
    const openPhotoGallery = async (editor: any) => {
        const filesServer = await systemExpand.filesServer();
        const { manageServiceUrl, serviceId } = filesServer as any;
        //由于没有选择默认图库的组，所以提前设定第一个组为默认
        fetchApi.GET(manageServiceUrl,
            {
                apiId: 'filesGroup',
                apiExtend: 'showList'
            }, {
            serviceId
        }).then(res => {
            if (res.success) {
                res.data.map(async (item: any, index: any) => {
                    if (index === 0) {
                        //读取数据库，首先将分组先set好
                        fetchApi.GET(manageServiceUrl,
                            {
                                apiId: 'filesGroup',
                                apiExtend: 'showList'
                            }, {
                            serviceId
                        }).then(res => {
                            if (res.success) {
                                setFilesGroupData(res.data);
                            }
                        })
                        //将默认分组也先set好
                        setDefaultMenu(item.id);
                        //将editor也set好，将值传到下一个组件使用，方便将回传回来的图片插入到编辑器中
                        setEditorData(editor);
                        setPhotoGalleryVisible(true);
                    }
                })
            }
        })
    };

    /**
    * 获取选择好的图片，并插入到编辑框
    */
    const getCheckPhoto = (data: any, editor: any) => {
        //console.log(data);
        //editor.execCommand('mceInsertContent', false, '<img src="123.jpg" width="100" height="100" />');
        data.map(async (item: any, index: any) => {
            editor.execCommand('mceInsertContent', false, '<img src="' + item.src + '" width="100%" />');
        })
    }

    /**
     * 关闭图库
     */
    const closePhotoGallery = (state: boolean) => {
        setPhotoGalleryVisible(false);
    };

    /**
     * 加载缓存窗口确定按钮
     */
    const handleOk = () => {
        setRichTextData(tempData.data);
        setModalVisible(false);
    };

    /**
     * 加载缓存窗口取消按钮
     */
    const handleCancel = () => {
        getData();
        localStorage.removeItem(localStorageId);
        setModalVisible(false);
    };

    /**
     * 退出检查窗口[是]按钮
     */
    const handleExitOk = async () => {
        if (await saveFile() === true) {
            onClose!();
        }
    };

    /**
     * 退出检查窗口[取消]按钮
     */
    const handleExitCancel = () => {
        setModalExitVisible(false);
    };

    /**
     * 退出检查窗口[否]按钮
     */
    const handleExitNo = () => {
        localStorage.removeItem(localStorageId);
        onClose!();
    };

    /**
     * 富文本配置参数
     */
    const editorObj = {
        // selector: 'textarea',
        language: 'zh_CN',
        min_height: 700,
        max_height: 700,
        skin: 'oxide-dark',
        content_css: 'default',
        //菜单置顶
        toolbar_sticky: true,
        //去除上传图片的默认宽高属性
        // image_dimensions: false,
        //自定义样式
        // content_style: "img { max-width: 100%; width: auto; height: auto;}",
        //插件
        plugins: 'preview searchreplace autolink directionality visualblocks visualchars ' +
            'fullscreen image link template code codesample table charmap hr pagebreak ' +
            'nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern ' +
            'help emoticons autosave autoresize formatpainter',

        // toolbar: 'code undo redo restoredraft | ' +
        //     'cut copy paste pastetext | ' +
        //     'formatselect fontsizeselect forecolor backcolor bold italic underline strikethrough link anchor | ' +
        //     'alignleft aligncenter alignright alignjustify outdent indent | ' +
        //     'styleselect formatselect fontselect fontsizeselect | ' +
        //     'bullist numlist | ' +
        //     'blockquote subscript superscript removeformat | ' +
        //     'table image media charmap emoticons hr pagebreak insertdatetime print preview | ' +
        //     'fullscreen | ' +
        //     'bdmap indent2em lineheight formatpainter axupimgs',
        // toolbar: `formatselect | code | preview | bold italic strikethrough forecolor backcolor | 
        // link image | alignleft aligncenter alignright alignjustify  | 
        // numlist bullist outdent indent`,

        //配置
        setup: function (editor: any) {
            //设置自定义菜单子项
            //文件-保存子菜单
            editor.ui.registry.addMenuItem('saveFile', {
                text: '保存',
                onAction: function () {
                    saveFile();
                }
            });
            //文件-退出子菜单
            editor.ui.registry.addMenuItem('closeFile', {
                text: '退出',
                onAction: function () {
                    let obj = getLocalStorage();
                    if (typeof obj !== "undefined") {
                        setTempData(obj);
                        //打开退出检查窗口
                        setModalExitVisible(true);
                    }
                    else {
                        onClose!();
                    }
                }
            });
            //-------------------
            //设置自定义工具栏快捷键
            //文件保存
            editor.ui.registry.addButton('toolbarSave', {
                text: '保存',
                title: '保存文件',
                onAction: function () {
                    saveFile();
                }
            });
            editor.ui.registry.addButton('PhotoGallery', {
                text: '图库',
                title: '进入图库',
                onAction: () => {
                    openPhotoGallery(editor);
                }
                // onAction: function () {
                //     console.log('888888');

                //     openPhotoGallery(editor);
                //     //editor.insertContent('&nbsp;<strong>It\'s my button!</strong>&nbsp;');   //插入文字
                //     //editor.execCommand('mceInsertContent', false, '<img alt="' + title + '" src="' + url + '"/>');       //插入图片                
                // }
            });
            //初始化前事件
            editor.on('init', function (e: any) {
            });
        },
        //执行命令事件
        init_instance_callback: function (editor: any) {
            editor.on('ExecCommand', function (e: any) {
                // console.log('The ' + e.command + ' command was fired.');
            });
        },
        //-------------------------------
        //菜单
        menubar: 'customMenu edit insert view format table',
        //自定子菜单项
        menu: {
            customMenu: { title: '文件', items: 'saveFile | closeFile' }
        },
        //工具栏
        toolbar: 'toolbarSave | PhotoGallery | code | Formatting | alignment',
        //工具栏子项
        toolbar_groups: {
            formatting: {
                text: '文字格式',
                tooltip: 'Formatting',
                items: 'formatselect bold italic underline | superscript subscript',
            },
            alignment: {
                icon: 'align-left',
                tooltip: 'alignment',
                items: 'alignleft aligncenter alignright alignjustify',
            },

        },
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        relative_urls: false,
        file_picker_types: 'image',
        images_upload_url: 'files.wisca.cn',
        image_advtab: true,
        image_uploadtab: true,
        /**
         * 上传图片
         * @param blobInfo 
         * @param success 
         * @param failure 
         */
        images_upload_handler: async (blobInfo: any, success: any, failure: any) => {
            // const uploadActionData = await httpUrl.filesGeneral('files', undefined, 'UploadSingle')
            // const res = await fetchApi.GET(filesManageService, {
            //     apiId: 'configs',
            //     apiVariable: 'system',
            //     apiExtend: 'getNodeValue',
            //     apiData: {
            //         nodeId: apiId + 'FilesGroupId',
            //         privatePar: false
            //     }
            // });
            // let filesGroupId;
            // if (res.success) {
            //     filesGroupId = res.data.nodeValue;
            // }
            // let fd = new FormData();
            // fd.append('file', blobInfo.blob());
            // const uploadRes = await fetch(uploadActionData, {
            //     method: 'POST',
            //     headers: {
            //         filesGroupId,
            //         subdirectory: id
            //     },
            //     body: fd,
            // });
            // if (uploadRes.status >= 200 && uploadRes.status < 300) {
            //     const data = await uploadRes.json();
            //     success((await systemExpand.filesServer()).url + "/" +
            //         data.data.destination + "/" +
            //         data.data.fileName);
            // }
        },
    };

    /**
     * 显示窗口内容
     * @returns 
     */
    const showModalContents = () => {
        return (<>
            <p>时间：{tempData ?
                moment(tempData.updateAt).format('YYYY-MM-DD HH:mm:ss') :
                ''}</p>
            <p>内容：{tempData ?
                (tempData.data.length >= 200 ?
                    tempData.data.substring(0, 200) + '......' :
                    tempData.data) :
                ''}</p>
        </>)
    };

    /**
     * modalExit窗口 三个按钮
     */
    let modalExitButtons = [
        <Button type="primary" onClick={handleExitOk}>
            是
        </Button>,
        <Button onClick={handleExitNo} >
            否
        </Button >,
        <Button onClick={handleExitCancel}>
            取消
        </Button>
    ]

    return (
        <>
            <Editor
                inline={false}
                initialValue={richTextData}
                apiKey='u18whowftd88lho5rhr4rh2qw2cx8k54p2np7imwq1jrq8lf'
                init={{ ...editorObj as any }}
                onEditorChange={handleEditorChange}
            />
            <Modal
                title="临时文件未保存，是否使用上次临时文件？"
                closable={false}
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {showModalContents()}
            </Modal>
            <Modal
                title="数据未保存，是否保存数据？"
                closable={false}
                visible={modalExitVisible}
                footer={modalExitButtons}
                forceRender={true}
                destroyOnClose={true}
            >
                {showModalContents()}
            </Modal>
            {photoGalleryVisible
                ? (<PhotoGallery
                    closeModal={closePhotoGallery}
                    filesGroupData={filesGroupData}
                    defaultMenu={defaultMenu}
                    editorData={editorData}
                    getCheckPhoto={getCheckPhoto}
                />)
                : null}
        </>
    )
};

export default forwardRef(TinMCEEdit);