import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from "../../universal/getList";

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const ShopsEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>();
    const [brandData, setBrandData] = useState<any>();
    const [companyData, setCompanyData] = useState<any>();
    const [transactionModeData, setTransactionModeDat] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {
            fetchApi.GET(
                defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'transactionMode',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setTransactionModeDat(res.data)
                    }
                });
        };
        fetchData();
        //由父组件返回的数据
        if (ref.current) {
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    const companyId = res.data.companyId;
                    const brandId = res.data.brandId;
                    fetchApi.GET(defaultService, {
                        apiId: 'company',
                        apiVariable: companyId
                    }).then(res => {
                        if (res.success) {
                            setCompanyData([res.data]);
                        }
                    });
                    fetchApi.GET(defaultService, {
                        apiId: 'brand',
                        apiVariable: brandId
                    }).then(res => {
                        if (res.success) {
                            setBrandData([res.data]);
                        }
                    })
                }
            })
        }
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: any = {
        ...props,
        form,
        apiId: 'shop',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '店铺'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={780}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={7}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="店铺名称"
                                name="shopName"
                                rules={[{ required: true, message: '店铺名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="联系人"
                                name="contacts">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                label="公司"
                                name="companyId">
                                <Select
                                    showSearch
                                    placeholder="公司"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('company', setCompanyData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(companyData, 'companyName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="联系电话"
                                name="contactCall">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="客服电话"
                                name="customerCall">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="品牌"
                                name="brandId">
                                <Select
                                    showSearch
                                    placeholder="品牌"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('brand', setBrandData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="品牌店"
                                name="isBrand"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="使用店铺支付"
                                name="useShopPay"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="交易方式"
                                name="transactionModeId">
                                <Select
                                    disabled={false}
                                    showSearch
                                    placeholder="交易方式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(transactionModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="商户号"
                                name="mch_id">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="商户秘钥"
                                name="apiSecret">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="商户v3秘钥"
                                name="apiV3Secret">
                                <Input />
                            </Form.Item>
                        </Col>

                        {data.type === 'NEW'
                            ? ''
                            : <label>
                                商户号、商户秘钥、商户v3秘钥在编辑状态不会显示，如不需修改则不用输入
                            </label>}

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default ShopsEdit;