import WebSalesLevelEdit from './WebSalesLevelEdit';
import WebUniversal from '../../system/WebUniversal'
import { columnsheadId } from '../../universal/columnshead';

//组件 WebSalesLevel 
const WebSalesLevel = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '级别名称',
            dataIndex: 'levelName'
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: any = {
        ...props,
        titleName: '销售员级别',
        apiName: 'webSalesLevel',
        columns,
        EditComponent: WebSalesLevelEdit,
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebSalesLevel;
