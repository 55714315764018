import { useEffect, useRef, useState } from 'react';
import CompanyEdit from './CompanyEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import CompanyUploadFile from './CompanyUploadFile';
import fetchApi from '../../../utils/api/fetchApi';
import { columnsheadId } from '../../universal/columnshead';
import { Tooltip } from 'antd';

//组件 Company 
const Company = (props: any) => {
    const [filePath, setFilePath] = useState<any>();
    const ref = useRef<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            const res = await fetchApi.GET(defaultService,
                {
                    apiId: 'configs',
                    apiVariable: 'system',
                    apiExtend: 'getNodeValue',
                    apiData: {
                        nodeId: 'companyFilePath',
                        privatePar: false
                    }
                });
            setFilePath(res.data.nodeValue);
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '公司名称',
            dataIndex: 'companyName',
            sorter: true,
        },
        {
            title: '营业执照',
            dataIndex: 'certificateNo',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '公司',
        apiId: 'company',
        columns,
        filePath,
        showUploadFile: true,
        showImage: true,
        showStates: true,
        EditComponent: CompanyEdit,
        UploadFileComponent: CompanyUploadFile,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Company;
