import objectHandle from '../../utils/baseLib/objectHandle';

export const INITIALSTATE = "INITIALSTATE";
export const SHOWPAGE = "SHOWPAGE";
export const WIPESTATE = 'WIPESTATE';

const initialState: any = {
  apiId: ''
}

/** 提交动作 */
export const InitData = (payload: any) => {
  return {
    type: INITIALSTATE,
    payload,
  };
}

/** 清空数据动作 */
export const WipeData = (payload: any) => {
  return {
    type: WIPESTATE,
    payload,
  };
}

/** 获取数据 */
export const ShowPage = (payload: any) => {
  return {
    type: SHOWPAGE,
    payload,
  };
}

export const showPageReducer = (state: any = initialState, action: { type: any, payload: any }) => {
  switch (action.type) {
    case INITIALSTATE:
      // console.log('state: ');
      // console.log(state);
      // console.log('action:');
      // console.log(action);
      // console.log('action.payload.apiId: ');
      // console.log(action.payload.apiId);
      //如获取地址不同，初始化state
      // const { apiId } = state;
      // const apiIdData = state[apiId];
      // const payloadApiId = action.payload.apiId;
      // let mergeData;
      // if (apiId === '' || apiId !== payloadApiId) {
      //   mergeData = {
      //     ...state,
      //     ...action.payload
      //   }
      // } else {
      //   mergeData = {
      //     ...state,
      //     [apiId]: {
      //       ...apiIdData,
      //       ...action.payload[apiId]
      //     }
      //   }
      // }
      // console.log('finish: ');
      // console.log(mergeData);
      let mergeData = objectHandle.mergeObject(state, action.payload);
      return mergeData;
    case SHOWPAGE:
      return {
        ...state,
        ...action.payload,
      };
    case WIPESTATE:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
}