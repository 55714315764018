import { Select } from "antd";
import fetchApi from "../../utils/api/fetchApi";
import systematic from '../../config/systematic';
import objectHandle from '../../utils/baseLib/objectHandle';

const { Option } = Select;
/** 后端数据defaultService */
const defaultService = systematic.bmService;

/**
 * 由数据集生成列表Option
 */
const getList = () => {
};

/**
 * 获取通用列表
 * @param rows 数据集
 * @param itemName Option.item.name，默认：'name'
 * @param itemId Option.item.id，默认：'id'
 * @param noId 没有id键的数组
 * @param includeNull 包括 无(NULL)，默认：false
 * @returns Option 
 */
getList.general = (rows: any, itemName?: string, itemId?: string, noId?: boolean, includeNull?: boolean) => {
    if (typeof rows !== "undefined") {
        if (rows.constructor === Object) {
            rows = [rows]
        }
        /** Option.item.id */
        let itemIdx = 'id';
        if (typeof itemId !== 'undefined') {
            itemIdx = itemId;
        }
        /** Option.item.name */
        let itemNamex = 'name';
        if (typeof itemName !== 'undefined') {
            itemNamex = itemName;
        }
        if (typeof includeNull === 'undefined') {
            includeNull = false;
        }
        if (includeNull === true) {
            rows = rows.concat({
                [itemIdx]: null,
                [itemNamex]: '无（null）'
            });
        }
        if (rows.length === undefined) { return undefined }
        return (rows.map((item: any, key: number) => {
            return <Option value={
                noId === true ?
                    item
                    :
                    item[itemIdx]
            }
                key={key}>
                {noId === true ?
                    item
                    :
                    item[itemNamex]}
            </Option>
        }))
    }
};

/** 获取通用列表的操作类型 */
export declare type generalOPType = {
    /** Option.item.id，默认：'id' */
    itemId?: string,
    /** Option.item.name，默认：'name' */
    itemName?: string,
    /** 没有id键的数组 */
    noId?: boolean,
    /** 包括 无(NULL)，默认：false */
    includeNull?: boolean
};

/**
 * 获取通用列表Ex
 * @param rows 数据集
 * @param generalOP 获取通用列表的操作类型
 * @returns Option 
 */
getList.generalEx = (rows: any, generalOP?: generalOPType) => {
    if (!generalOP) { generalOP = {}; }
    let { itemId, itemName, noId, includeNull } = generalOP;
    if (typeof includeNull === 'undefined') {
        includeNull = false;
    }
    return getList.general(rows, itemName, itemId, noId, includeNull);
};

/**
 * 查询
 * @param apiId 接口名称
 * @param setData react useState => setData
 * @param queryString 查询的内容
 * @returns 列表 | undefined
 */
getList.search = async (apiId: string, setData: any, queryString?: string,) => {
    if (objectHandle.isEmpty(queryString) === true) {
        console.log('查询不能为空');
        return undefined;
    }
    const res = await fetchApi.GET(defaultService,
        {
            apiId,
            apiExtend: 'showList',
            apiData: {
                queryString
            }
        })
    if (res.success === true) {
        setData(res.data);
        console.log('查询到:');
        console.log(res.data);
    }
    return setData;
};


/**
 * 获取通用列表
 * @param rows 数据集
 * @param itemName Option.item.name，默认：'name'
 * @param itemId Option.item.id，默认：'id'
 * @param noId 没有id键的数组
 * @param label 是否自定义显示数据
 * @param children 自定义下拉框显示
 * @returns Option 
 */
getList.generalOption = (rows: any, itemName?: string, itemId?: string, noId?: boolean, label?: string, children?: any) => {

    if (typeof rows !== "undefined") {

        if (rows.constructor === Object) {
            rows = [rows]
        }
        if (rows.length === undefined) { return undefined }
        return (rows.map((item: any, key: number) => {
            return <Option label={label ? item[label] : null} value={
                noId === true ?
                    item
                    :
                    typeof itemId === 'undefined' ? item.id : item[itemId]
            }
                key={key}>
                {children ?
                    children(item)
                    : noId === true ?
                        item
                        :
                        typeof itemName === 'undefined' ? item.name : item[itemName]}
            </Option>
        }))
    }
};

export default getList;