import AppLayout from '../AppLayout'
import basicRouter from './basicRouter';
import webWxRouter from './webWxRouter';
import messageRouter from './messageRouter';
import payRouter from './payRouter';

//system
import Home from '../components/system/Welcome'
import Configs from '../components/basic/configs/Configs';
import ConfigNodes from '../components/basic/configs/ConfigNodes';
import SystemLogger from '../components/system/logger/SystemLogger';
import OpLogger from '../components/system/logger/OpLogger';
import PayLogger from '../components/system/logger/PayLogger';
import CronTasks from '../components/system/cronTasks/CronTasks';

import FilesServer from '../components/filesSystem/FilesServer';
import FilesService from '../components/filesSystem/FilesService';
import FilesGroup from "../components/filesSystem/FilesGroup";
import Files from "../components/filesSystem/Files";

import TinyMCEEdit from "../components/system/TinyMCEEdit";

import WebGlobalData from '../components/system/WebGlobalData';

//development
import { RouteObject } from "react-router-dom"

import DevelopProjects from '../components/development/DevelopProjects';
import DevelopApi from '../components/development/DevelopApi';
import DevelopDatabase from '../components/development/DevelopDatabase';
import DevelopTable from '../components/development/DevelopTable';
import Login from '../components/basic/login/Login';

//t
import Passage from '../components/t/Passage';

const routers: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout></AppLayout>,
    children: [
      {
        element: <Home />,
        index: true
      },
      {
        path: "/configs",
        element: <Configs />,
      },
      {
        path: "/configNodes",
        element: <ConfigNodes />,
      },
      {
        path: "/systemLogger",
        element: <SystemLogger />,
      },
      {
        path: "/opLogger",
        element: <OpLogger />,
      },
      {
        path: "/payLogger",
        element: <PayLogger />,
      },
      {
        path: "/filesServer",
        element: <FilesServer />,
      },
      {
        path: "/filesService",
        element: <FilesService />,
      },
      {
        path: "/filesGroup",
        element: <FilesGroup />,
      },
      {
        path: "/files",
        element: <Files />,
      },
      {
        path: "/tinyMCE",
        element: <TinyMCEEdit />,
      },
      {
        path: "/globalData",
        element: <WebGlobalData />,
      },
      {
        path: "/cronTasks",
        element: <CronTasks />,
      },

      //development
      {
        path: "/developProjects",
        element: <DevelopProjects />,
      },
      {
        path: "/developApi",
        element: <DevelopApi />,
      },
      {
        path: "/developDatabase",
        element: <DevelopDatabase />,
      },
      {
        path: "/developTable",
        element: <DevelopTable />,
      },
      //t
      {
        path: "/passage",
        element: <Passage />,
      },
      {
        path: '*',
        element: <div>找不到页面/页面不存在</div>
      }

    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '*',
    element: <div>找不到页面/页面不存在</div>
  }


]
let indexRouters = routers.map((item: any, index: number) => {
  if (item.children) {
    return {
      ...item, children: item.children.concat(basicRouter)
        .concat(webWxRouter).concat(messageRouter)
        .concat(payRouter)
    }
  } else {
    return { ...item }
  }
})


export default indexRouters;