import WebProductSpecTypeEdit from './WebProductSpecTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const webProductSpecType = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '产品规格分类',
            dataIndex: 'specTypeName',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品规格分类',
        apiId: 'webProductSpecType',
        columns,
        showStates: true,
        EditComponent: WebProductSpecTypeEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default webProductSpecType;
