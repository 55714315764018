import WebSalesEdit from './WebSalesEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 WebSales 
const WebSales = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'salesName'
        },
        {
            title: '微信',
            dataIndex: 'wxUser',
            render: (text: any) => (
                text ? text.nickName : ''
            )
        },
        {
            title: '级别',
            dataIndex: 'salesLevel_Name',
        },
        {
            title: '余额',
            dataIndex: 'balance',
        },
        {
            title: '收入表',
            dataIndex: 'balance',
        },
        {
            title: '提现表',
            dataIndex: 'balance',
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '销售员',
        apiId: 'webSales',
        showAdd: false,
        columns,
        EditComponent: WebSalesEdit,
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebSales;
