import React, { useState } from 'react';
import { useEffect } from 'react';
import fetchApi from '../../../utils/api/fetchApi'
import moment from 'moment';
import { Button, DatePicker, Select, Form, Col, Row, Table, Tooltip, Space } from 'antd';
import systematic from '../../../config/systematic'

import DownloadExcel from '../../report/DownloadExcel';

const { RangePicker } = DatePicker;
const { Option } = Select;
const WebSalesCommissionReport = (props: any) => {
    const defaultService = systematic.bmService;

    const [form] = Form.useForm()

    // 店铺数据
    const [shopData, setShopData] = useState<any>()

    //选中的店铺
    const [chooseShop, setChooseShop] = useState<any>("")

    // 合并时间跟apiId的数据
    const [prop, setProp] = useState<any>()

    //表头
    const columnsHead = [
        {
            title: '销售人',
            dataIndex: 'sales',
            render: (text: any) => (<span>{text ? text.salesName : ""}</span>),
            width: 100,
        },
        {
            title: '商品名称',
            dataIndex: 'productOrderDetail_productName',
            width: 200,
        },
        {
            title: '规格',
            dataIndex: 'productOrderDetail_productPriceSpec',
            width: 200,
        },

        // {
        //     title: '销售次数',
        //     dataIndex: 'sumAmount',
        //     width: 100,
        // },
        // {
        //     title: '总价格',
        //     dataIndex: 'totalPrice',
        //     width: 100,
        // },
        {
            title: '销售金额',
            dataIndex: 'totalPurchasePrice',
            width: 100,
        },
        {
            title: '销售提成',
            dataIndex: 'commissionsAmount',
            render: (text: any, record: any) => (<span>{text ? text : 0}</span>),
            width: 100,
        },
        {
            title: '店铺',
            dataIndex: 'shop',
            render: (text: any, record: any) => {
                //console.log("text",text);
                //console.log("record",record);
                return (<span>{text ? text.shopName : chooseShop}</span>)
            },
            width: 100,
        }
    ];
    //处理参数并返回
    const parameter = (item: any) => {
        let time: any = [];
        if (item.chooseTime) {
            item.chooseTime.map((item: any) => {
                time.push(moment(item.$d).format('YYYY-MM-DD'))
            })
        } else {
        }
        return{ ...item, time }
    }

    //查询按钮事件
    const onFinish =async (values: any) => {

        let retureData= parameter(values)
         if(retureData){    
            let data = await fetchApi.GET(defaultService, {
                apiId: "webReport",
                apiExtend: 'salesCommission',
                apiData: {
                    //如果有选择时间则按照选择的时间，否则结束时间是当前时间，开始时间为相对当前时间的前一个月
                    "startDate": retureData.time.length !== 0 ? retureData.time[0] + " " + "00:00:00" : moment().subtract(1, "months").format("YYYY-MM-DD") + " " + "00:00:00",
                    "endDate": retureData.time.length !== 0 ? retureData.time[1] + " " + "23:59:59" : moment().format(`YYYY-MM-DD `) + " " + "23:59:59",
                    "shopId": retureData.shopId || ""
                }
            })

            //如果数据请求成功
            if (data.success) {
                //console.log("data",data);

                let date = data.data.data
                setProp({ data: date, props: { apiId: "webReport-salesCommission", queryCriteria: { ...data.data.queryCriteria } } });
                // console.log({ data: date, props: { apiId: "webReport-salesCommission", queryCriteria: { ...data.data.queryCriteria } } });


                //判断是否有数据而且存在data.data.queryCriteria.shop
                if (data.data.queryCriteria.shop && date.length !== 0) {
                    setChooseShop(data.data.queryCriteria.shop.shopName)
                }
            }
         }
        
    }

    useEffect(() => {
        const fetch = async () => {
            //店铺数据
            let shopData = await fetchApi.GET(defaultService, {
                apiId: "shop",
                apiExtend: 'showPage'
            })

            if (shopData.success) {
                let newShopData = [{ id: "", shopName: "全部" }, ...shopData.data.rows]
                setShopData(newShopData)
            }
        }
        fetch()
    }, [])

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col span={7}>
                        <Form.Item
                            label="选择时间"
                            name="chooseTime"
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                                placeholder={[moment().subtract(1, "months").format("YYYY-MM-DD"), moment().format(`YYYY-MM-DD`)]}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="店铺"
                            name="shopId"
                        >
                            <Select
                                optionFilterProp="children"
                                placeholder={"全部"}
                            >
                                {shopData && shopData.length > 0 ? shopData.map((item: any) => (
                                    <Option value={item.id} key={item.id}>{item.shopName}</Option>
                                )) : undefined}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button >
                                <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                    下载EXCEL文件
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey={(record: any) => (`${record.productOrderDetail_productId}${Math.random() * 1 + 10000000000}`)}

                columns={columnsHead} dataSource={prop ? prop.data : []}
                summary={(pageData: any) => {
                    //总销售金额
                    let totalPurchasePrice: number = 0;

                    //总销售提成
                    let commissionsAmount: number = 0

                    pageData.forEach((item: any) => {
                        totalPurchasePrice += Number(item.totalPurchasePrice)
                        commissionsAmount += item.commissionsAmount ? Number(item.commissionsAmount) : 0
                    });
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{ }</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>{ }</Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>{`${totalPurchasePrice === 0 ? "" : Number(totalPurchasePrice).toFixed(2)}`}</Table.Summary.Cell>
                                <Table.Summary.Cell index={6}>{`${commissionsAmount === 0 ? "" : Number(commissionsAmount).toFixed(2)}`}</Table.Summary.Cell>
                                <Table.Summary.Cell index={7}>{ }</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}
            >
            </Table>
        </>
    );
}
export default WebSalesCommissionReport