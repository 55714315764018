import { useEffect, useRef } from 'react';
import WebProductCategoryEdit from './WebProductCategoryEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const webProductCategory = (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'webProductCategory';
    let handleChangeOne: any;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '产品大类',
            dataIndex: 'categoryName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品大类',
        apiId,
        columns,
        showStates: true,
        EditComponent: WebProductCategoryEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default webProductCategory;
