import { Form } from 'antd';
import {useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import  { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import Pop from '../../popUp/Pop';
import getList from "../../universal/getList";
import {initData} from '../../typeValidation'
import systematic from '../../../config/systematic'
import dayjs from 'dayjs';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebCouponEdit = (props: any) => {

    const [form] = Form.useForm(); //form 
    const { data } = props;    
    const [shopData, setShopData] = useState<any>();
    const [productCategoryData, setProductCategoryData] = useState<any>();
    const [productTypeData, setProductTypeData] = useState<any>();
    const [productData, setProductData] = useState<any>();
    const [couponLinkData, setCouponLinkData] = useState<any>();
    const [couponLinkIdName, setCouponLinkIdName] = useState<any>();
    const [couponLinkIdDisabled, setCouponLinkIdDisabled] = useState(true);
    const [couponScopeOfUseSelectId, setCouponScopeOfUseSelectId] = useState(0);

    const [couponModeData, setCouponModeData] = useState<any>();
    const [couponTypeData, setCouponTypeData] = useState<any>();
    const [deductionTooltip, setDeductionTooltip] = useState<any>('根据优惠券类型，抵扣金额或折扣');
    const [couponScopeOfUseData, setCouponScopeOfUseData] = useState<any>();

    const [shopIdRequired, setShopIdRequired] = useState(false);

    
    //只执行一次
    const [oneUse, setOneUse] = useState(true);
    const [oneUse2, setOneUse2] = useState(true);
    /** 后端数据defaultService */
     const defaultService=systematic.bmService

  
    useEffect(() => {
        const fetchData = async () => {
            let resCouponMode = await fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'couponMode',
                    apiExtend: 'showList'
                })
            if (resCouponMode.success) {
                setCouponModeData(resCouponMode.data);
            }

            let resShop = await fetchApi.GET(defaultService,
                {
                    apiId: 'shop',
                    apiExtend: 'showList'
                })
            if (resShop.success) {
                setShopData(resShop.data);
            }

            let resWebProductCategory = await fetchApi.GET(defaultService,
                {
                    apiId: 'webProductCategory',
                    apiExtend: 'showList'
                })
            if (resWebProductCategory.success) {
                setProductCategoryData(resWebProductCategory.data);
            }

            let resWebProductType = await fetchApi.GET(defaultService,
                {
                    apiId: 'webProductType',
                    apiExtend: 'showList'
                })
            if (resWebProductType.success) {
                setProductTypeData(resWebProductType.data);
            }

            let resWebProduct = await fetchApi.GET(defaultService,
                {
                    apiId: 'webProduct',
                    apiExtend: 'showList'
                })
            if (resWebProduct.success) {
                setProductData(resWebProduct.data);
            }

            if (data.type !== 'NEW') {
                let resCouponModeData = resCouponMode.data;

                let resCouponTypeData = getData(resCouponModeData, form.getFieldValue('couponModeId'), 'couponType');
                if (form.getFieldValue('couponModeId') === 2) { setShopIdRequired(true); }
                let resCouponScopeOfUseData = getData(resCouponTypeData, form.getFieldValue('couponTypeId'), 'couponScopeOfUse');
                // console.log("resCouponScopeOfUseData==>", resCouponScopeOfUseData);

                //设置couponType列表 
                setCouponTypeData(resCouponTypeData);
                //设置couponScopeOfUse列表 
                setCouponScopeOfUseData(resCouponScopeOfUseData);

                //获取couponScopeOfUseId数据
                let couponScopeOfUseId = form.getFieldValue('couponScopeOfUseId');
                switch (couponScopeOfUseId) {
                    case 1: //店铺
                        disabledUse();
                        break;
                    case 2: //大类
                        getCouponLinkIdList(couponScopeOfUseId, resWebProductCategory.data);
                        getCouponLinkId(form.getFieldValue('productCategoryCouponId'))

                        break;
                    case 3: //分类
                        getCouponLinkIdList(couponScopeOfUseId, resWebProductType.data);
                        getCouponLinkId(form.getFieldValue('productTypeCouponId'))
                        break;
                    case 4: //产品
                        getCouponLinkIdList(couponScopeOfUseId, resWebProduct.data);
                        getCouponLinkId(form.getFieldValue('productCouponId'))
                        break;
                }

            }
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 禁用大类、分类、产品选择框
     */
    const disabledUse = () => {
        setCouponLinkIdDisabled(true);
        setCouponLinkIdName('')
        setCouponLinkData(undefined);
        setCouponScopeOfUseSelectId(0);
    }

    /**
     * 数组由id获取数据
     * @param rows 
     * @param id 
     * @returns 
     */
    const getData = (rows: any, id: number, typeName: string) => {
        let data;
        if (rows.constructor === Array) {
            for (var i = 0, leni = rows.length; i < leni; i++) {
                if (rows[i].id === id) {
                    data = rows[i][typeName];
                }
            }
        }
        return data;
    };

    /**
     * 选择优惠券模式
     * @param couponModeId 
     */
    const onSelectCouponMode = (couponModeId: number) => {
        
        //重置deduction
        form.resetFields(['couponTypeId']);
        onSelectCouponType();
        //获取couponType列表 
        setCouponTypeData(getData(couponModeData, couponModeId, 'couponType'))
        
        //
        if (couponModeId === 2) {
            setShopIdRequired(true);
        } else {
            
            setShopIdRequired(false);
            //重置shopId
            form.resetFields(['shopId']);
        }
    };

    /**
    * 选择优惠券类型
    * @param couponTypeId 
    */
    const onSelectCouponType = (couponTypeId?: number) => {
        //重置deduction
        form.resetFields(['deduction', 'couponScopeOfUseId']);
        onSelectCouponScopeOfUse();
        //获取couponScopeOfUse列表
        if (typeof couponTypeData !== "undefined" && typeof couponTypeId !== "undefined") {

            setCouponScopeOfUseData(getData(couponTypeData, couponTypeId, 'couponScopeOfUse'))
        }
        else {
            setCouponScopeOfUseData(undefined);
        }
        switch (couponTypeId) {
            case 1:
                setDeductionTooltip('现金券类型，抵扣金额，如优惠金额为30，直接输30')
                break;
            case 2:
                setDeductionTooltip('折扣券类型，优惠折扣，如优惠折扣15%请输入0.15')
                break;
            default:
                setDeductionTooltip('根据优惠券类型，抵扣金额或折扣')
                break;
        }
    };

    /**
     * 选择优惠券使用范围
     * @param couponScopeOfUseId 
     * @param showListData 
     */
    const onSelectCouponScopeOfUse = (couponScopeOfUseId?: number, showListData?: any) => {
        //重置couponLinkId
        form.resetFields(['couponLinkId']);
        setCouponLinkIdDisabled(false);
        setCouponScopeOfUseSelectId(couponScopeOfUseId!);
        // setShopIdRequired(true);
        
        switch (couponScopeOfUseId) {
            case 1:
                disabledUse();
                break;
            case 2:
                if (typeof showListData === 'undefined') { showListData = productCategoryData }
                setCouponLinkIdName('大类')
                setCouponLinkData(getList.general(showListData, 'categoryName'));
                break;
            case 3:
                if (typeof showListData === 'undefined') { showListData = productTypeData }
                setCouponLinkIdName('分类')
                setCouponLinkData(getList.general(showListData, 'typeName'));
                break;
            case 4:
                if (typeof showListData === 'undefined') { showListData = productData }
                setCouponLinkIdName('产品')
                setCouponLinkData(getList.general(showListData, 'productName'));
                setShopIdRequired(false);
                break;
            default:
                disabledUse();
                break;
        }
    };

    /**
     * 选择优惠券使用范围数据
     * @param couponLinkId 数据id
     */
    const onSelectCouponLink = (couponLinkId: string) => {
        //由产品id 获取店铺id
        fetchApi.GET(defaultService,
            {
                apiId: 'webProduct',
                apiVariable: couponLinkId
            }).then(res => {
                if (res.success === true) {
                    /** 店铺Id */
                    const shopId = res.data.shopId
                    form.setFieldsValue({ shopId })
                }
            })
    };

    /**
     * 根据 couponScopeOfUseId 获取couponLinkId 列表
     * @param couponScopeOfUseId 
     * @param selectListData 
     */
    const getCouponLinkIdList = (couponScopeOfUseId: number, selectListData: any) => {
        if (oneUse === true) {
            if (typeof couponScopeOfUseId !== 'undefined' && couponScopeOfUseId !== null) {
                onSelectCouponScopeOfUse(couponScopeOfUseId, selectListData);
                setOneUse(false);
            }
        }
    };

    /**
     * 设置couponLinkId数据
     * @param couponLinkId 
     */
    const getCouponLinkId = (couponLinkId: string) => {
        if (oneUse2 === true) {
            if (typeof couponLinkId !== 'undefined' && couponLinkId !== null) {
                //设置表单couponLinkId数据
                form.setFieldsValue({ couponLinkId })
                setOneUse2(false);
            }
        }
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webCoupon',
        dateColumnName: ['collectStartDate',
            'collectEndDate',
            'useStartDate',
            'useEndDate'],
    };

  //form数据结构
    let formData = [
        {
            count: 0, data: [
                { type: "Input", label: "id", name: "id",rules: [{}], disable: true, col: 12 },
                { type: "Input", label: "名称", name: "couponName", rlues: [{message: '优惠券名称!'}], disable: false, col: 12, required: true },
                {
                    type: "Select",
                    label: "模式",
                    name: "couponModeId",
                    rlues: [{message: '选择优惠券模式!' }],
                    col: 8,
                    placeholder: "优惠券模式",
                    showSearch: true,
                    optionFilterProp: "children",
                    onSelect: (item: any) => {
                        onSelectCouponMode(item);
                    },
                    filterOption: (input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    data: getList.general(couponModeData),
                    required: true
                },
                {
                    type: "Select",
                    label: "类型",
                    name: "couponTypeId",
                    rlues: [{message: '选择优惠券类型!'}],
                    col: 8,
                    placeholder: "优惠券类型",
                    showSearch: true,
                    optionFilterProp: "children",
                    onSelect: (item: any) => {
                        onSelectCouponType(item);
                    },
                    filterOption: (input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    data: getList.general(couponTypeData),
                    required: true
                },
                {
                    type: "InputNumber",
                    name: "deduction",
                    label: "可抵扣额",
                    rlues: [{ message: '可抵扣额!'}],
                    Tooltip: true,
                    placement: "topLeft",
                    title: deductionTooltip,
                    col: 8,
                    required: true
                },
            ]
        },
        {
            count: 1, data: [

                {
                    type: "Select",
                    name: "couponScopeOfUseId",
                    label: "优惠券使用范围",
                    optionFilterProp: "children",
                    rlues: [{ message: '优惠券使用范围!' }],
                    filterOption: (input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    col: 24,
                    required: true,
                    onSelect: (item: any) => {
                        onSelectCouponScopeOfUse(item);
                    },
                    data: getList.general(couponScopeOfUseData),

                },
                {
                    type: "Select",
                    name: "couponLinkId",
                    label: couponLinkIdName,
                    optionFilterProp: "children",
                    rlues: [{ message: '请选择优惠券使用范围数据!' }],
                    filterOption: (input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    disable: couponLinkIdDisabled,
                    col: 24,
                    showSearch: true,
                    onSelect: (item: any) => {
                        onSelectCouponLink(item);
                    },
                    data: couponLinkData,
                    required: couponScopeOfUseSelectId === 0 ? false : true,
                },
                {
                    type: "Select",
                    name: "shopId",
                    label: "店铺",
                    rlues: [{ message: '店铺!' }],
                    disable: !shopIdRequired,
                    col: 24,
                    showSearch: true,
                    required: shopIdRequired,
                    Tooltip: true,
                    placement: "topLeft",
                    title: shopIdRequired ? '模式选择为‘店铺’时，必需选择店铺' : '',
                    placeholder: "店铺",
                    optionFilterProp: "children",
                    filterOption: (input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    data: getList.general(shopData, 'shopName')

                },
                {

                    type: "DatePicker",
                    name: "collectStartDate",
                    label: "领用起始日期",
                    optionFilterProp: "children",
                    rlues: [{message: '领用起始日期!'}],
                    col: 12,
                    initialValue: dayjs(new Date()),
                    format: "YYYY-MM-DD HH:mm:ss",
                    required: true,
                    row:true,
                },
                {
                    type: "DatePicker",
                    name: "collectEndDate",
                    label: "领用结束日期",
                    optionFilterProp: "children",
                    rlues: [{ message: '领用结束日期!' }],
                    col: 12,
                    initialValue: dayjs(new Date()),
                    format: "YYYY-MM-DD HH:mm:ss",
                    required: true,
                    row:true,

                },
                {
                    type: "DatePicker",
                    name: "useStartDate",
                    label: "起始使用日期",
                    optionFilterProp: "children",
                    rlues: [{ message: '起始使用日期!'}],
                    initialValue: dayjs(new Date()),
                    col: 12,
                    format: "YYYY-MM-DD HH:mm:ss",
                    required: true,
                    row:true,

                },
                {
                    type: "DatePicker",
                    name: "useEndDate",
                    initialValue: dayjs(new Date()),
                    label: "结束使用日期",
                    optionFilterProp: "children",
                    rlues: [{message: '结束使用日期!'}],
                    col: 12,
                    format: "YYYY-MM-DD HH:mm:ss",
                    required: true,
                    row:true,
                },
            ]
        },
        {
            count: 2, data: [

                {
                    type: "InputNumber",
                    name: "fullUse",
                    label: "满额使用",
                    rlues: [{ message: '满额使用!'}],
                    Tooltip: true,
                    placement: "topLeft",
                    title: "以原价格为准，如填0不限制",
                    required: true
                },
                {
                    type: "InputNumber",
                    name: "collectAmount",
                    label: "可领数量",
                    Tooltip: true,
                    size:"middle",
                    placement: "topLeft",
                    title: "每人可领用优惠券数量",
                    rlues: [{ message: '可领数量!'}],
                    required: true
                },
                {
                    type: "Switch",
                    name: "isRepeatedCollect",
                    label: "重复领取",
                    rlues: [],
                    checkedChildren: "是",
                    unCheckedChildren: "否",
                    valuePropName: "checked",
                    Tooltip: true,
                    placement: "topLeft",
                    title: "优惠券使用后是否可以重复领取",
                    initialValue: true,
                    checked: true,
                },
                {
                    type: "InputNumber",
                    name: "total",
                    label: "券总数",
                    required: data.type === 'NEW' ? true : false,
                    rlues: [{ message: '优惠券总数!' }],
                    disable: data.type === 'NEW' ? false : true,
                

                },
                {
                    type: "InputNumber",
                    name: "stock",
                    label: "券库存",
                    rlues: [{ message: '券库存总数!' }],
                 
                    required: data.type === 'NEW' ? false : true,
                    disable: data.type === 'NEW' ? true : false,

                },
                {
                    type: "Switch",
                    name: "isConversionCode",
                    label: "兑换码",
                    rlues: [],
                    checkedChildren: "是",
                    unCheckedChildren: "否",
                    valuePropName: "defaultChecked",
                    Tooltip: true,
                    placement: "topLeft",
                    title: "是否需要兑换码",
                    initialValue: false,
                    // checked: false,
                },
              
            ]
        }
    ]
   //整体传过去的数据
    let initData:initData={
        extendProps,
        formData,
        data,
        form,
        vis:true,
        title:"优惠券",
    }
    return (
        <>
            <Pop props={initData}></Pop>
        </>
    )
}

export default WebCouponEdit;