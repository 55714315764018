import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import { Switch } from 'antd';

//组件 WebSalesCashOut 
const WebSalesCashOut = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '销售',
            dataIndex: 'sales',
            render: (text: any) => (
                text ? text.salesName : ''
            )
        },
        {
            title: '申请日期',
            dataIndex: 'sysDate',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {moment(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '提现金额',
            dataIndex: 'moneyAmount',
        },
        {
            title: '提现',
            dataIndex: 'isCashOut',
            render: (text: any, record: any) => (
                <Switch checkedChildren="已支付" unCheckedChildren="未支付"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '提现日期',
            dataIndex: 'cashOutDate',
            render: (val: moment.MomentInput, record: any) =>
                <span>
                    {moment(record.cashOutDate).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '销售员提现表',
        apiId: 'webSalesCashOut',
        columns,
        showAdd: false,
        showStates: true,
        showOperation: false
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebSalesCashOut;
