import { Form, Input, Row, Col, Switch, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from "../../universal/getList";

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据defaultService */
    let defaultService: any = '';

    const [wxSubscribeMessageTypeData, setWxSubscribeMessageTypeData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then((res: any) => {
            })
        }
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'wxSubscribeMessageType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setWxSubscribeMessageTypeData(res.data);
                    }
                })
        }
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'wxSubscribeMessageUser',
        serviceId: 'message'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '微信订阅信息用户'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyPress={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="类型"
                                name="wxSubscribeMessageTypeId"
                                rules={[{ required: true, message: '类型!' }]}>
                                <Select

                                    showSearch
                                    placeholder="id"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(wxSubscribeMessageTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="unionId"
                                name="unionId"
                                rules={[{ required: true, message: 'unionId!' }]}>
                                <Input placeholder="unionId" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="openId"
                                name="openId"
                                rules={[{ required: true, message: 'openId!' }]}>
                                <Input placeholder="openId" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="订阅"
                                name="isSuccessfully"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="成功"
                                    unCheckedChildren="失败" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebEdit;