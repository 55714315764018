import WebProductPriceFileEdit from './WebProductPriceFileEdit';
import UploadFileListUniversal, { UFLUExtendPropsType } from '../../filesSystem/UploadFileListUniversal';
import { columnsheadId } from '../../universal/columnshead';
import systemExpand from '../../../config/systemExpand';
import { useEffect, useState } from 'react';

//组件
const WebProductPriceUploadFile = (props: any) => {
    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();
    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { webProductPriceFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '产品价格图片',
                apiId: 'webProductPrice',
                filesGroupId: webProductPriceFilesGroupId,
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                EditComponent: WebProductPriceFileEdit,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filesColumns = columnsheadId;

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default WebProductPriceUploadFile;