/** 数字处理 */
const numberHandle = () => { };

/**
 * 生成从[最小数]到[最大数]的随机数
 * @param minNum 最小数
 * @param maxNum 最大数
 * @returns 返回随机数
 */
numberHandle.rangeNumber = (minNum: number, maxNum: number) => {
    /** 取值范围的差 */
    var range = maxNum - minNum;
    /** 小于1的随机数 */
    var random = Math.random();
    /** 最小数与随机数和取值范围求和，返回想要的随机数字 */
    return minNum + Math.round(random * range);
};

/**
 * 8进制转16进制
 * @param uint8Array 
 * @returns 
 */
numberHandle.uint8To16 = (uint8Array: Uint8Array) => {
    return Array.prototype.map
        .call(uint8Array, (x: any) => ('00' + x.toString(16)).slice(-2))
        .join('');
};

/**
 * 二进制转16进制
 * @param buffer 
 * @returns 
 */
numberHandle.bufferTo16 = (buffer: Buffer) => {
    return Array.prototype.map.call(new Uint8Array(buffer), (x: { toString: (arg0: number) => string; }) => ('00' +
        x.toString(16)).slice(-2)).join('');
};

/**
 * 16进制转二进制
 * @param hex 
 * @returns 
 */
numberHandle.uint16Tobuffer = (hex: any) => {
    var typedArray = new Uint8Array(hex.match(/[\da-f]{2}/gi).map(function (h: string) {
        return parseInt(h, 16)
    }))
    return typedArray.buffer
};

export default numberHandle;