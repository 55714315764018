import React, { ReactElement, useEffect, useState } from 'react';
import { formInitDataType } from '../typeValidation';
import { Col, Form, Input, Select, InputNumber, DatePicker, Switch, Tooltip, Row } from 'antd';
const ComForm = (props: any): ReactElement => {
    let data: formInitDataType = props.props
    const { form, formItem, onFinish } = data
    const [formItemData, setFormItemData] = useState<any>([])

    //处理input
    const inputItem = (item: any):ReactElement  => {
        //规则处理
        let rule = rules(item)
        return (
            <Tooltip key={item.name} title={item.Tooltip && item.title ? item.title : null}>
                <Col key={item.name} span={item.col || 8}>
                    <Form.Item
                        label={item.label}
                        name={item.name}
                        key={item.name}
                        rules={rule}
                        required={item.required}
                    >
                        <Input
                            addonAfter={item.addonAfter}
                            addonBefore={item.addonBefore}
                            allowClear={item.allowClear}
                            bordered={item.bordered}
                            defaultValue={item.defaultValue}
                            id={item.id}
                            maxLength={item.maxLength}
                            prefix={item.prefix}
                            size={item.size}
                            suffix={item.suffix}
                            type={item.type}
                            value={item.value}
                            onChange={item.onChange}
                            onPressEnter={item.onPressEnter}
                            disabled={item.disable}
                        />
                    </Form.Item>
                </Col>
            </Tooltip>

        )
    }
    //处理Switch
    const SwitchC = (item: any):ReactElement  => {
        //规则处理
        let rule = rules(item)
        return (
            <Tooltip key={item.name} title={item.Tooltip && item.title ? item.title : null}>
                <Col key={item.name} span={item.col || 8}>
                    <Form.Item
                        label={item.label}
                        name={item.name}
                        key={item.name}
                        rules={rule}
                        valuePropName={item.valuePropName}
                        initialValue={item.initialValue}
                    >
                        <Switch
                            autoFocus={item.autoFocus}
                            checked={item.checked}
                            checkedChildren={item.checkedChildren}
                            className={item.className}
                            defaultChecked={item.defaultChecked}
                            disabled={item.disable}
                            loading={item.loading}
                            size={item.size}
                            onChange={item.onChange}
                            unCheckedChildren={item.unCheckedChildren}
                            onClick={item.onClick}
                        />
                    </Form.Item>
                </Col>
            </Tooltip>

        )
    }
    //处理数字框
    const inputNumber = (item: any):ReactElement  => {
        //规则处理
        let rule = rules(item)   
        return (
            <Tooltip key={item.name} placement={item.Tooltip ? item.placement : null} title={item.Tooltip ? item.title : null}>
                <Col key={item.name} span={item.col || 8}>
                    <Form.Item
                        label={item.label}
                        name={item.name}
                        key={item.name}
                        rules={rule}
                    >
                        <InputNumber
                            addonAfter={item.addonAfter}
                            addonBefore={item.addonBefore}
                            autoFocus={item.autoFocus}
                            bordered={item.bordered}
                            controls={item.controls}
                            decimalSeparator={item.decimalSeparator}
                            defaultValue={item.defaultValue}
                            disabled={item.disable}
                            formatter={item.formatter}
                            keyboard={item.keyboard}
                            max={item.max}
                            min={item.min}
                            parser={item.parser}
                            precision={item.precision}
                            readOnly={item.readOnly}
                            prefix={item.prefix}
                            size={item.size}
                            stringMode={item.stringMode}
                            value={item.value}
                            onChange={item.onChange}
                            onPressEnter={item.onPressEnter}
                            onStep={item.onStep}
                        />
                    </Form.Item>
                </Col>
            </Tooltip>

        )
    }
    //处理DatePicker
    const datePicker = (item: any):ReactElement  => {
        //规则处理
        let rule = rules(item)
        return (
            <Tooltip key={item.name} title={item.Tooltip && item.title ? item.title : null}>
                <Col key={item.name} span={item.col || 8}>
                    <Form.Item
                        label={item.label}
                        name={item.name}
                        key={item.name}
                        rules={rule}
                        initialValue={item.initialValue}
                    >
                        <DatePicker
                            defaultPickerValue={item.defaultPickerValue}
                            defaultValue={item.defaultValue}
                            disabledTime={item.disabledTime}
                            format={item.format}
                            renderExtraFooter={item.renderExtraFooter}
                            showNow={item.showNow}
                            showTime={item.showTime}
                            showToday={item.showToday}
                            value={item.value}
                            onChange={item.onChange}
                            onOk={item.onOk}
                            onPanelChange={item.onPanelChange}
                        />
                    </Form.Item>
                </Col>
            </Tooltip>

        )
    }
    //处理Select
    const selectItem = (item: any):ReactElement  => {
        let rule = rules(item)
        return (
            <Tooltip key={item.name} title={item.Tooltip && item.title ? item.title : null}>
                <Col key={item.name} span={item.col || 8}>
                    <Form.Item
                        label={item.label}
                        name={item.name}
                        key={item.name}
                        rules={rule}
                    >
                        <Select disabled={item.disable}
                            placeholder={item.placeholder}
                            showSearch={item.showSearch}
                            optionFilterProp={item.optionFilterProp}
                            filterOption={item.filterOption}
                            onSelect={item.onSelect}
                            autoClearSearchValue={item.autoClearSearchValue}
                            autoFocus={item.autoFocus}
                            bordered={item.bordered}
                            clearIcon={item.clearIcon}
                            defaultActiveFirstOption={item.defaultActiveFirstOption}
                            defaultOpen={item.defaultOpen}
                            defaultValue={item.defaultValue}
                            dropdownClassName={item.dropdownClassName}
                            dropdownMatchSelectWidth={item.dropdownMatchSelectWidth}
                            dropdownRender={item.dropdownRender}
                            dropdownStyle={item.dropdownStyle}
                            fieldNames={item.fieldNames}
                            filterSort={item.filterSort}
                            getPopupContainer={item.getPopupContainer}
                            labelInValue={item.labelInValue}
                            listHeight={item.listHeight}
                            loading={item.loading}
                            maxTagCount={item.maxTagCount}
                            maxTagPlaceholder={item.maxTagPlaceholder}
                            maxTagTextLength={item.maxTagTextLength}
                            menuItemSelectedIcon={item.menuItemSelectedIcon}
                            mode={item.mode}
                            notFoundContent={item.notFoundContent}
                            open={item.open}
                            optionLabelProp={item.optionLabelProp}
                            options={item.options}
                            removeIcon={item.removeIcon}
                            searchValue={item.searchValue}
                            showArrow={item.showArrow}
                            size={item.size}
                            suffixIcon={item.suffixIcon}
                            tagRender={item.tagRender}
                            value={item.value}
                            virtual={item.virtual}
                            onBlur={item.onBlur}
                            onChange={item.onChange}
                            onClear={item.onClear}
                            onDeselect={item.onDeselect}
                            onDropdownVisibleChange={item.onDropdownVisibleChange}
                            onFocus={item.onFocus}
                            onPopupScroll={item.onPopupScroll}
                            onSearch={item.onSearch}
                        >
                            {item.data !== undefined ? item.data : null}
                        </Select>
                    </Form.Item>
                </Col>
            </Tooltip>

        )
    }
    //处理Row
    const row = (Arr: Array<any>):ReactElement => {
        return (
            <Row>
                {Arr}
            </Row>
        )
    }
    //规则
    const rules = (item: any):Array<any> => {
        let rules = [];
        if (item.required) {
            let message = item.message || `${item.label}不能为空`
            rules.push({ required: true, message })
        }
        if (item.rules && item.rules.length > 0) {
            rules = rules.concat(item.rules)
        }
        return rules
    }

    // 初始化
    const initFormItem = () => {
        if (!formItemData || (formItemData && formItemData.length === 0)) { return false }
        let rowList: any = []
        formItemData.map((item: any) => {
            if (item.type === "Input") {
                rowList.push(inputItem(item))
            }
            if (item.type === "Select") {
                rowList.push(selectItem(item))
            }
            if (item.type === "InputNumber") {
                rowList.push(inputNumber(item))
            }

            if (item.type === "DatePicker") {
                rowList.push(datePicker(item))
            }
            if (item.type === "Switch") {
                rowList.push(SwitchC(item))
            }
        })
        let data = row(rowList)
        return data
    }
    useEffect(() => {
        setFormItemData(formItem)
    }, [formItem])

    return (
        <>
            <Form

                form={form}
                onFinish={onFinish}
            >
                {initFormItem as any}
            </Form>
        </>
    )
}
export default ComForm