import { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi'
import antMessage from '../../utils/extend/AntdNotification'
import validator from 'validator';
import objectHandle from '../../utils/baseLib/objectHandle';
import { serviceIdType, getService } from './WebUniversalNewEx';
import dayjs from 'dayjs';

/** WebEditUniversal ExtendProps数据类型 */
export declare type WEUExtendPropsType = {
    /** api服务id */
    apiId: string,
    /** 数据 */
    data?: any,
    /** 表单 */
    form?: any,
    /** 自动转换表单识别的日期格式 */
    isDateMoment?: Boolean,
    /** 是否禁止显示 */
    disabledShow?: Boolean,
    /** 是否禁止更新 */
    disabledUpdate?: Boolean,
    /** 手动输入表单识别的日期格式字段名称数组 */
    dateColumnName?: [any],
    /** 是否提取sysDate对象内容到数据中 */
    isExtractSysDate?: Boolean,
    /** 页面传送的数据对象 */
    sendPageData?: any,
    /** 后台服务地址 */
    serviceUrl?: string,
    /** 后台服务数据 */
    serviceId?: serviceIdType
};

const WebEditUniversal = (props: any, ref: any) => {
    //totalData 新增
    let { totalData } = props
    let {
        closeModal,
        data,
        form,
        apiId,
        isDateMoment,
        disabledShow,
        disabledUpdate,
        dateColumnName,
        isExtractSysDate,
        serviceUrl,
        serviceId
    } = props.props; //从父组件传来

    //sendPageData 默认的传送数据
    const { sendPageData } = data;

    /** 默认service */
    const defaultService = serviceUrl ? serviceUrl : getService(serviceId);

    //获取数据
    const [row] = useState<any>(
        async () => {
            //默认获取数据
            if (data.id) {
                const resData = await fetchApi.GET(defaultService, {
                    apiId,
                    apiVariable: data.id,
                    apiData: {
                        sendPageData
                    }
                });
                let resEx;
                if (isExtractSysDate) {
                    resEx = {
                        ...resData,
                        data: {
                            ...resData.data,
                            createdAt: resData.data.sysDate.createdAt,
                            updatedAt: resData.data.sysDate.updatedAt,
                        }
                    }
                    delete resEx.data.sysDate;
                }
                return resEx ? resEx : resData;
            }
        });


    //初始化
    useEffect(() => {
        //异步获取数据
        if (data.type !== 'NEW' && disabledShow !== true) {
            row.then((res: any) => {
                if (res.success === true) {
                    //检查日期类型，转为monent格式
                    if (typeof dateColumnName !== "undefined") {
                        for (let columnName in res.data) {
                            if (dateColumnName.includes(columnName) &&
                                res.data[columnName] !== null) {
                                //日期转换moment格式
                                res.data[columnName] = dayjs(res.data[columnName])
                            }
                        }
                    }
                    else {
                        if (isDateMoment === true) {
                            for (let columnName in res.data) {
                                if (typeof res.data[columnName] !== "undefined" && res.data[columnName] !== null) {
                                    if (validator.isISO8601(res.data[columnName].toString()) &&
                                        res.data[columnName] !== null) {
                                        //日期转换moment格式
                                        res.data[columnName] = dayjs(res.data[columnName])
                                    }
                                }
                            }
                        }
                    }
                    //显示数据
                    //设置表单默认数据  
                    form.setFieldsValue({
                        ...res.data,
                    })
                }
            })
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let mergeData = objectHandle.mergeObject(sendPageData, data.sendPageData);

    useImperativeHandle(ref, () => ({
        /** 确认 */
        handleOk: () => handleOkEdit(),
        /** 取消 */
        handleCancel: () => closeModal(false),
        /** 获取的数据 */
        row,
        /** 后端defaultService */
        defaultService
    }))

    const handleOkEdit = () => {
        if (disabledUpdate !== true) {
            form.validateFields() //数据校验
                .then(async (values: any) => {
                    //新增
                    let nowData;
                    if (totalData) {
                        nowData = totalData
                    } else {
                        nowData = values
                    }
                    const editData = {
                        //新增
                        ...nowData,
                        sendPageData: mergeData,
                    }
                    switch (data.type) {
                        case 'NEW':
                            fetchApi.POST(defaultService, {
                                apiId,
                                apiVariable: data.id,
                                apiData: editData
                            }).then(res => {
                                antMessage('success', '新增成功', 'success');
                                closeModal(true);
                            }).catch(err => {
                                antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                            })
                            break;
                        case 'EDIT':
                            fetchApi.PUT(defaultService, {
                                apiId,
                                apiVariable: data.id,
                                apiData: editData
                            }).then(res => {
                                antMessage('success', '编辑成功', 'success');
                                closeModal(true);
                            }).catch(err => {
                                console.log("err", err);
                                antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                            })
                            break;
                    }
                })
                .catch((err: any) => {
                    antMessage('warning', '无法通过数据校验', '请输入所需数据！');
                });
        }
        else {
            closeModal(true);
        }
    };
    return (
        <></>
    )
}

export default forwardRef(WebEditUniversal);