import { useState, useEffect } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import { Menu, MenuProps } from 'antd';
import systematic from '../../config/systematic';
import AntdIcon from './AntdIcon';
import { Link } from 'react-router-dom';
import antMessage from '../../utils/extend/AntdNotification';
type MenuItem = Required<MenuProps>['items'][number];

interface licenseGroup {
  id: Number,
  licenseGroupName: String,
  icon: String,
  licenses: license[],
};

interface license {
  id: Number,
  licenseName: String,
  icon: String,
  pageUrl: String,
  licenseSort: number,
};

interface httpData {
  success: boolean,
  data: licenseGroup[];
};
const useSetMenu = (resAll: httpData) => {
  //子菜单
  const [selectedKeys, setSelectedKeys] = useState<any>(sessionStorage.getItem("MenuItemKey"))

  //菜单
  const [openKeysState, setOpenKeysState] = useState<any>(sessionStorage.getItem("latestOpenKey"));

  const onOpenChange = (openKeys: any) => {
    
      setOpenKeysState(openKeys[1]);
  };
  const clickMenuItem = (MenuItem: any) => {
    //存储在sessionStorage上
    sessionStorage.setItem("MenuItemKey", MenuItem.keyPath[0])
    sessionStorage.setItem("latestOpenKey", MenuItem.keyPath[1])
    setSelectedKeys(MenuItem.keyPath[0])
    setOpenKeysState(MenuItem.keyPath[1])
  }
  if (resAll === undefined) { return }
  let resData = resAll.data;

  //修改数据
  let newData = resData.map((item: licenseGroup, key: number):MenuItem => {
    return {
      label: item.licenseGroupName,
      key: key.toString(),
      icon: <AntdIcon iconName={item.icon} />,
      children: item.licenses.sort((a, b) => a.licenseSort - b.licenseSort).map((item: license, key: number) => {
          return {
            label: <Link to={item.pageUrl as string}>{item.licenseName}</Link>,
            key: item.id.toString() + key.toString(),
            icon: <AntdIcon iconName={item.icon} />,
            // pageUrl:item.pageUrl,
            // isUse:item.isUse,
            // licenseSort:item.licenseSort,
            // licenseTypeId:item.licenseTypeId,
            // memo:item.memo,
            // sysDate:item.sysDate
          }
      }),
    }

  })
  let recordsComponent = (
    <Menu
      theme="dark"
      mode="inline"
      openKeys={[openKeysState]}
      items={newData as MenuItem[]}
      selectedKeys={[selectedKeys]}
      onClick={clickMenuItem}
      onOpenChange={e=>onOpenChange(e)}
    >

    </Menu>)
  return recordsComponent;
};

const SiderMenu = () => {
  const [records, setRecords] = useState<httpData>();
  /** 后端数据defaultService */
  const defaultService: any = systematic.bmService;
  useEffect(() => {
    //web 方式
    const fetchData = async () => {
      fetchApi.GET(defaultService,
        {
          apiId: 'licenseGroup',
          apiVariable: 'web',
          apiExtend: 'siderMenu'
        }).then(res => {
          if(res.data.length===0){
            antMessage('error', "错误","无菜单数据/无菜单权限/token过期")
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/login"
            }, 2000)
          }
          setRecords(res);
        }).catch(err => {
          if (err.message === "Invalid/Expired 无效/过期 token") {
            antMessage('error', "错误", err.message)
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/login"
            }, 2000)
          }
        })
    };
    fetchData();
  }, []);

  return (
    <div>
      {useSetMenu(records as httpData)}
    </div>
  )
}

export default SiderMenu;




