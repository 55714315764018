import WebProductTypeEdit from './WebProductTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

const webProductType = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '产品大类',
            dataIndex: 'productCategory',
            render: (text: any) => (
                text ? text.categoryName : ''
            )
        },
        {
            title: '产品分类',
            dataIndex: 'typeName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品分类',
        apiId: 'webProductType',
        columns,
        showStates: true,
        EditComponent: WebProductTypeEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default webProductType;
