import { Form, Input, Row, Col, Switch, Select, DatePicker } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from "../../universal/getList";
import moment from 'moment';
import stringHandle from '../../../utils/baseLib/stringHandle';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebRefundOrderEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [transactionModeData, setTransactionModeDat] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        const fetchData = async () => {

            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'transactionMode',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setTransactionModeDat(res.data)
                    }
                });
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webRefundOrder',
        dateColumnName: ['refundDate'],
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '退款单'}
                open={true}
                okButtonProps={{ style: { display: "none" } }}
                cancelText="取消"
                onOk={stringHandle.isUndefined}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={9}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="退款金额"
                                name="refundAmount">
                                <Input disabled={false} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="退款日期"
                                name="refundDate"
                                initialValue={moment(new Date())}>
                                <DatePicker
                                    disabled={false}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="内部退款单号"
                                name="refundNo">
                                <Input disabled={false} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="交易单号"
                                name="transactionNo">
                                <Input disabled={false} />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="交易方式"
                                name="transactionModeId">
                                <Select
                                    disabled={false}
                                    showSearch
                                    placeholder="交易方式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(transactionModeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="是否退款"
                                name="isRefund"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch
                                    disabled={false}
                                    checked={true}
                                    checkedChildren="已退款"
                                    unCheckedChildren="未退款" />
                            </Form.Item>
                        </Col>



                        <Col span={7}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch
                                    disabled={false}
                                    checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>



                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebRefundOrderEdit;