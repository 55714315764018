import { Form, Input, Row, Col, Radio, Switch, DatePicker, RadioChangeEvent } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const WebWxUserEdit = (props: any) => {
    const [form] = Form.useForm(); //form  
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const [genderData, setGenderData] = useState<any>();

    /** 后端数据defaultService */
    let defaultService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            ref.current.row.then(async (res: any) => {
                if (typeof res !== 'undefined') {
                    if (res.success === true) {
                        let userId = res.data.userId;
                        if (userId) {
                            // fetchApi.GET(defaultService,
                            //     {
                            //         apiId: 'user',
                            //         apiVariable: userId
                            //     }).then(res => {
                            //         if (res.success) {
                            //             setUserData([res.data]);
                            //         }
                            //     })
                        }
                    }
                }
            })
        }
    }, []);

    const onChangeGender = (e: RadioChangeEvent) => {
        setGenderData(e.target.value);
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'webWxUser',
        dateColumnName: ['birthday']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '微信用户'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="unionId"
                                name="unionId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="昵称"
                                name="nickName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="头像"
                                name="avatarUrl">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="姓名"
                                name="userName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="手机码"
                                name="mobile">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="性别"
                                name="gender">
                                <Radio.Group onChange={onChangeGender} value={genderData}>
                                    <Radio value={0}>女</Radio>
                                    <Radio value={1}>男</Radio>
                                </Radio.Group>
                            </Form.Item>

                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="生日"
                                name="birthday">
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="销售员"
                                name="isSales"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default WebWxUserEdit;