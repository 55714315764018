import UserLoginEdit from './UserLoginEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'

//组件 UserLogin
const UserLogin = (props: any) => {

    //表头
    const columns = [
        {
            title: '登录ID',
            dataIndex: 'logId',
            sorter: true,
        },
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => (
                text ? text.userName : ''
            )
        },
    ];
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户登录',
        apiId: 'userLogin',
        columns,
        primaryKeyName: 'logId',
        showStates: true,
        EditComponent: UserLoginEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default UserLogin;
