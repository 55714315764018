import { useEffect, useRef } from 'react';
import TradingEdit from './TradingEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import moment from 'moment';
import { Button, Space, Switch } from 'antd';

const Trading = (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'trading';
    let handleChangeOne: any;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columns = [
        {
            title: '交易日期',
            dataIndex: 'tradingDate',
            render: (val: moment.MomentInput) =>
                <span>
                    {moment(val).format('YYYY-MM-DD')}
                </span>,
        },
        {
            title: '股票',
            dataIndex: 'stock',
            render: (text: any) => (text ? text.stockName : '')
        },
        {
            title: '昨收',
            dataIndex: 'closedYesterday',
        },
        {
            title: '今开',
            dataIndex: 'openedToday',
        },
        {
            title: '今收',
            dataIndex: 'closedToday',
        },
        {
            title: '涨跌',
            dataIndex: 'riseAndFall',
        },
        {
            title: '涨跌幅',
            dataIndex: 'riseAndFallRange',
            render: (text: any) => (`${text * 10000 / 100}%`)
        },
        {
            title: '最高价',
            dataIndex: 'highestPrice',
        },
        {
            title: '最低价',
            dataIndex: 'lowestPrice',
        },
        {
            title: '振幅',
            dataIndex: 'amplitude',
            render: (text: any) => (`${text * 10000 / 100}%`)
        },
        {
            title: '换手率',
            dataIndex: 'turnoverRate',
            render: (text: any) => (`${text * 10000 / 100}%`)
        },
        {
            title: '成交量',
            dataIndex: 'turnover',
            render: (text: any) => (`${text}万`)
        },


    ];

    /** 子表头内容 */
    const expandableColumnsHead = [
        {
            title: '成交额',
            dataIndex: 'turnoverAmount',
            render: (text: any) => (`${text}亿元`)
        },
        {
            title: '流入资金',
            dataIndex: 'inflowMoney',
            render: (text: any) => (`${text}亿元`)
        },
    ];

    /** 子表头 */
    let expandableColumns = expandableColumnsHead;

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '交易',
        apiId,
        columns,
        showStates: true,
        primaryKeyName: 'tradingDate',
        EditComponent: TradingEdit,
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: undefined
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default Trading;
