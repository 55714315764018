import objectHandle from './objectHandle';

/** 字符串处理 */
export default async function stringHandle() { };

/**
* 返回字符串首字母大写
* @param source 原字符串
* @returns 新字符串
*/
stringHandle.firstUpperCase = (source: string) => {
    return source[0].toLocaleUpperCase().concat(source.slice(1))
};

/**
 * 字符串"true"/"false"转为boolean: true/false
 * @param str 
 */
stringHandle.string2Boolean = (str: string) => {
    let res = false;
    if (eval((str as string).toLowerCase()) === true) {
        res = true;
    }
    return res;
}

/**
 * 返回isUndefined
 * @param arg any data
 * @returns void 0 
 */
stringHandle.isUndefined = (arg?: any) => {
    return arg === void 0;
};

/**
 * 随机字符串(默认32位)
 * @param strLenght ? 字符串长度
 */
stringHandle.randomString = (strLenght?: number) => {
    if (typeof strLenght === 'undefined') {
        strLenght = 32;
    }
    let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let res = ''
    for (let i = 0; i < strLenght; i++) {
        //随机生成strLenght位数字
        let num = Math.floor(Math.random() * str.length);
        //拿取str里的下标生成strLenght位随机字符串
        res += str[num]
    }
    return res;
};

/**
 * 删除特定字符串
 * @param originStr 原字符串
 * @param extensionStr 删除匹配的字符串
 * @param style 样式 - 
 * 1：删除匹配的字符串(后面字符同时删除)， 
 * 2：删除匹配的字符串(有相同的都删除)， 
 * 3：删除匹配的字符串(只删除第一个)， 
 * 4：删除匹配的字符串(只删除最后一个)
 * @example
 * 例1：stringHandle.removeExtension('AA8883888','888',1) 返回 AA
 * 例2：stringHandle.removeExtension('AA8883888','888',2) 返回 AA3
 * 例3：stringHandle.removeExtension('AA8883888','888',3) 返回 AA3888
 * 例4：stringHandle.removeExtension('AA8883888','888',4) 返回 AA8883
 * @returns 
 */
stringHandle.removeExtension = (originStr: string, extensionStr: string, style?: number) => {
    if (typeof style === 'undefined') { style = 1; }
    let res = '';
    let reg;
    switch (style) {
        case 1:
            if (originStr.indexOf(extensionStr) > 0) {
                res = originStr.slice(0, originStr.indexOf(extensionStr))
            }
            else {
                res = originStr;
            }
            break;
        case 2:
            reg = new RegExp(extensionStr, 'g');
            res = originStr.replace(reg, '');
            break;
        case 3:
            reg = new RegExp(extensionStr);
            res = originStr.replace(reg, '');
            break;
        case 4:
            /** 反转字符串 */
            var tempStr = originStr.split('').reverse().join('');
            reg = new RegExp(extensionStr);
            var str = tempStr.replace(reg, '');
            res = str.split('').reverse().join('');
            break;
    }

    return res;
};

/** 补位类型 */
export declare type padType = 'START' | 'END';

/**
 * 字符串补位
 * @param str 原字符串
 * @param strLen 补位后的字符串长度
 * @param padStr 补位的字符串
 * @param style 补位类型，前/后补位
 * @returns 
 */
stringHandle.pad = (str: string, strLen: number, padStr: string, style: padType) => {
    let res;
    switch (style) {
        case 'START':
            //前补位
            res = str.padStart(strLen, padStr);
            break;
        case 'END':
            //后补位
            res = str.padEnd(strLen, padStr);
            break;
    }
    return res;
};

/** trim类型 */
export declare type trimType = 'LEFT' | 'RIGHT' | 'ALL';

/**
 * 去除字符串两边的空格
 * @param str 字符串
 * @param leftOrRight ? 左边或右边，默认是两边
 * @returns 
 */
stringHandle.trim = (str: string, leftOrRight?: trimType) => {
    /** 返回值 */
    let res = str;
    if (objectHandle.isEmpty(str) !== true) {
        switch (leftOrRight) {
            case 'LEFT':
                res = str.replace(/(^\s*)/g, "");
                break;
            case 'RIGHT':
                res = str.replace(/(\s*$)/g, "");
                break;
            default:
                res = str.replace(/(^\s*)|(\s*$)/g, "");
                break;
        }
    }
    return res;
};