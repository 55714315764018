import { useEffect } from 'react';
import FilesServiceEdit from './FilesServiceEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';

const WebComponent = (props: any) => {

    useEffect(() => {
        //同步获取数据 async - await
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
     * 表头内容
     */
    const columnsHead = [
        {
            title: '服务名称',
            dataIndex: 'serviceName',
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '服务号',
        apiId: 'filesService',
        columns,
        showStates: true,
        EditComponent: FilesServiceEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebComponent;